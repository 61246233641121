import React, { useState, useEffect } from 'react';
import { m, LazyMotion, domAnimation } from 'framer-motion';
import {
	LazyLoadComponent,
	LazyLoadImage,
	trackWindowScroll,
} from 'react-lazy-load-image-component';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { imgUrlConverter } from '../../util';

export const FilterGroupsTiles = trackWindowScroll(
	React.memo((props) => {
		const [groupData, setGroupData] = useState({});
		const [showORPCatGroups, setShowORPCatGroups] = useState(false);
		let settings = {
			accessibility: true,
			slidesToShow: 5,
			slidesToScroll: 5,
			arrows: true,
			dots: true,
			variableWidth: false,
			adaptiveHeight: false,
			infinite: false,
			responsive: [
				{
					breakpoint: 576,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1,
						dots: true,
						arrows: true,
						variableWidth: false,
					},
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 4,
						slidesToScroll: 4,
						dots: true,
						arrows: true,
						variableWidth: false,
					},
				},
				{
					breakpoint: 992,
					settings: {
						slidesToShow: 5,
						slidesToScroll: 5,
						dots: true,
						arrows: true,
						variableWidth: false,
					},
				},
				{
					breakpoint: 1200,
					settings: {
						slidesToShow: 5,
						slidesToScroll: 5,
						dots: true,
						arrows: true,
						variableWidth: false,
					},
				},
			],
		};

		const container = {
			show: {
				transition: {
					staggerChildren: 0.2,
				},
			},
		};

		const product = {
			hidden: { opacity: 0 },
			show: {
				opacity: 1,
				transition: {
					duration: 0.25,
				},
			},
		};
		useEffect(()=>{
			setShowORPCatGroups(props.catalogInfo?.isCanadaCollege ? false : props.catalogInfo?.hasNationalCat == false && 
				props.catalogInfo?.hasStandard == false)
		},[props.catalogInfo])

		useEffect(() => {
			let filterGropups = [];
			if (showORPCatGroups) {
				let standaredCatalog = props.serviceData?.subCatalogRefKeys?.find((filterObj) =>
				props.pageContentFilterGroup?.ctaCheck?.excludedNames?.includes(
					filterObj.catalogName
				))
				let groups = props.serviceData?.subCatalogRefKeys
					?.filter(
						(filterObj) =>
							!props.pageContentFilterGroup?.ctaCheck?.excludedNames?.includes(
								filterObj.catalogName
							)
					)
					?.sort((a, b) => a.orderSequence - b.orderSequence);
				groups && filterGropups.push(...groups);
				groups = props.serviceData?.siteMapRefKeys?.filter((filterObj) => {
					let key = Object.keys(filterObj)[0];
					return (
						(props.pageContentFilterGroup?.ctaCheck?.siteMapincludeOsrs ===
						filterObj[key]?.catalogOsr) && !props?.pageContentFilterGroup?.ctaCheck?.siteMapExcludedNames?.includes(filterObj[key]?.catalogName)
					);
				});

				groups &&
					groups.forEach((groupobj) => {
						Object.keys(groupobj).map((groupKey) => {
							let imgUrl = '';
							Object.keys(groupobj[groupKey].productGroups).forEach((key) => {
								imgUrl =
									groupobj[groupKey].productGroups[key].products[0]?.imageUrl;
								if (groupobj[groupKey].productGroups[key].products?.length) { 
									filterGropups.push({
										catalogName: groupobj[groupKey].catalogName,
										imageUrl: imgUrl,
										catalogOsr: groupobj[groupKey].catalogOsr,
									});
								}
							});
						});
					});
					if(standaredCatalog && Object.keys(standaredCatalog).length){
						filterGropups.push(
							{
								filterName: 'ALL Rings',
								imageUrl: standaredCatalog?.imageUrl,
								isOrp: true,
							},
						);
					}
					if(props.catalogInfo?.hasNationalCat || props.catalogInfo?.hasStandard){
						filterGropups.push(
							{
								filterName: 'All College Jewelry',
								imageUrl: props.pageContentFilterGroup?.allGroupImageUrl?.imageUrl,
								showOrp: false,
							}
						);
						}
			} else {
				filterGropups.push({
					filterName: 'All College Jewelry',
					imageUrl: props.pageContentFilterGroup?.allGroupImageUrl?.imageUrl,
					isOrp: false,
				});
				if (props.catalogInfo?.hasJlry || props.catalogInfo?.hasOrp) {
					let imageUrl = "";
					if(props.catalogInfo?.hasOrp) {
						imageUrl = props.serviceData?.subCatalogRefKeys && props.serviceData?.subCatalogRefKeys[0]?.imageUrl
					} else {
							props.serviceData?.siteMapRefKeys?.forEach((filterObj) => {
							let key = Object.keys(filterObj)[0];
							if (
								(props.pageContentFilterGroup?.ctaCheck?.siteMapincludeOsrs ===
								filterObj[key]?.catalogOsr) && !props?.pageContentFilterGroup?.ctaCheck?.siteMapExcludedNames?.includes(filterObj[key]?.catalogName)
							){
								Object.keys(filterObj).map((groupKey) => {
									Object.keys(filterObj[groupKey].productGroups).forEach((obj) => {
										imageUrl = filterObj[groupKey].productGroups[obj].products[0]?.imageUrl;
									});
								});
							}
						});
						
					}
					filterGropups.push({
						filterName: 'Official Ring Program',
						imageUrl: imageUrl,
						showOrp: true,
					});
				}
				filterGropups.push(...props.filterGroupData);
				props?.serviceData?.siteMapRefKeys?.forEach((siteMapObject) => {
					Object.keys(siteMapObject)?.forEach((key) => {
						if(props?.pageContentFilterGroup?.ctaCheck?.siteMapExcludedNames?.includes(siteMapObject[key]?.catalogName)) {
							Object.keys(siteMapObject[key].productGroups)?.forEach((gpKey) => {
								let customFilter = {
									filterName: siteMapObject[key]?.catalogName,
									imageUrl: siteMapObject[key]?.productGroups[gpKey]?.products[0]?.imageUrl,
									isCustomFilter: true
								}
								filterGropups?.push(customFilter);
							});
						}
					});					
				});
			}
			setGroupData(filterGropups);
		}, [props.filterGroupData, showORPCatGroups, props.serviceData, props.catalogInfo]);

		const handleGroupProducts = (groupObj) => {
			props.getProducts(groupObj, showORPCatGroups);
			if (groupObj.hasOwnProperty('showOrp')) {
				setShowORPCatGroups(groupObj.showOrp);
			} else {
				document.getElementById("productTiles")?.scrollIntoView({ behavior: 'smooth' })
			}
		};

		if (groupData && groupData?.length > 0) {
			return (
				<LazyMotion features={domAnimation}>
					<div className='product-categories'>
						{
							<m.div
								className='container-fluid'
								variants={container}
								initial='hidden'
								animate='show'>
								<Slider {...settings} className='carousel' key={showORPCatGroups}>
									{groupData?.map((filterObj, key) => {
										return (
											<m.div
												key={`groupObj-${key}`}
												className='product'
												variants={product}>
												<div
													className='py-1 mx-2 group-tile cursorPointer font-weight-bold'
													onClick={() => handleGroupProducts(filterObj)}>
														<LazyLoadComponent>
															<picture>
																<LazyLoadImage
																	className='group-image'
																	src={imgUrlConverter(filterObj.imageUrl || props.altImage)}
																	alt={`Group_Tiles-${key}`}
																	scrollPosition={props.scrollPosition}
																	onError={(e)=>e.target.src = props.altImage}
																/>
															</picture>
														</LazyLoadComponent>
													<div className='group-title text-uppercase'>
														{filterObj?.filterName || filterObj?.catalogName}
														<i className='fas fa-chevron-right ml-2 fa-xs'></i>
													</div>
												</div>
											</m.div>
										);
									})}
								</Slider>
							</m.div>
						}
					</div>
				</LazyMotion>
			);
		} else {
			return null;
		}
	})
);
