import React from 'react'

export const Breadcrumb = React.memo(({pbData})=> {
    return (
        <ol className="breadcrumbs" role="navigation">
            {
                pbData?.homewardPathSegments?.length > 0 &&
                pbData?.homewardPathSegments.map((breadcrumbs, index) =>
                    <li key={index}>
                        {
                            breadcrumbs.url
                            ? <a href={breadcrumbs.url}><span dangerouslySetInnerHTML={{ __html: breadcrumbs.displayName }}/></a>
                            : <span dangerouslySetInnerHTML={{ __html: breadcrumbs.displayName }}/>
                        }
                    </li>
                )
            }
        </ol>
    )
})