import { LETTER_JACKET_PRODUCT_OSR, SOFTSHELL_JACKET_PRODUCT_OSR, SOFTSHELL_JACKET_TITLE } from '../constants'

export const useDataProcessor = (appData) => {
    const processedData = {
        ...appData,
        pagecontentData: appData.pagecontentData || [],
        productBrowseData: appData.productBrowseData || {}
    }
    let catalogHasSoftshellProduct = false

    Object.keys(processedData.productBrowseData)
        .filter(key => key !== "metaData")
        .map(key => {
            const catalogData = processedData.productBrowseData[key]
            // check if catalog has Softshell Jacket.
            Object.keys(catalogData?.productGroups).map(productGroupKey => {
                catalogData.productGroups[productGroupKey]?.products.map(product => {
                    if (product.prodRefCode === LETTER_JACKET_PRODUCT_OSR) {
                        // set hasLetterJacket flag for catalog product groups 
                        catalogData.productGroups[productGroupKey].hasLetterJacket = true
                    }
                    if (product.prodRefCode === SOFTSHELL_JACKET_PRODUCT_OSR) {
                        catalogHasSoftshellProduct = true
                        // set hasSoftshellJacket flag for catalog product groups 
                        catalogData.productGroups[productGroupKey].hasSoftshelJacket = true
                        // set isSoftshellJacket flag for product itself 
                        product.isSoftshelJacket = true
                        // update breadcrumbs
                        catalogData.productGroups[productGroupKey]?.homewardPathSegments.map(pathSegment => {
                            if (pathSegment.displayName === 'Letter Jackets') {
                                pathSegment.displayName = SOFTSHELL_JACKET_TITLE
                            }
                        })
                    }
                })
            })
        })
    
    
    // update page title
    if (catalogHasSoftshellProduct) {
        processedData.pagecontentData.find(data => data.contentType === "ProductBrowseTitle").headingPostText = SOFTSHELL_JACKET_TITLE
    }
    
    return processedData
}
