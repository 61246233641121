import { LazyMotion, domAnimation, m } from "framer-motion";
import React, { useCallback, useEffect, useState } from 'react';
import { Carousel } from 'react-bootstrap';
import { LazyLoadComponent, LazyLoadImage, trackWindowScroll } from 'react-lazy-load-image-component';
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import { useGetProductBrowsePromoBanners } from '../../context';
import { hostUrlConverter, imgUrlConverter } from '../../util';
import { PriceComponent } from '../home/price';
import SeeDetailsComponent from '../home/seeDetails';
import { Spinner } from "../home/spinner";

export const FeaturedProducts = trackWindowScroll(React.memo(({ 
    pbData, 
    altImage, 
    fromHsJwl, 
    isColJlry, 
    pageContent, 
    pageContentFeatured, 
    scrollPosition, 
    paymentOptions,
    pbId
}) => {
    const [fpData, setFpData] = useState({})
    const [seeDetails, setSeeDetails] = useState(false);
    const [productData, setProductData] = useState({});
    const [loading, setLoading] = useState(false);

    const promoBanners = useGetProductBrowsePromoBanners(pbId);

    let settings = {
        accessibility: false,
        slidesToShow: 6,
        slidesToScroll: 6,
        arrows: fpData?.products?.length > 5 ? true : false,
        dots: true,
        variableWidth: true,
        adaptiveHeight: false,
        infinite: false,
        responsive: [
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: true,
                    variableWidth: false
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    dots: true,
                    arrows: true,
                    variableWidth: false
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5,
                    dots: true,
                    arrows: true,
                    variableWidth: true
                }
            }
        ]
    }

    const container = {
        show: {
            transition: {
                staggerChildren: 0.2
            }
        }
    }

    const viewDetails = useCallback(
        (data) => {
            setSeeDetails(true)
            setProductData({...data,"showMetalOption": !pageContent?.hideMetalOption})
        },
        [],
    )
    const nextIcon = () => {
        return <div className="swiper-btn-next"><i className="far fa-arrow-right"></i></div>
    }
    const prevIcon = () => {
        return <div className="swiper-btn-prev"><i className="far fa-arrow-left"></i></div>
    }

    const navigate = (productObj) => {
        setLoading(true);
        let redirectUri = hostUrlConverter(productObj?.actionUrl)
        window.location.assign(redirectUri);
      }

    useEffect(() => {
        if (pbData && pageContentFeatured && Object.keys(pbData)?.length > 0 && Object.keys(pageContentFeatured)?.length > 0) {
            const productOsrs = pageContentFeatured?.ctaCheck.productOsr?.split(",")
            const featuredProductsArray = []
            productOsrs?.length > 0 && productOsrs?.forEach(osrValue => {
                var parts = osrValue.split("*");
                if (parts[1]!==undefined)
                    osrValue = parts.slice(0,-1).join('.+')  + parts.slice(-1);
                osrValue = "^"+osrValue.trim();
                let regex= new RegExp(osrValue)
                let product = pbData?.products?.find(productObj => productObj.productOsr.match(regex))
                if (product) { 
                    featuredProductsArray.push(product)
                }
            })
            setFpData({ "products": featuredProductsArray })
        }
    }, [pageContentFeatured, pbData])


    if (fpData && fpData.products && Object.keys(fpData)?.length > 0 && Object.keys(fpData.products).length > 0) {
        return (
            <LazyMotion features={domAnimation}>
                <LazyLoadComponent scrollPosition={scrollPosition}> 
                <div className="container-fluid mt-3"><h5 className="px-2">{"Featured"}</h5></div>
                <div className='container-fluid'><hr /></div>
                <div
                    className="product-categories"
                >
                    <m.div
                        className="container-fluid"
                        variants={container}
                        initial="hidden"
                        animate="show"
                    >
                       {loading && <Spinner/>}
                        <Slider {...settings} className="carousel"> 
                            {fpData.products?.map((productObj, key) => {
                                return (
                                    <m.div className="product-container mobile-center" key={"pc" + key}>
                                        <div className="cursorPointer" onClick={()=> navigate(productObj)}>
                                            <LazyLoadComponent>
                                            <picture>
                                                <source media="(min-width: 1920px)" srcSet={imgUrlConverter(productObj?.imageUrl, pageContentFeatured?.ctaCheck?.desktopImageVariant)} />
                                                <LazyLoadImage src={imgUrlConverter(productObj?.imageUrl || altImage, pageContentFeatured?.ctaCheck?.mobileImageVariant)} alt={productObj?.displayName} scrollPosition={scrollPosition} effect="blur" onError={(e)=>e.target.src = altImage}/>
                                            </picture>
                                            </LazyLoadComponent>
                                        </div>
                                        {
                                            productObj?.altImages?.length>0 && productObj?.altImages?.filter(obj=>obj.isAlternate===true)?.length>0 &&
                                            <LazyLoadComponent>
                                            <Carousel className="product-button" nextIcon={nextIcon()} prevIcon={prevIcon()} indicators={false} indicatorlabels={[]} interval={null}>
                                                <Carousel.Item>
                                                    <div className="cursorPointer" onClick={()=> navigate(productObj)}>
                                                        <LazyLoadComponent>
                                                        <picture>
                                                            <source media="(min-width: 1920px)" srcSet={imgUrlConverter(productObj?.imageUrl, pageContentFeatured?.ctaCheck?.desktopImageVariant)} />
                                                            <LazyLoadImage src={imgUrlConverter(productObj?.imageUrl || altImage, pageContentFeatured?.ctaCheck?.mobileImageVariant)} alt={productObj?.displayName} scrollPosition={scrollPosition} effect="blur" onError={(e)=>e.target.src = altImage}/>
                                                        </picture>
                                                        </LazyLoadComponent>
                                                    </div>
                                                </Carousel.Item>
                                                {
                                                    productObj?.altImages?.filter(obj=>obj.isAlternate===true).map((altImage) =>
                                                        <Carousel.Item key={altImage?.altImageDisplayName + key}>
                                                            <div className="cursorPointer" onClick={()=> navigate(productObj)}>
                                                                <LazyLoadComponent>
                                                                <picture>
                                                                    <source media="(min-width: 1920px)" srcSet={imgUrlConverter(altImage?.altImageUrl, pageContentFeatured?.ctaCheck?.desktopImageVariant)} />
                                                                    <LazyLoadImage src={imgUrlConverter(altImage?.altImageUrl || altImage, pageContentFeatured?.ctaCheck?.mobileImageVariant)} alt={altImage?.altImageDisplayName} scrollPosition={scrollPosition} effect="blur" onError={(e)=>e.target.src = altImage}/>
                                                                </picture>
                                                                </LazyLoadComponent>
                                                            </div>
                                                        </Carousel.Item>
                                                    )
                                                }
                                            </Carousel>
                                            </LazyLoadComponent>
                                        }
                                        <div className="carousel-caption">
                                            <button className="product-button-btn" onClick={() => viewDetails(productObj)}> SEE DETAILS </button>
                                        </div>
                                        <div className="fa-xs mt-2">
                                            <div onClick={()=>setLoading(true)}>
                                                <a href={hostUrlConverter(productObj?.actionUrl)} rel="nofollow">
                                                <div className="cursorPointer">{productObj?.displayName}</div>
                                                </a>
                                            </div>
                                            {productObj && Object.keys(productObj)?.length > 0 && (
                                                <PriceComponent
                                                    data={productObj}
                                                    pageContent={pageContentFeatured}
                                                    fromHsJwl={fromHsJwl}
                                                    isColJlry={isColJlry}
                                                    isDiscountedPriceVisible={!Boolean(promoBanners?.length)}
                                                />
                                            )}
                                        </div>
                                    </m.div>
                                )
                            })}
                         </Slider>
                   </m.div>
                </div>
                    {seeDetails && (
                        <SeeDetailsComponent
                            setSeeDetails={setSeeDetails}
                            productData={productData}
                            fromHsJwl={fromHsJwl}
                            isColJlry={isColJlry}
                            pageContent={pageContentFeatured}
                            paymentOptions={paymentOptions}
                            pbId={pbId}
                        />
                    )}
                </LazyLoadComponent>
            </LazyMotion>
        )
    } else {
        return null
    }
}))
