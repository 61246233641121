import React, { useEffect, useState, useCallback } from 'react';
import {
	BREADCRUMB,
	PAGETITLE,
	PAYPALBANNER,
	PRODUCTTILES,
	PROMOMSG
} from '../../constants/rootElements';
import { Breadcrumb } from '../breadcrumb';
import { PaypalBanner } from '../paypalBanner';
import { UplomaProductTiles } from './uplomaProductTiles';
import { TitleBar } from "../productBrowseTitle";
import { AlertsList } from '../../components/Alerts/alertsList';

export const UplomaPage = ({ appData }) => {
	const [pbData, setPbData] = useState({});
	const [breadcrumbData, setBreadcrupmData] = useState([]);
	const [promoMessages, setPromoMessages] = useState([]);

	const UplomaBreadcrumb = Boolean(
		appData?.pagecontentData?.find((data) => data.contentType === 'Navigation')
	);
	const ProductBrowseProducts = appData?.pagecontentData?.find(
		(data) => data.contentType === 'ProductBrowseProducts'
	);
	const ProductBrowsePromoMsg = Boolean(
		appData?.pagecontentData?.find(
			(data) => data.contentType === 'ProductBrowsePromoMsg'
		)
	);
	const PaypalBannerData = appData?.pagecontentData?.find(
		(data) => data.contentType === 'PaypalId'
	);
	const UplomaTitleBar = appData?.pagecontentData?.find(
		(data) => data.contentType === 'ProductBrowseTitle'
	);

	const getBreadcrumb = useCallback(() => {
		BREADCRUMB.render(
			<Breadcrumb pbData={breadcrumbData} />
		);
	}, [breadcrumbData]);

	const getPromoMsg = useCallback(() => {
		PROMOMSG.render(
			<AlertsList messages={promoMessages} />
		);
	}, [promoMessages]);

	const productTiles = useCallback(() => {
		PRODUCTTILES.render(
			<UplomaProductTiles
				pbData={pbData}
				pageContent={ProductBrowseProducts}
			/>
		);
		// eslint-disable-next-line
	}, [pbData]);

	const paypalBanner = useCallback(() => {
		PAYPALBANNER.render(
			<PaypalBanner paypalBannerData={PaypalBannerData} />
		);
	}, [PaypalBannerData]);

	const getTitleBar = useCallback(() => {
		PAGETITLE.render(
			<TitleBar
				pageContent={UplomaTitleBar}
				affiliationData={appData?.affiliationData}
			/>
		);
	}, []);

	useEffect(() => {
		appData.productBrowseData &&
			Object.keys(appData.productBrowseData).map((key) => {
				if (key !== 'metaData') {
					appData.productBrowseData[key]?.productGroups &&
						Object.keys(appData.productBrowseData[key]?.productGroups).map(
							(catObj) => {
								setPbData(appData.productBrowseData[key].productGroups[catObj]);
								setPromoMessages(appData.productBrowseData[key].promoMessages);
								setBreadcrupmData(
									appData.productBrowseData[key].productGroups[catObj]
								);
								return true;
							}
						);
				}
				return true;
			});
	}, [appData.productBrowseData]);

	return (
		<>
			{UplomaBreadcrumb && getBreadcrumb()}
			{UplomaTitleBar && Object.keys(UplomaTitleBar)?.length > 0 && getTitleBar()}
			{ProductBrowsePromoMsg && promoMessages?.length > 0 && getPromoMsg()}
			{ProductBrowseProducts &&
				Object.keys(ProductBrowseProducts)?.length > 0 &&
				productTiles()}
			{PaypalBannerData &&
				Object.keys(PaypalBannerData)?.length > 0 &&
				paypalBanner()}
		</>
	);
};
