import React, { useState, useCallback, useEffect } from 'react'
import { LazyLoadComponent, LazyLoadImage, trackWindowScroll } from 'react-lazy-load-image-component';
import { imgUrlConverter, hostUrlConverter, checkURLHasDomain } from '../../util'
import { PriceComponent } from '../home/price'
import SeeDetailsComponent from '../home/seeDetails'
import { Carousel } from 'react-bootstrap';
import { Spinner } from '../home/spinner';
import { domAnimation, LazyMotion } from 'framer-motion';


export const PhotoCardTiles = trackWindowScroll(React.memo(({pbData, pageContent, paymentOptions, flavoredProductsPageContent, scrollPosition, filteredItems, fromColGradAnnc}) => {
  const [seeDetails, setSeeDetails] = useState(false);
  const [productData, setProductData] = useState({});
  const [loading, setLoading] = useState(false);
  const [flavoredImagesIndex, setFlavoredImagesIndex] = useState(null)
  const [productIndex, setProductIndex] = useState(null)
  const optionOsrs = JSON.parse(sessionStorage.getItem("filterOptionOsrs"))
  const [coloredProductData, setColoredProductData] = useState({})


  const viewDetails = useCallback(
    (data) => {
      setSeeDetails(true)
      setProductData(data)
    },
    [],
  )
  const nextIcon = () => {
    return <div className="swiper-btn-next"><i className="far fa-arrow-right"></i></div>
  }
  const prevIcon = () => {
    return <div className="swiper-btn-prev"><i className="far fa-arrow-left"></i></div>
  }
  const navigate = (productObj) => {
    setLoading(true);
    let redirectUri = ""
    if(productObj?.flavoredProductActionUrl?.includes("/-/-")){
      const pathParams = window.location.pathname?.split("/")
      if(pathParams?.length > 0){
        redirectUri = hostUrlConverter(productObj?.flavoredProductActionUrl.replace("/-/-/", `/${pathParams[4]}/${pathParams[5]}/`))
      }
    } else {
      redirectUri = hostUrlConverter(productObj?.flavoredProductActionUrl)
    }
    window.location.assign(redirectUri);
  }
  const setImagesIndexs = (productIndexValue, flavoredImageIndexValue) => {
    setProductIndex(productIndexValue)
    setFlavoredImagesIndex(flavoredImageIndexValue)
    sessionStorage.setItem("flavoredImageIndex", JSON.stringify(flavoredImageIndexValue))
  }

  useEffect(() => {
    if (pbData && Object.keys(pbData)?.length > 0) {
      let isRcSelected = false;
      filteredItems?.forEach((groupObj) => groupObj.filters?.forEach((filterObj) => filterObj.filterOsr === "RC" && (isRcSelected = filterObj.checked)))
        pbData.products?.length > 0 && pbData.products.map((obj)=>{
          obj.imageUrl = isRcSelected ? obj.imageUrl.includes('-rc-md.jpg') ? obj.imageUrl :obj.imageUrl.replace("-md.jpg","-rc-md.jpg") : obj.imageUrl.replace("-rc-md.jpg","-md.jpg")
          obj.flavoredOptions?.length > 0 && obj.flavoredOptions.map((fObj) => {
            fObj.flavoredProductImages?.length > 0 && fObj.flavoredProductImages.map((ffobj)=>{
              ffobj.imageUrl = isRcSelected ? ffobj.imageUrl.includes('-rc-md.jpg') ? ffobj.imageUrl : ffobj.imageUrl.replace("-md.jpg","-rc-md.jpg") : ffobj.imageUrl.replace("-rc-md.jpg","-md.jpg") 
            })
          })
        })
      if(optionOsrs?.length > 0){
        getFilteredProducts()
      } else {
        pbData?.products?.sort((a,b) => {
          if (a.orderSeq && b.orderSeq) {
              if(a.orderSeq === b.orderSeq) {
                  return a.displayName > b.displayName ? 1 : -1
              }
            return  a.orderSeq - b.orderSeq
          } else if (a.orderSeq) {
              return -1
          } else {
              return 1
          }
        });
        setColoredProductData({...pbData})
      }
    }
  }, [])

  useEffect(() => {
    if (pbData && Object.keys(pbData)?.length > 0) {
      let isRcSelected = false;
      filteredItems?.forEach((groupObj) => groupObj.filters?.forEach((filterObj) => filterObj.filterOsr === "RC" && (isRcSelected = filterObj.checked)))
        pbData.products?.length > 0 && pbData.products.map((obj)=>{
          obj.imageUrl = isRcSelected ? obj.imageUrl.includes('-rc-md.jpg') ? obj.imageUrl :obj.imageUrl.replace("-md.jpg","-rc-md.jpg") : obj.imageUrl.replace("-rc-md.jpg","-md.jpg")
          obj.flavoredOptions?.length > 0 && obj.flavoredOptions.map((fObj) => {
            fObj.flavoredProductImages?.length > 0 && fObj.flavoredProductImages.map((ffobj)=>{
              ffobj.imageUrl = isRcSelected ? ffobj.imageUrl.includes('-rc-md.jpg') ? ffobj.imageUrl : ffobj.imageUrl.replace("-md.jpg","-rc-md.jpg") : ffobj.imageUrl.replace("-rc-md.jpg","-md.jpg") 
            })
          })
        })
      if(optionOsrs?.length > 0){
        getFilteredProducts()
      } else {
        setColoredProductData({...pbData})
      }
    }
  }, [pbData])

  const getFilteredProducts = () => {
    const productsData = { ...pbData }
    productsData.products?.length > 0 && productsData.products.map((pObj, index) => {
      const fOptions = []
      pObj.flavoredOptions?.length > 0 && pObj.flavoredOptions.map((fObj) => {
        optionOsrs.map((filterOsr) => {
          if (fObj.optionOsr === filterOsr.optionOsr) {
            fOptions.push(fObj)
          }

        })
      })
      productsData.products[index] = { ...productsData.products[index], flavoredOptions: [...fOptions] }
    })
    productsData?.products?.sort((a,b) => {
      if (a.orderSeq && b.orderSeq) {
          if(a.orderSeq === b.orderSeq) {
              return a.displayName > b.displayName ? 1 : -1
          }
        return  a.orderSeq - b.orderSeq
      } else if (a.orderSeq) {
          return -1
      } else {
          return 1
      }
    });
    setColoredProductData({ ...productsData })
  }

  if (coloredProductData && Object.keys(coloredProductData)?.length > 0) {
    return (<LazyMotion features={domAnimation}>
      <LazyLoadComponent scrollPosition={scrollPosition}>  
      {loading && <Spinner/>}
      <div className="product-grid-inner-container container-fluid">
      {coloredProductData.products?.length > 0 && coloredProductData.products?.map((productObj, key) => {
        return (
          <div className="product-container mobile-center" key={"pc"+key}>
            {
                productObj?.flavoredOptions?.length > 0 && productIndex !== key ?
                <LazyLoadComponent><Carousel nextIcon={nextIcon()} prevIcon={prevIcon()} indicators={false} indicatorlabels={[]} interval={null} key={"slider"+key}>
                    {
                        productObj?.flavoredOptions[0]?.flavoredProductImages?.map((altImage) =>
                            <Carousel.Item key={`${key}-${altImage?.altImageDisplayName}-${altImage.variantType}`}>
                                <div className="cursorPointer" onClick={() => navigate(productObj?.flavoredOptions[0])}>
                                    <div>
                                        <LazyLoadComponent>
                                          <picture>
                                              <source media="(min-width: 1920px)" srcSet={altImage?.imageUrl} />
                                              <LazyLoadImage src={altImage?.imageUrl || pageContent?.imageUrl} title={`${productObj.displayName} - ${productObj?.flavoredOptions[0].displayName} (${altImage.variantType})`} alt={altImage?.altImageDisplayName} scrollPosition={scrollPosition} onError={(e)=>e.target.src = pageContent?.imageUrl}/>
                                          </picture>
                                        </LazyLoadComponent>
                                    </div>
                                </div>
                            </Carousel.Item>
                        )
                    }
                </Carousel></LazyLoadComponent> : 
                productObj?.flavoredOptions?.length === 0 ?
                <LazyLoadComponent>
                  <picture>
                    <source media="(min-width: 1920px)" srcSet={productObj?.imageUrl} />
                    <LazyLoadImage src={productObj?.imageUrl} title={`${productObj.displayName}.`} alt={productObj?.displayName} scrollPosition={scrollPosition} />
                  </picture>
                  </LazyLoadComponent> : 
                <LazyLoadComponent><Carousel nextIcon={nextIcon()} prevIcon={prevIcon()} indicators={false} indicatorlabels={[]} interval={null} key={"slider"}>
                    {
                        coloredProductData.products[productIndex]?.flavoredOptions[flavoredImagesIndex]?.flavoredProductImages?.map((altImage) =>
                            <Carousel.Item key={altImage?.altImageDisplayName}>
                                <div className="cursorPointer" onClick={() => navigate(coloredProductData.products[productIndex]?.flavoredOptions[flavoredImagesIndex])}>
                                    <div>
                                    <LazyLoadComponent>
                                        <picture>
                                            <source media="(min-width: 1920px)" srcSet={altImage?.imageUrl} />
                                            <LazyLoadImage src={altImage?.imageUrl || pageContent?.imageUrl} title={`${coloredProductData.products[productIndex].displayName} - ${coloredProductData.products[productIndex]?.flavoredOptions[flavoredImagesIndex].displayName} (${altImage.variantType})`} alt={altImage?.altImageDisplayName} scrollPosition={scrollPosition} onError={(e)=>e.target.src = pageContent?.imageUrl}/>
                                        </picture>
                                        </LazyLoadComponent>
                                    </div>
                                </div>
                            </Carousel.Item>
                        )
                    }
                </Carousel></LazyLoadComponent>
            }
            <div className="carousel-caption">
              <button className="product-button-btn" onClick={()=>viewDetails(productObj)}> SEE DETAILS </button>
            </div>
            {
              productObj.flavoredOptions?.length > 1 &&
              <div className='flavored-options'>
                {
                  productObj.flavoredOptions.map((options, index) => {
                    if(index < 4){
                      return(<div key={index} title={options.displayName} className={`filter-color ${options.optionOsr} checked`} onClick={()=>setImagesIndexs(key,index)}></div>)
                    }
                    if(index >= 4 && index === 4){
                      return(
                      <LazyLoadComponent>
                        <picture>
                            <source media="(min-width: 1920px)" srcSet={checkURLHasDomain(flavoredProductsPageContent?.imageUrl) ? flavoredProductsPageContent.imageUrl : imgUrlConverter(flavoredProductsPageContent.imageUrl)} onClick={()=>window.location.assign(hostUrlConverter(productObj?.actionUrl))} />                            
                            <LazyLoadImage key={index} className="spiral-img" src={checkURLHasDomain(flavoredProductsPageContent?.imageUrl) ? flavoredProductsPageContent.imageUrl : imgUrlConverter(flavoredProductsPageContent.imageUrl)} onClick={()=>window.location.assign(hostUrlConverter(productObj?.actionUrl))} alt="Spiral Image" scrollPosition={scrollPosition} />
                        </picture>
                      </LazyLoadComponent>)
                    }else {
                      return null
                    }
                  })
                }
              </div>
            }
            <div className="fa-xs mt-2">
              <div onClick={()=>setLoading(true)}>
                <a href={hostUrlConverter(productObj?.actionUrl)} rel="nofollow">
                  <div className="cursorPointer" title={`${productObj?.displayName}.`}>{productObj?.displayName}</div>
                </a>
              </div>
              {productObj && Object.keys(productObj)?.length > 0 && <PriceComponent data={productObj} pageContent={pageContent} />}
            </div>
          </div>
        )
      })}
      </div>
      {
        seeDetails && <SeeDetailsComponent setSeeDetails={setSeeDetails} productData={productData} fromHsGrad={true} paymentOptions={paymentOptions} fromPhotoCard={true} flavoredProductsPageContent={flavoredProductsPageContent} fromColGradAnnc={fromColGradAnnc} pageContent={pageContent}/>
      }
      </LazyLoadComponent>
    </LazyMotion>)
  }
}))
