import { useEffect, useState } from "react"

import { imgUrlConverter } from '../../../util'
import { filterItemInitialProps } from '../constants'

export const useGetFilterItemsList = ({
    filters = [],
    altImage,
    scrollPosition,
    handleFilterItemClick,
    handleError,
    imageUrl,
    displayName
}) => {
    const [filterItems, setFilterItems] = useState([])
    const [anchorFilterItem, setAnchorFilterItem] = useState()

    useEffect(() => {
        const filtersData = filters?.map((filterItem, key) => ({
            ...filterItemInitialProps,
            alt: `Group_Tiles-${key}`,
            handleError,
            handleItemClick: () => handleFilterItemClick(filterItem),
            src: imgUrlConverter(filterItem.imageUrl || altImage),
            srcSet: imgUrlConverter(filterItem.imageUrl),
            title: filterItem?.filterName,
            titleClassName: "group-title text-uppercase",
            scrollPosition,
        }))

        const anchorFilterItemData = {
            ...filterItemInitialProps,
            alt: "Group_Tiles",
            handleItemClick: () => handleFilterItemClick('ALL'),
            src: imgUrlConverter(imageUrl || altImage),
            srcSet: imgUrlConverter(imageUrl),
            title: displayName,
            titleClassName: "group-title",
            handleError,
            scrollPosition,
        }

        setFilterItems(filtersData)
        setAnchorFilterItem(anchorFilterItemData)
    }, [filters, altImage, scrollPosition])

    return { filterItems, anchorFilterItem }
}