import { LazyMotion, domAnimation, m } from "framer-motion";
import React, { useCallback, useEffect, useState } from 'react';
import { LazyLoadComponent, LazyLoadImage, trackWindowScroll } from 'react-lazy-load-image-component';
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { hostUrlConverter, imgUrlConverter } from '../../util';
import { PriceComponent } from '../home/price';
import SeeDetailsComponent from '../home/seeDetails';
import { ANIMATE_DEFAULT_PROPS, ANIMATE_DISPLAY_NAME_PROPS, ANIMATE_LIST_ITEM_PROPS } from './constants';

const HeroBannerComponent = React.memo(({ heroBannerData, pageContentData, isYearbookPlusCustomer, catalogOsr, scrollPosition, ybActivePromo }) => {
    const [seeDetails, setSeeDetails] = useState(false)
    const [productData, setProductData] = useState({});
    const [hbProductData, setHBProductData] = useState([]);
    const [hbProductDataUpdated, setHBroductDataUpdated] = useState([]);  //combine two products in one array
    const [hbPageData, setHBPageData] = useState([]);
    const [productIndex, setProductIndex] = useState();
    const promotionApplicableProducts = pageContentData?.ctaCheck?.promotionApplicableProducts?.split(",");

    useEffect(() => {
        if (heroBannerData && heroBannerData?.length > 0) {
            heroBannerData.sort((a, b) => a.orderSeq - b.orderSeq)
            heroBannerData.map((bannerObj) =>
                setHBProductData(prevState => [...prevState, ...bannerObj.products])
            )
        }
    }, [heroBannerData])

    useEffect(() => {
        if (ybActivePromo?.adjustmentAmount > 0) {
            hbProductData && hbProductData?.length > 0 && hbProductData.map((data, i) => {
                if (promotionApplicableProducts.includes(data.productOsr)) {
                    data.ispromotionApplicableProduct = true;
                    data.ybDiscountedPrice = data.retailPrice - ybActivePromo?.adjustmentAmount
                }
                else {
                    data.ispromotionApplicableProduct = false;
                    data.ybDiscountedPrice = null
                }
            })
        }
    }, [hbProductData, ybActivePromo])


    useEffect(() => {
        let copyHbProductDataUpdated = [];
        const copyHbProductData = [...hbProductData]
        if (copyHbProductData.length > 0) {
            for (let i = 0; i < copyHbProductData.length; i += 2) {
                copyHbProductDataUpdated.push(copyHbProductData.slice(i, i + 2));
            }
        }
        setHBroductDataUpdated(copyHbProductDataUpdated)
    }, [hbProductData, ybActivePromo])


    let settings = {
        accessibility: true,
        slidesToScroll: 1,
        arrows: hbProductDataUpdated?.length > 1 ? true : false,
        dots: hbProductDataUpdated?.length > 1 ? true : false,
        variableWidth: false,
        adaptiveHeight: false,
        infinite: false,
        responsive: [
            {
                breakpoint: 992,
                settings: "unslick"
            }
        ]
    }

    const container = {
        show: {
            transition: {
                staggerChildren: 0.2
            }
        }
    }

    const product = {
        hidden: { opacity: 0 },
        show: {
            opacity: 1,
            transition: {
                duration: 0.25
            }
        }
    }

    const viewDetails = useCallback(
        (data, prodIndex) => {
            setSeeDetails(true)
            setProductData(data)
            setProductIndex(prodIndex)
        },
        [])

    useEffect(() => {
        const heroPageArr = []
        hbProductData && hbProductData?.length > 0 && hbProductData.map((prodData, index) => {
            let heroPageObj = {}
            let heroPageFlag = false
            pageContentData?.productBrowseHeroProducts?.map((pageData, key) => {
                const productOsrs = pageData.ctaCheck.productOsr?.split(",");
                productOsrs.map((osrValue) => {
                    if (osrValue === prodData.productOsr && Object.keys(pageData.ctaCheck?.map)?.length > 1
                        && ((pageData.ctaCheck?.map.isYearbookPlusCustomer === "true" && isYearbookPlusCustomer) ||
                            (pageData.ctaCheck?.map.isYearbookPlusCustomer === "false" && isYearbookPlusCustomer === false) ||
                            (pageData.ctaCheck?.map.catalogOsr === catalogOsr))) {
                        heroPageFlag = true
                        heroPageObj = pageData
                    }
                    return true
                })
                return true
            })

            if (heroPageFlag === false) {
                let heroPCFlag = false
                const heroData = [...pageContentData.productBrowseHeroProducts];
                for (let j = 0; j < heroData?.length; j++) {
                    const productOsrs = heroData[j]?.ctaCheck.productOsr?.split(",");
                    for (let k = 0; k < productOsrs?.length; k++) {
                        if (productOsrs[k] === prodData.productOsr && Object.keys(heroData[j].ctaCheck?.map)?.length === 1) {
                            heroPCFlag = true
                            heroPageObj = heroData[j];
                            break;
                        }
                    }
                    if (heroPCFlag) break;
                }
            }
            heroPageArr.push(heroPageObj)
            return true
        })
        setHBPageData(heroPageArr)
    }, [hbProductData, catalogOsr, isYearbookPlusCustomer, pageContentData?.productBrowseHeroProducts])

    if (hbProductData?.length > 0) {
        return (
            <LazyMotion features={domAnimation}>
                <LazyLoadComponent scrollPosition={scrollPosition}>
                    <div className="product-categories">
                        <div className="row mb-3 mx-2">
                            <m.div
                                className="container-fluid"
                                variants={container}
                                initial="hidden"
                                animate="show"
                            >
                                <LazyLoadComponent>
                                    <Slider {...settings} className="carousel">
                                        {hbProductDataUpdated && hbProductDataUpdated?.length > 0 && hbProductDataUpdated.map((productObj, key) => {
                                            return (
                                                <div className="row d-flex main-row m-lg-0">
                                                    {productObj && productObj?.length > 0 &&
                                                        productObj.map((newProductObj, newProductObjIndex) =>
                                                            <div
                                                                className={newProductObjIndex == 0 ? "col-lg-6 pr-lg-0" : "col-lg-6 pl-lg-0"}
                                                                key={newProductObjIndex}
                                                                variants={product}
                                                            >
                                                                <div className={productObj.length > 1 && newProductObjIndex == 0 ? 'school-banner multiple-product add-border' : 'school-banner multiple-product'}>
                                                                    <m.div
                                                                        className={(newProductObjIndex === 0 && productObj.length > 1) || (hbProductDataUpdated.length - key > 1 && newProductObjIndex === 1) ? "overlay border-separation" : "overlay"}
                                                                        animate={ANIMATE_DEFAULT_PROPS}
                                                                    >
                                                                        <div>
                                                                            {hbPageData && hbPageData?.length > 0 && hbPageData.map((pageData, index) => (newProductObjIndex === index) && <div className="fontSizeMd mb-4 hero-label">{pageData.ctaMessage}</div>)}
                                                                            <m.h2
                                                                                animate={ANIMATE_DISPLAY_NAME_PROPS}
                                                                                className="mb-3"
                                                                            >
                                                                                {newProductObj?.displayName}
                                                                            </m.h2>
                                                                            {newProductObj !== undefined && Object.keys(newProductObj).length > 0 &&
                                                                                <div className="price-label hero-banner-price-label">
                                                                                    <PriceComponent
                                                                                        data={newProductObj}
                                                                                        showYbDiscountPrice={newProductObj?.ispromotionApplicableProduct && newProductObj?.ybDiscountedPrice}
                                                                                    />
                                                                                </div>
                                                                            }
                                                                            <ul>
                                                                                <m.li animate={ANIMATE_LIST_ITEM_PROPS}>
                                                                                    {hbPageData && hbPageData?.length > 0 && hbPageData.map((pageData, index) => (newProductObjIndex === index) &&
                                                                                        <>
                                                                                            <div className="my-2 pt-3 pb-3">
                                                                                                <a
                                                                                                    href={hostUrlConverter(newProductObj.actionUrl)}
                                                                                                    className="hero-banner-button btn"
                                                                                                >
                                                                                                    {pageData.ctaButton ? pageData.ctaButton : pageContentData.ctaDefaultButton}
                                                                                                </a>
                                                                                            </div>
                                                                                            <div style={{ position: "relative" }}>
                                                                                                <div className="fontSize1X my-2 product-description">
                                                                                                    {pageData.description ? pageData.description : newProductObj?.description}
                                                                                                    <span className="product-description-tooltiptext">
                                                                                                        {/* <span><FontAwesomeIcon icon={faTimes} className="tooltip-close" onClick={()=> $('.product-description-tooltiptext').hide()}/></span> */}
                                                                                                        {pageData.description ? pageData.description : newProductObj?.description}
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <a className="fontSize1X my-2">
                                                                                                <span
                                                                                                    className="cursorPointer"
                                                                                                    onClick={() => viewDetails(newProductObj, newProductObjIndex)}
                                                                                                >
                                                                                                    {pageData.ctaLink}
                                                                                                </span>
                                                                                            </a>
                                                                                        </>
                                                                                    )}
                                                                                </m.li>
                                                                            </ul>
                                                                        </div>
                                                                    </m.div>
                                                                    <div className="img-wrapper" onClick={() => window.location.assign(hostUrlConverter(newProductObj?.actionUrl))}>
                                                                        <LazyLoadComponent>
                                                                            <picture>
                                                                                <source media="(min-width: 1024px)" srcSet={imgUrlConverter(newProductObj.imageUrl, pageContentData?.ctaCheck?.desktopImageVariant)} />
                                                                                <LazyLoadImage
                                                                                    src={imgUrlConverter(newProductObj.imageUrl, pageContentData?.ctaCheck?.mobileImageVariant)}
                                                                                    alt={newProductObj?.displayName} scrollPosition={scrollPosition}
                                                                                />
                                                                            </picture>
                                                                        </LazyLoadComponent>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            )
                                        })}
                                    </Slider>
                                </LazyLoadComponent>
                            </m.div>
                        </div>
                    </div>
                    {
                        seeDetails && Object.keys(productData).length > 0 && hbPageData?.length > 0 && <SeeDetailsComponent setSeeDetails={setSeeDetails} productData={productData} pageContentData={hbPageData[productIndex]} fromHeroBanner={true} showYbDiscountPrice={productData?.ispromotionApplicableProduct && productData?.ybDiscountedPrice} />
                    }
                </LazyLoadComponent>
            </LazyMotion>)
    } else {
        return <></>
    }
})

export default trackWindowScroll(HeroBannerComponent)