import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { hostUrlConverter } from '../../util/index'
import { PaymentPromoModal } from "./paymentPromoModal";
import { gaCapGownBrowseCustomizeClick } from '../../util/googleAnalytics';

export const PaymentPromo = React.memo(({ hsPaymentPromo, product, isBannerMode }) => {
    const [paymentOptionsData, setPaymentOptionsData] = useState("")
    const [showModal, setShowModal] = useState(false)
    const [paymentData, setPaymentData] = useState({});

    useEffect(() => {
        const excludeForCatOsr = ["GRAD_PROD_HS_SENIOR_NATIONAL_SPRING_CATALOG", "GRAD_PROD_HS_SENIOR_SPRING_CATALOG"]
        if (product) {
            if (!Boolean(excludeForCatOsr.find((osrValue) => osrValue === product.catClassRefcode))) {
                const customerNumber = product.cacheKey?.split("-")[0]
                const url = `${process.env.REACT_APP_BASE_PATH}/apps/checkout/productPaymentPlanDetails.mvc?productType=${product.productType}&categoryName=${hsPaymentPromo.categoryOsr}&currentPrice=${product.retailPrice}&currencyCode=${product.currencyCode}&customerNumber=${customerNumber}`

                url && axios.get(url,
                    {
                        headers: {
                            "Content-Type": "application/json"
                        }
                    })
                    .then(result => {
                        let displayText = ""
                        setPaymentData(result.data);
                        if (result.data?.numberOfPayments > 1) {
                            displayText = hsPaymentPromo?.displayText
                                ?.replace('{totalPrice}', `$${product?.retailPrice}`)
                                ?.replace('{numberOfPayments}', result.data?.numberOfPayments)
                                ?.replace('{price}', `$${result.data.payments?.length > 0 && result.data.payments[0]} <img src=${hsPaymentPromo?.imageUrl} alt="Jpay logo" height=${17}></img>`)
                        } else {
                            displayText = result.data.payments?.length > 0 && '$' + result.data.payments[0]
                        }
                        setPaymentOptionsData(displayText)
                    })
            }
        }

    }, [product, hsPaymentPromo])

    return (
        <div className={isBannerMode ? 'container-fluid ml-3' : ''}>
            { (!paymentOptionsData || paymentData?.numberOfPayments === 1) && <div className="price mb-2">${product.retailPrice}</div>}
            {paymentOptionsData && paymentData?.numberOfPayments > 1 && 
                (<div className="d-inline-flex">
                    <div className='mb-2 fontSizeSM' dangerouslySetInnerHTML={{ __html: paymentOptionsData }} />
                    <i className="fas fa-info-circle ml-1" onClick={()=> setShowModal(true)}></i>
                </div>)
            }
            {isBannerMode && 
                (<div className='pb-3'>
                    <button type="button" className="btn btn-primary" onClick={()=>{gaCapGownBrowseCustomizeClick(product, "Banner");window.location.assign(hostUrlConverter(product?.actionUrl))}}>{hsPaymentPromo.buttonCta}</button>
                </div>)
            }
            <PaymentPromoModal showModal={showModal} handleClose={()=>setShowModal(false)} hsPaymentPromo={hsPaymentPromo} paymentData={paymentData} retailPrice={product?.retailPrice}/>
        </div>
    )
})