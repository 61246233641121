import React, { useEffect, useState } from 'react';
import { ProductBrowseTiles } from '../home/productBrowseTiles';
import { ProductFilterTile } from '../hs-jwl/productFilterTile';

export const ColJlryProductTiles = React.memo((props) => {
	const [filteredPbData, setFilteredPbData] = useState({});
	const [productData, setProductData] = useState({});
	const [filteredItems, setFilteredQuickData] = useState([]);

	useEffect(() => {
		let productsData = Object.keys(filteredPbData)?.length > 0
				?  filteredPbData.products
				:  props.pbData?.products?.sort(
							(a, b) => a.orderSeq - b.orderSeq
						)
		let jlry_box = {};
		let finalProducts = productsData?.filter((pData) => {
				if(pData.productOsr === "JLRY_BOX" ||  pData.productOsr === "JLRY_BOX_CND") {
					jlry_box = pData;
					return false;
				} else {
					return true
				}
		});
		if(Object.keys(jlry_box).length) {
			finalProducts = [...finalProducts,jlry_box]
		}
		setProductData({products:finalProducts})
	}, [props.pbData, filteredPbData]);

	if (props.pbData) {
		return (
			<>
				<ProductFilterTile
					pbData={props.pbData}
					filteredPbData={filteredPbData}
					filterData={props.filterData}
					setFilteredPbData={setFilteredPbData}
					setFilteredQuickData={setFilteredQuickData}
					filteredItems={filteredItems}
					isOrpFilters = {props.isOrpFilters}
					filterClicked = {()=>props.setTopFilterClicked(false)}
					topFilterClicked = {props.topFilterClicked}
					selectedGroup={props.selectedGroup}
					setSelectedGroup={props.setSelectedGroup}
					getProducts={props.getProducts}
				/>
				<>
					<div className="product-title">
						<span className="px-2 pt-2 h5">{`${props.selectedSubCatalog?.catalogName ? props.selectedSubCatalog?.catalogName : ''} INDIVIDUAL PRODUCTS`}</span>
					</div>
					<div className='container-fluid'><hr /></div>
				</>
				<ProductBrowseTiles
					pbData={productData}
					pageContent={props.pageContent}
					isColJlry={true} // added this for now to avoid conflicts, will optimise it in future.
					paymentOptions={props.PaymentOptions}
					productInfo={{"performSort": false,"isColJlry":true}}
				/>
			</>
		);
	} else {
		return null;
	}
});
