export const ANIMATE_DEFAULT_PROPS = {
    opacity: [0, 1],
    transition: {
        duration: .5
    }
}

export const ANIMATE_DISPLAY_NAME_PROPS = {
    ...ANIMATE_DEFAULT_PROPS,
    y: [15, 0],
    transition: {
        delay: .8,
        duration: .3,
    },
}

export const ANIMATE_LIST_ITEM_PROPS = {
    ...ANIMATE_DEFAULT_PROPS,
    y: [15, 0],
    transition: {
        delay: 1.1,
        duration: .3,
    },
}