import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

export const PaymentPromoModal = React.memo(
	({ showModal, handleClose, hsPaymentPromo, paymentData, retailPrice }) => {
		const [headingText, setHeadingText] = useState('');
		const [paymentText, setPaymentText] = useState('');
		useEffect(() => {
			let displayText =
				`$${retailPrice} or ` +
				hsPaymentPromo?.displayText
					?.replace('{totalPrice}', '')
					?.replace('{numberOfPayments}', paymentData?.numberOfPayments)
					?.replace(
						'{price}',
						`$${paymentData.payments?.length > 0 && paymentData.payments[0]}`
					)
					?.replaceAll('</br>', '') +
				`over ${paymentData?.numberOfPayments} months`;
			setHeadingText(displayText);
			if (paymentData.payments?.length) {
				let paymentText = '';
				paymentData?.payments?.forEach((payment, index) => {
					let text = '';
					if (index === 0) {
						text = 'charged at time of order';
					} else {
						text = 'charged 30 days later';
						if (index === 2) text = 'charged 30 days after 2nd payment';
						if (index === 3) text = 'charged 30 days after 3rd payment';
						if (index > 3)
							text = 'charged 30 days after ' + index + 'th payment';
					}
					paymentText =
						paymentText +
						`<p>
            <b> Payment #${index + 1} - ${payment}</b> | ${text}
          </p>`;
				});
				setPaymentText(paymentText);
			}
		}, [hsPaymentPromo, paymentData, retailPrice]);
		return (
			<Modal
				show={showModal}
				onHide={handleClose}
				dialogClassName='payment-modal'>
				<Modal.Header closeButton>
				</Modal.Header>
				<Modal.Body>
					<div className='container'>
						<div className='modal-content-header'>
							<i className='fas fa-info-circle'></i>
							<div dangerouslySetInnerHTML={{ __html: headingText }} />
						</div>

						<h3>{hsPaymentPromo.heading}</h3>
						<div
							dangerouslySetInnerHTML={{ __html: hsPaymentPromo.subheading }}
						/>

						<div className='payment-plan-list-container'>
							<div className='payment-plan-list-header'>
								<img
									className='jpay-logo'
									src={hsPaymentPromo?.imageUrl}
									height={20}
									alt='Jpay payment plan logo'
								/>
								{paymentData?.numberOfPayments}-PAYMENT PLAN SCHEDULE
							</div>
							<div
								className='payment-plan-list-body'
								dangerouslySetInnerHTML={{ __html: paymentText }}></div>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		);
	}
);
