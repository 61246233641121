import React from 'react';

export const TitleBar = React.memo(({ pageContent, affiliationData }) => {
	return (
		<div className='row mx-0 mt-3 container-fluid'>
			<h4 className={window?.template === "YB-1" ? "mb-0" : ""}>
				{
					pageContent.headingPreText 
						? <span className={'mr-2'} dangerouslySetInnerHTML={{ __html: pageContent.headingPreText }} />
						: ""
				} 
				<span dangerouslySetInnerHTML={{ __html: affiliationData.name }} /> 
				{
					pageContent.headingPostText 
						? <span className={'ml-2'} dangerouslySetInnerHTML={{ __html: pageContent.headingPostText }} />
						: ""
				}
			</h4>
		</div>
	);
});
