import React, {useState} from 'react';
import { Modal } from 'react-bootstrap';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Slider from "react-slick";
import { PaymentPromo } from './paymentPromo';
import { imgUrlConverter, packageProductsSliderSettings } from '../../util';



const GradPackageOverviw = ({packageList, productList, hsPaymentPromo, altImage}) => {

    const [activePkgIdx, setActivePkgIdx] = useState(0);
    const [seeDatails, setSeeDetails] = useState(false);
    
    let sliderRef = null;

    const onPkgCardClick = (pkgIdx) => {
        setActivePkgIdx(pkgIdx);
        sliderRef.slickGoTo(0);
    }
    const showOwerviewPopup = (e, pkgIdx) => {
        e.preventDefault();
        console.log('clicked pkg inx: ', pkgIdx);
        setSeeDetails(true);
    }
    const handleClosePopup = () => {
        setSeeDetails(false);
      }

    const packageItems = packageList.filter( product => {
        return product.packageProductAvailability.filter(record => record.packageOsr === productList[activePkgIdx].productOsr).length > 0 
    }).map( product => {
        return {
            ...product,
            displayQtyByPackageOsr: product.packageProductAvailability.reduce( (acc, record) => {
                return { ... acc, [record.packageOsr]: parseInt(record.display,10) } 
            }, {})
        }
    });

    return (
        <>
            <section className="grad-packages-overview-container p-3">
                <div className="grad-package-card-list">
                    
                    {productList?.length > 0 && productList.map((pkgProduct, index) =>
                        <div className={`grad-package-card pkg-color-${index > 3 ? 3:index} ${activePkgIdx === index ? 'active':''}`} key={pkgProduct.productOsr} onClick={() => onPkgCardClick(index)}>
                            <div className="title">{pkgProduct.displayName}</div>
                            <div className="price-row">
                                <span className="price">${pkgProduct.retailPrice}</span>
                                {index === 0 && <span className="best-label">Most Popular</span>}
                            </div>
                            <div className="rep-message my-2">{pkgProduct.repMessage}</div>
                            <div className="action-buttons">
                                <div className="text-center"><a className="btn btn-primary btn-sm" href={pkgProduct.actionUrl} rel="nofollow">Customize</a></div>
                                <div className="see-details-link-container text-center mt-2"><a className="see-details-link" href="#" onClick={(e) => showOwerviewPopup(e, index)}>See Details</a></div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="grad-package-overview">
                    { productList?.length > 0 && <>
                        <div className="product-categories mb-3 px-3">
                            <Slider {...packageProductsSliderSettings} ref={slider => (sliderRef = slider)} className="carousel">
                                { packageItems.length > 0 && packageItems.filter(packageItem => !!packageItem.imageUrl).map( packageItem => {
                                if(packageItem?.personalizedAnnouncementItems?.length) {
                                    return (
                                        packageItem?.personalizedAnnouncementItems.map(item => (
                                            productList[activePkgIdx]?.prodRefCode === item?.prodRefcode &&
                                            <div key={item.productOsr}>
                                                <LazyLoadImage src={imgUrlConverter(item?.imageUrl || altImage, "product_md")} alt={item?.displayName} onError={(e)=>e.target.src = altImage}/>
                                                <div className="packageItems-productName">
                                                    <div className='package-item-name fontSizeXS' title={item?.displayName+"."}>{item?.displayName}</div>
                                                </div>
                                            </div>
                                            )
                                        )
                                    )
                                } else {
                                    return (<div key={packageItem.productOsr}>
                                        <LazyLoadImage src={imgUrlConverter(packageItem?.imageUrl || altImage, "product_md")} alt={packageItem?.displayName} onError={(e)=>e.target.src = altImage}/>
                                        <div className="packageItems-productName">
                                            <div className='package-item-name fontSizeXS' title={packageItem?.displayName+"."}>{packageItem?.displayName}</div>
                                        </div>
                                    </div>)
                                }
                            })}
                            </Slider>
                        </div>
                        <div className="title">{productList[activePkgIdx].displayName}</div>
                        <div className="price-row">
                            <div className="payment-plan">
                                {hsPaymentPromo 
                                    ? <PaymentPromo hsPaymentPromo={hsPaymentPromo} product={productList[activePkgIdx]} />
                                    : <div className="price">${productList[activePkgIdx].retailPrice}</div>
                                }
                            </div>
                        </div>
                        <div className="font-weight-bold my-2">{productList[activePkgIdx].repMessage}</div>
                        <div className="action-button"><a className="btn btn-primary btn-sm" href={productList[activePkgIdx].actionUrl} rel="nofollow">Customize</a></div>
                        <div className="details my-2">
                            <div className="font-weight-bold">Included</div>
                            <ul className="included-product-list mb-0">
                                {packageItems?.length > 0 && packageItems.filter(product => !product.personalizedAnnouncementItems).map( product =>
                                    <li className="included-product-item mb-1 mr-2 ml-3" key={product.productOsr}>
                                        {
                                            product.displayQtyByPackageOsr[productList[activePkgIdx].productOsr] > 1 
                                            && <span className="item-ammount">{product.displayQtyByPackageOsr[productList[activePkgIdx].productOsr]} </span>
                                        }
                                        {product.displayName}
                                    </li>
                                )}
                            </ul>
                            <ul>
                                {packageItems?.length > 0 && packageItems.filter(product => !!product.personalizedAnnouncementItems).map( product =>
                                    <li className="included-product-item mb-1 mr-2 ml-3" key={product.productOsr}>
                                        <div>
                                            {
                                                product.displayQtyByPackageOsr[productList[activePkgIdx].productOsr] > 1 
                                                && <span className="item-ammount">{product.displayQtyByPackageOsr[productList[activePkgIdx].productOsr]} </span>
                                            }
                                            {product.displayName}
                                        </div>
                                        {product.personalizedAnnouncementItems && product.personalizedAnnouncementItems
                                            .filter(subProduct => subProduct.prodCatItem === productList[activePkgIdx].productOsr)
                                            .map( subProduct =>
                                                <div className="ml-2 fontSizeSM" key={subProduct.productOsr}>{subProduct.displayName}</div>
                                        )}
                                    </li>
                                )}
                            </ul>
                        </div>
                    </>}
                </div>
                {
                    seeDatails && productList?.length > 0 &&
                    <Modal dialogClassName='pkg-see-details-modal' show={seeDatails} onHide={handleClosePopup} size="lg">
                        <Modal.Header closeButton></Modal.Header>
                        <Modal.Body>
                            <div className="product-categories mb-3 px-2">
                                <Slider {...packageProductsSliderSettings} className="carousel">
                                    { packageItems.length > 0 && packageItems.filter(packageItem => !!packageItem.imageUrl).map( packageItem => {
                                        if(packageItem?.personalizedAnnouncementItems?.length) {
                                            return (
                                                packageItem?.personalizedAnnouncementItems.map(item => (
                                                    productList[activePkgIdx]?.prodRefCode === item?.prodRefcode &&
                                                    <div key={item.productOsr}>
                                                        <LazyLoadImage src={imgUrlConverter(item?.imageUrl || altImage, "product_md")} alt={item?.displayName} onError={(e)=>e.target.src = altImage}/>
                                                        <div className="packageItems-productName">
                                                            <div className='package-item-name fontSizeXS' title={item?.displayName+"."}>{item?.displayName}</div>
                                                        </div>
                                                    </div>
                                                    )
                                                )
                                            )
                                        } else {
                                            return (<div key={packageItem.productOsr}>
                                                <LazyLoadImage src={imgUrlConverter(packageItem?.imageUrl || altImage, "product_md")} alt={packageItem?.displayName} onError={(e)=>e.target.src = altImage}/>
                                                <div className="packageItems-productName">
                                                    <div className='package-item-name fontSizeXS' title={packageItem?.displayName+"."}>{packageItem?.displayName}</div>
                                                </div>
                                            </div>)
                                        }
                                    })}
                                </Slider>
                            </div>
                            <div className="title">{productList[activePkgIdx].displayName}</div>
                            <div className="price-row">
                                {hsPaymentPromo 
                                    ? <PaymentPromo hsPaymentPromo={hsPaymentPromo} product={productList[activePkgIdx]} />
                                    : <div className="price">${productList[activePkgIdx].retailPrice}</div>
                                }
                            </div>
                            <div className="font-weight-bold my-2">{productList[activePkgIdx].repMessage}</div>
                            <div className="action-button"><a className="btn btn-primary btn-sm" href={productList[activePkgIdx].actionUrl} rel="nofollow">Customize</a></div>
                            <div className="details my-2">
                                <div className="font-weight-bold">Included</div>
                                <ul className="included-product-list mb-0">
                                    {packageItems?.length > 0 && packageItems.filter(product => !product.personalizedAnnouncementItems).map( product =>
                                        <li className="included-product-item mb-1 mr-2 ml-3" key={product.productOsr}>
                                            {
                                                product.displayQtyByPackageOsr[productList[activePkgIdx].productOsr] > 1 
                                                && <span className="item-ammount">{product.displayQtyByPackageOsr[productList[activePkgIdx].productOsr]} </span>
                                            }
                                            {product.displayName}
                                        </li>
                                    )}
                                </ul>
                                <ul>
                                    {packageItems?.length > 0 && packageItems.filter(product => !!product.personalizedAnnouncementItems).map( product =>
                                        <li className="included-product-item mb-1 mr-2 ml-3" key={product.productOsr}>
                                            <div>
                                                {
                                                    product.displayQtyByPackageOsr[productList[activePkgIdx].productOsr] > 1 
                                                    && <span className="item-ammount">{product.displayQtyByPackageOsr[productList[activePkgIdx].productOsr]} </span>
                                                }
                                                {product.displayName}
                                            </div>
                                            {product.personalizedAnnouncementItems && product.personalizedAnnouncementItems
                                                .filter(subProduct => subProduct.prodCatItem === productList[activePkgIdx].productOsr)
                                                .map( subProduct =>
                                                    <div className="ml-2 fontSizeSM" key={subProduct.productOsr}>{subProduct.displayName}</div>
                                            )}
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </Modal.Body>
                    </Modal>
                }
            </section>
        </>
    );
}

export default GradPackageOverviw;