import React, { useEffect, useState } from 'react';
import { ProductBrowseTiles } from '../home/productBrowseTiles';
import { ProductFilterTile } from '../hs-jwl/productFilterTile';

export const UplomaProductTiles = React.memo(({
	pbData,
	pageContent,
	gradPaymentOptions
}) => {
	const [filteredPbData, setFilteredPbData] = useState({});
	const [productData, setProductData] = useState({});

	useEffect(() => {
		const pbDataProducts = pbData?.products
		const filteredPbDataProducts = filteredPbData.products
		const data = {}
		if (filteredPbDataProducts !== undefined) {
			data.products = [...filteredPbDataProducts]
		} else if (pbDataProducts !== undefined) {
			pbDataProducts.sort((a, b) => a.orderSeq - b.orderSeq)
			data.products = [...pbDataProducts]
		}
		setProductData(data)
	}, [pbData, filteredPbData]);

	if (pbData) {
		return (
			<>
				<ProductFilterTile
					pbData={pbData}
					filteredPbData={filteredPbData}
					setFilteredPbData={setFilteredPbData}
				/>

				<ProductBrowseTiles
					pbData={productData}
					pageContent={pageContent}
					paymentOptions={gradPaymentOptions}
					productInfo={{"prodDescDotCms": true, "performSort": false }}
				/>
			</>
		);
	} else {
		return null;
	}
});
