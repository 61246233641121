import React from 'react';

export function ChangeSchool() {
    const changeSchool = () => {
        document.getElementById("affiliate-change")?.click()
    }
	return (
		<div className='row mb-4 mx-0 container-fluid'>
			<a href="#affiliate" class="change-school" onClick = {changeSchool} >Change  School</a>
		</div>
	);
};
