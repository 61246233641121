/**
 * return button with close icon inside
 * @param   {string} dataDismiss 
 * @param   {function} handleCloseClick Button Click Event Handler
 */

export const CloseButton = ({ handleCloseClick, dataDismiss }) => (
    <button
        type="button"
        className="close"
        data-dismiss={dataDismiss}
        aria-label="Close"
        onClick={handleCloseClick}
    >
        <span aria-hidden="true">×</span>
    </button>
)