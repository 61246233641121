

import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'


export const HelpButton = React.memo(() => {
    const [show, setShow] = useState(false);
    return (
        <>
            <button className='helpButton' onClick={()=> setShow(true)}>
                <i className="fas fa-question-circle"></i>
                <span className='ml-1'>Help</span>
            </button>

            <Modal
                size="sm"
                show={show}
                onHide={() => setShow(false)}
                aria-labelledby="help-button-modal-sm"
                dialogClassName="help-button-modal-sm tile-left"
            >
                <Modal.Header>
                    <span>
                        Help
                    </span>
                    <button onClick={() => setShow(false)} className="modal-close-btn" aria-label="close"><i className="fas fa-minus"></i></button>
                </Modal.Header>
                <Modal.Body className="p-4">
                    <a className="modal-help-btn leave-msg" target="_blank" href="/customer-service/contact-us">
                        <i className="fas fa-envelope"></i>
                        <span className='ml-3'>Leave a message</span>
                    </a>
                </Modal.Body>
            </Modal>
        </>
    );
})





    //   <div id="help-btn-modal" style="display: none;">
    //     <div class="modal-header">
    //         <span>Help</span>
    //         <button class="modal-close-btn" aria-label="close"><i class="fas fa-minus"></i></button>
    //     </div>
    //     <div class="modal-content">
    //         <a class="modal-help-btn leave-msg" target="_blank" href="/customer-service/contact-us">
    //             <i class="fas fa-envelope"></i>
    //             <span>Leave a message</span>
    //         </a>
    //     </div>
    // </div>