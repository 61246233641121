import React, { useState } from "react";

export const FilterComponent = ({submitFilterData, filteredItems, isUSCustomer}) => {
    const [lists, setLists] = useState(filteredItems)
    const [colorOptionSelected, setColorOptionSelected] = useState(false)

    const clearAllFilters = () => {
        setLists(lists.map((list) =>
            list.filterGroupName
                ? {
                    ...list,
                    filters: list.filters.map((filterObj) =>
                        filterObj.checked === true
                            ? {
                                ...filterObj,
                                checked: false
                            }
                            : filterObj
                    )
                }
                : list
            )
        );
    }

    const handleTile = (list) => {
        setLists(
            lists.map((item) =>
                item.filterGroupOsr === list.filterGroupOsr ? { ...item, active: !item.active } : item
            )
        );
    };

    const handleChange = (selectedList) => (e) => {
        const { checked, name, id } = e.target;

        setLists(lists.map((list) =>
            list.id === selectedList.id
                ? {
                    ...list,
                    filters: list.filters.map((filterObj) =>
                        filterObj.filterName === name
                            ? {
                                ...filterObj,
                                checked: checked
                            }
                            : name === list.filterGroupOsr ?
                                {
                                ...filterObj,
                                checked: filterObj.filterName === id
                                }
                            : filterObj
                    )
                }
                : list
        )
        );

        if (selectedList.filterGroupOsr === '05-METAL-COLOR') {
            if (checked) {
                const checkedMetalOption = []
                const selectedOtn = selectedList.filters?.find((listData) => listData.filterName === name && !listData.checked)
                checkedMetalOption.push(selectedOtn.filterOsr)
                const prevSelecMetalOtn = []
                lists.forEach((lData) => lData.filterGroupOsr === '05-METAL-COLOR' && lData.filters.forEach((fData) => {
                    if (fData.checked) {
                        prevSelecMetalOtn.push(fData.filterOsr)
                    }
                }))
                const selectedMetalOptions = [...checkedMetalOption, ...prevSelecMetalOtn]
                sessionStorage.setItem("selectedMetalOptions", JSON.stringify(selectedMetalOptions))
            } else {
                let metalOptions = JSON.parse(sessionStorage.getItem("selectedMetalOptions"))
                const selectedOptn = selectedList.filters?.find((listData) => listData.filterName === name)
                metalOptions = metalOptions?.length > 0 && metalOptions.filter((e) => e !== selectedOptn.filterOsr)
                sessionStorage.setItem("selectedMetalOptions", JSON.stringify(metalOptions))
            }
        }
    };

    const submitAllFilterData = () => {
        if(colorOptionSelected){
            const filterLists = [ ...lists ]
            let filterOsrs = []
            filterLists.forEach((filterList)=> {
                if(filterList.active && filterList.filterGroupOsr === "CARD_COLORS"){
                    filterList.filters.forEach((filterObj)=> {
                        if(filterObj.checked){
                            filterOsrs = [...filterOsrs, ...filterObj.filterOptionOsrs]
                        }
                    })
                }
            })
            sessionStorage.setItem("filterOptionOsrs", JSON.stringify(filterOsrs))
            submitFilterData(lists)
        } else {
            submitFilterData(lists)
        }
    }

    return (<>
        <div className="filter-section">
            {lists?.length > 0 && lists.map((ar, index) => (
                <div className="heading" key={index}>
                    <AccordionHeader filterGroup={ar} onClick={() => handleTile(ar)} setLists={setLists} lists={lists} />

                    {ar.active && ar.filters?.length > 0 &&
                    (ar.filterGroupOsr !== "CARD_COLORS" ? (
                        ar.filters.map((inner, key) => {
                            let name = [inner.filterName];
                            let nameStyle = {
                                color:'#cd0c0c',
                                fontWeight: 700
                            }
                            if(inner.filterName?.includes('NEW')){
                                name = inner.filterName.split('-');
                            }
                            if(inner.filterName === "Basic" && (inner.filterOsr === "11-BASIC"|| inner.filterOsr === "12-BASIC") && isUSCustomer){
                                inner.filterItems = []
                            }
                            return(
                                inner.filterItems?.length > 0 &&
                                <div key={key} className="filter-options">
                                    <span className="pl-5">
                                        <input
                                            type={ar.filterGroupOsr !== "CARD_TRIM"?"checkbox":"radio"}
                                            onChange={handleChange(ar)}
                                            checked={inner.checked}
                                            name={ar.filterGroupOsr !== "CARD_TRIM"? inner.filterName :ar.filterGroupOsr}
                                            id={inner.filterName}
                                        />
                                    </span>
                                    <span className="pl-2">{name[0]}</span> 
                                    <span className="pl-2" style={nameStyle}>{name[1]}</span>
                                </div>
                            )
                        }) 
                        ): (
							<ColorGrid filterGroup={ar} lists={lists} setLists={setLists} setColorOptionSelected={setColorOptionSelected} />
					    )
                    )}
                </div>
            ))}
        </div>
        <div className="align-items-end mt-3">
            <button variant="secondary" className="secondary-action-button mr-3" onClick={()=> clearAllFilters()}>
                CLEAR ALL
            </button>
            <button variant="primary" className="primary-action-button mr-3" onClick={()=> submitAllFilterData()}>
                APPLY
            </button> 
        </div>  
    </>
    );
}

const AccordionHeader = ({ filterGroup, onClick, setLists, lists }) => {
    const clearSelectedItems = () => {
        setLists(lists.map((list) =>
            list.id === filterGroup.id
                ? {
                    ...list,
                    filters: list.filters.map((filterObj) => filterObj.checked = false)
                }
                : list
            )
        );
    }
    return (
        <div className={filterGroup.active ? "tile tile-is-active" : "tile"} onClick={onClick}>
            <div className="tile-left">
                <div>
                    <strong>{filterGroup.filterGroupName}</strong>
                    
                    {
                        filterGroup?.filters?.length > 0 && (filterGroup.filters.filter((filter) => filter.checked === true))?.length > 0 && 
                        <>
                            <span className="ml-2 text-circle">{(filterGroup.filters.filter((filter) => filter.checked === true))?.length}</span>
                            <span className="text-underline fontSizeXS ml-3" onClick={()=>clearSelectedItems()}>Clear</span>
                        </>
                    }
                    
                    
                </div>
            </div>
            <div className="tile-right">
                {
                    filterGroup.active ? <i className="fas fa-thin fa-angle-up cursorPointer"></i> : <i className="fas fa-thin fa-angle-down cursorPointer"></i>
                }
            </div>
        </div>
    );
}

const ColorGrid = ({ filterGroup, lists, setLists, setColorOptionSelected}) => {

	const handleClick = (item) => {
        setColorOptionSelected(true)
		setLists(
			lists.map((list) =>
				list.id === filterGroup.id
					? {
							...list,
							filters: list.filters.map((filterObj) =>
								filterObj.filterId === item.filterId
									? {
											...filterObj,
											checked: filterObj.checked ? false : true,
									  }
									: filterObj
							)
					  }
					: list
			)
		);
	}
	return (
		<div className='color-filter-grid'>
			{filterGroup?.filters?.length &&
				filterGroup?.filters.map(
					(item, index) =>
						item.filterItems?.length > 0 && (
							<div key={index} onClick={()=>handleClick(item)} className={`jos-color-parent ${item.checked?'active' :''}`}>
								<svg
									aria-hidden='true'
									focusable='false'
									data-prefix='fas'
									data-icon='check-circle'
									className='selected_trim'
									role='img'
									xmlns='http://www.w3.org/2000/svg'
									viewBox='0 0 512 512'>
									<path
										fill='#17955E'
										d='M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z'></path>
								</svg>
								<div className={`jos-square-filter-item filter-color ${item.checked?'selected' :''}`}
								style={{ backgroundColor: item.filterName }}></div>
							</div>
						)
				)}
		</div>
	);
};