import React, { useEffect, useState } from 'react';
import { imgUrlConverter, hostUrlConverter, checkURLHasDomain } from '../../util';
import { Carousel } from 'react-bootstrap';

export const PhotoCardCarousel = (props) => {
  const [flavoredImagesIndex, setFlavoredImagesIndex] = useState(JSON.parse(sessionStorage.getItem("flavoredImageIndex")) ? JSON.parse(sessionStorage.getItem("flavoredImageIndex")) : 0)

  const setImagesIndexs = (flavoredImageIndexValue) => {
    setFlavoredImagesIndex(flavoredImageIndexValue)
  }

  useEffect(() => {
    return sessionStorage.removeItem("flavoredImageIndex")
  }, [])

  const nextIcon = () => {
    return <div className="swiper-btn-next"><i className="far fa-arrow-right"></i></div>
  }
  const prevIcon = () => {
    return <div className="swiper-btn-prev"><i className="far fa-arrow-left"></i></div>
  }

  return (<div>
    <Carousel nextIcon={nextIcon()} prevIcon={prevIcon()} indicators={false} indicatorlabels={[]} interval={null} key={"slider"}>
      {
        props.productData?.flavoredOptions[flavoredImagesIndex]?.flavoredProductImages?.map((altImage) =>
          <Carousel.Item key={`${altImage.variantType}`}>
            <div className="cursorPointer">
              <div>
                <picture>
                  <source media="(min-width: 1920px)" srcSet={altImage?.imageUrl} />
                  <img src={altImage?.imageUrl || props?.altImage} title={`${props.productData?.displayName} - ${props.productData?.flavoredOptions[flavoredImagesIndex].displayName} (${altImage.variantType})`} alt={altImage.variantType} onError={(e)=> e.target.src = props?.altImage} />
                </picture>
              </div>
            </div>
          </Carousel.Item>
        )
      }
    </Carousel>
    {
      props.productData.flavoredOptions?.length > 1 &&
      <div className='flavored-options'>
        {
          props.productData.flavoredOptions.map((options, index) => {
            if (index < 4) {
              return (<div key={index} title={options.displayName} className={`filter-color ${options.optionOsr} checked`} onClick={() => setImagesIndexs(index)}></div>)
            }
            if (index >= 4 && index === 4) {
              return (<img key={index} className="spiral-img" src={checkURLHasDomain(props.flavoredProductsPageContent?.imageUrl) ? props.flavoredProductsPageContent.imageUrl : imgUrlConverter(props.flavoredProductsPageContent.imageUrl)} onClick={() => window.location.assign(hostUrlConverter(props.productData?.actionUrl))} alt="Spiral Image" />)
            } else {
              return null
            }
          })
        }
      </div>
    }
  </div>);
}