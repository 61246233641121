import { LazyMotion, domAnimation, m } from "framer-motion";
import { uniqBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { LazyLoadComponent, LazyLoadImage, trackWindowScroll } from 'react-lazy-load-image-component';
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import { SLIDER } from '../../constants';
import { hostUrlConverter, imgUrlConverter } from '../../util';
import { PriceComponent } from '../home/price';
import { ANIMATE_DEFAULT_PROPS, ANIMATE_DISPLAY_NAME_PROPS } from './constants';

const AdsBannerComponent = React.memo(({ adsData, ybAdsPageData, scrollPosition }) => {
    const [adsProdData, setAdsProdData] = useState([])
    const learnMoreURL = 'yearbooks/students-and-parents/yearbook-ads'

    const getProductWithSubHeading = (productBrowseAds = [], product) => {
        return productBrowseAds.reduce((acc, curr) => {
            const productOSRs = curr?.ctaCheck?.productOsr?.split(',')
            const isProductHasEqualOsr = productOSRs.includes(product?.productOsr)

            acc = product
            if (isProductHasEqualOsr) {
                // Subheading received as HTML <p> element placed inside the string
                // in the code below was removed unnecessary tag ang get the content
                const subHeadingContent = curr?.subheading?.slice(3, curr.subheading.length - 4)
                acc.subHeading = subHeadingContent
            }

            return acc
        }, {})
    }

    useEffect(() => {
        if (adsData?.length > 0) {
            const groupOsrs = ybAdsPageData?.ctaCheck?.groupOsr?.split(",")

            groupOsrs?.length > 0 && groupOsrs.map((osrValue) =>
                adsData.map((ProductData) => {
                    if (ProductData.groupOsr === osrValue) {
                        ProductData?.products?.map((productObj) => {
                            const productData = getProductWithSubHeading(ybAdsPageData.ybProductBrowseAds, productObj)

                            setAdsProdData(prevState => uniqBy([...prevState, productData], 'displayName'))
                        }
                        )
                    }
                    return true
                })
            )
        }
    }, [adsData, ybAdsPageData?.ctaCheck?.groupOsr])

    if (adsProdData?.length > 0) {
        return (
            <LazyMotion features={domAnimation}>
                <LazyLoadComponent scrollPosition={scrollPosition}>
                    <div className="product-categories">
                        <div className="row mb-3 mx-2">
                            <m.div
                                className="container-fluid"
                                variants={SLIDER.CONTAINER}
                                initial="hidden"
                                animate="show"
                            >
                                <LazyLoadComponent>
                                    <Slider>
                                        {adsProdData?.map((productObj, key) => {
                                            return (
                                                <m.div
                                                    key={`ProductObj-${key}`}
                                                    className="product"
                                                    variants={SLIDER.PRODUCT}
                                                >
                                                    <div className="ads-banner">
                                                        <m.div
                                                            className="overlay"
                                                            animate={ANIMATE_DEFAULT_PROPS}
                                                        >
                                                            <div className="inner">
                                                                <div>
                                                                    <m.h2
                                                                        className="my-3"
                                                                        animate={ANIMATE_DISPLAY_NAME_PROPS}
                                                                    >
                                                                        {productObj?.displayName}

                                                                    </m.h2>
                                                                    {productObj?.subHeading && <p>{productObj?.subHeading}</p>}
                                                                    {productObj !== undefined && Object.keys(productObj).length > 0 && <div className="price-label my-3"><PriceComponent data={productObj} /></div>}
                                                                    {
                                                                        Object.keys(ybAdsPageData)?.length > 0 && ybAdsPageData.ybProductBrowseAds?.map((adsButton, key) => {
                                                                            const productOsrs = adsButton.ctaCheck?.productOsr?.split(",");
                                                                            if (productOsrs?.length === 1 && adsButton.ctaCheck?.productOsr === productObj.productOsr) {
                                                                                return <div>
                                                                                    <span key={key} className="my-3 d-inline-block"><a href={hostUrlConverter(productObj.actionUrl)} className="ads-button btn">{adsButton?.ctaButton}</a></span>
                                                                                    <span key={key} className="learnmore-button"><a href={hostUrlConverter(learnMoreURL)} className="ads-button btn">Learn More</a></span>
                                                                                </div>
                                                                            } else {
                                                                                return (<>
                                                                                    {
                                                                                        productOsrs?.length > 1 && productOsrs.map((osrValue) => {
                                                                                            if (osrValue === productObj.productOsr) {
                                                                                                return <div>
                                                                                                    <span key={key} className="my-3 d-inline-block"><a href={hostUrlConverter(productObj.actionUrl)} className="ads-button btn">{adsButton?.ctaButton}</a></span>
                                                                                                    <span key={key} className="learnmore-button"><a href={hostUrlConverter(learnMoreURL)} className="ads-button btn">Learn More</a></span>
                                                                                                </div>
                                                                                            }
                                                                                            return true
                                                                                        })
                                                                                    }
                                                                                </>)

                                                                            }
                                                                        })
                                                                    }

                                                                </div>
                                                            </div>

                                                        </m.div>
                                                        {
                                                            Object.keys(ybAdsPageData)?.length > 0 && ybAdsPageData.ybProductBrowseAds?.map((adsImage, key) => {
                                                                const productOsrs = adsImage.ctaCheck?.productOsr?.split(",");
                                                                if (productOsrs?.length === 1 && adsImage.ctaCheck?.productOsr === productObj.productOsr) {
                                                                    return (
                                                                        <a key={key} role="button" href={hostUrlConverter(productObj?.actionUrl)}>
                                                                            <div className="img-wrapper">
                                                                                <LazyLoadComponent>
                                                                                    <picture>
                                                                                        <source media="(min-width: 1024px)" srcSet={adsImage.desktopImageUrl ? imgUrlConverter(adsImage.desktopImageUrl) : imgUrlConverter(productObj?.imageUrl)} />
                                                                                        <LazyLoadImage src={adsImage.mobileImageUrl ? imgUrlConverter(adsImage.mobileImageUrl) : imgUrlConverter(productObj?.imageUrl)} alt={productObj?.displayName} scrollPosition={scrollPosition} />
                                                                                    </picture>
                                                                                </LazyLoadComponent>
                                                                            </div>
                                                                        </a>)
                                                                } else {
                                                                    return (<>
                                                                        {
                                                                            productOsrs?.length > 1 && productOsrs.map((osrValue) => {
                                                                                if (osrValue === productObj.productOsr) {
                                                                                    return (
                                                                                        <a key={key} role="button" href={hostUrlConverter(productObj?.actionUrl)}>
                                                                                            <div className="img-wrapper">
                                                                                                <LazyLoadComponent>
                                                                                                    <picture>
                                                                                                        <source media="(min-width: 1024px)" srcSet={adsImage.desktopImageUrl ? imgUrlConverter(adsImage.desktopImageUrl) : imgUrlConverter(productObj?.imageUrl)} />
                                                                                                        <LazyLoadImage src={adsImage.mobileImageUrl ? imgUrlConverter(adsImage.mobileImageUrl) : imgUrlConverter(productObj?.imageUrl)} alt={productObj?.displayName} scrollPosition={scrollPosition} />
                                                                                                    </picture>
                                                                                                </LazyLoadComponent>
                                                                                            </div>
                                                                                        </a>)
                                                                                }
                                                                                return true
                                                                            })
                                                                        }
                                                                    </>)
                                                                }
                                                            })
                                                        }
                                                    </div>

                                                </m.div>)

                                        })}

                                    </Slider>
                                </LazyLoadComponent>
                            </m.div>
                        </div>

                    </div>
                </LazyLoadComponent>
            </LazyMotion>)
    } else {
        return null
    }
})

export default trackWindowScroll(AdsBannerComponent)