import React from 'react';

export const Spinner = () => {
  return (
    <>
      <div className="jcom-overlay">
        <div className="jcom-loader"></div>
      </div>
    </>
  );
}