import React, { useEffect, useState, useCallback } from 'react'
import { ExpiredComingSoonBanner } from './expiredComingSoonPLP'
import { ExpiredComingSoonAccessories } from "./expiredCSYbAccessories"
import { YearbookPLP } from './ybPLPDataParser'
import { YBEXPIREDMSGS, YBACCESSORIES } from'../../constants/rootElements'

export const YearbookPage = React.memo((props) => {
	const { appData } = props
	const [ybPLP, setYbPLP] = useState("")

	const YbExpiredBannerData = appData?.pagecontentData?.find(data => data.contentType === "YbExpiredMsgs")
	const YbCSBannerData = appData?.pagecontentData?.find(data => data.contentType === "YbComingSoonMsgs")
	const YbAccPageData = appData?.pagecontentData?.find(data => data.contentType === "ProductBrowseIndividualProducts")

	useEffect(()=> {
		Object.keys(appData?.productBrowseData)?.length > 0 && Object.keys(appData.productBrowseData).map((ybObjData) => {	
			if(ybObjData !== "metaData"){	
				if(appData.productBrowseData[ybObjData]?.dotCmsTemplateName === "YB-CROSSSELL"){
					setYbPLP("others")
					return
				}
				if (appData.productBrowseData[ybObjData]?.statusDescription === "ACTIVE") {
					setYbPLP("ongoing")
				} else {
					setYbPLP("expired")
				}
				
			}			
			return true
		})
	},[appData?.productBrowseData])

	const getExpiredComingSoonBanner = useCallback(() => {
		(YbExpiredBannerData || YbCSBannerData) &&
			YBEXPIREDMSGS.render(
				<ExpiredComingSoonBanner
					pageContentData={
						YbExpiredBannerData ? YbExpiredBannerData : YbCSBannerData
					}
					appData={appData}
				/>
			);
		YbAccPageData && YBACCESSORIES && 
			YBACCESSORIES.render(
				<ExpiredComingSoonAccessories
					appData={appData}
					ybAccPageData={YbAccPageData}
				/>
			);
	}, [YbCSBannerData, YbExpiredBannerData, appData, YbAccPageData]);

	console.log("CURRENT STATE OF YB: " + ybPLP)
	return (		
		<div className="container-fluid">
			{
				(ybPLP === "ongoing" || ybPLP === "others") && <YearbookPLP appData={appData} />
			}
			{
				(ybPLP === "expired" || ybPLP === "comingsoon") && getExpiredComingSoonBanner()
			}
			
		</div>
	)
})
