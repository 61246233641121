import React from 'react'
import { LazyLoadImage, trackWindowScroll } from 'react-lazy-load-image-component';

export const HeroBanner = trackWindowScroll(React.memo(({
    imgURL,
    imgAltText,
    heading,
    subHeading,
    scrollPosition
}) => {
    return (
        <div className="hero-banner-container">
            <div className="hero-banner-img">
                <picture>
                    <source media="(min-width: 1024px)" srcSet={imgURL?.xl} />
                    <LazyLoadImage src={imgURL?.md} alt={imgAltText} scrollPosition={scrollPosition} />
                </picture>
            </div>
            <div className="hero-banner-conntent">
                <h1 className="banner-heading mb-3">{heading}</h1>
                <p className="banner-subheading"><div dangerouslySetInnerHTML={{__html:subHeading}}></div></p>
            </div>
        </div>
    )
}))
