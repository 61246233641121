import React, { useEffect, useState, useCallback } from 'react'
import { imgUrlConverter } from '../../util'

export const PromoMessage = React.memo(({ pbData, activeState }) => {
    const [showExpandPromoBanner, setShowExpandPromoBanner] = useState(activeState);
    const popupText = "*Offer not available on Basic (P-Rings). This offer cannot be used in combination with any offers, discounts, or promotions."
    const dateText = pbData?.event?.dateText && pbData?.event?.dateText.split(/\s+/);
    const promoImgUrl = "apps/catalog/catalogImage.mvc?type=highschool&variant=product_lg&product=JWL_PLP_Banner&imageType=product&jc3b=20211117242&version=2"
    const plusFreeProductName = 'Companion Gratitude Ring'
    const gifts = [].concat(
        pbData.gifts.filter(gift => gift.name !== plusFreeProductName),
        pbData.gifts.filter(gift => gift.name === plusFreeProductName),
        pbData.companionRing
    ).filter(el => (el !== null) && (el !== undefined))

    const promoGridDynamic = gifts.length > 0 ? "promo-grid" : "promo-gridtwocolumns"

    const promoImageGridClasses = [
        '',
        "promo-one-image-grid",
        "promo-two-image-grid",
        "promo-three-image-grid",
        "promo-four-image-grid"
    ]
    const promoImageGridDynamic = promoImageGridClasses[gifts?.length] || ''
    
    const promoInnerDivDynamic = gifts.length > 0 ? "promo-inner-div" : "promo-inner-twodiv"
        
    const imageTextAlignDynamic = gifts.length ===1  ? "image-text" : "";
    
    if (pbData) {
        return (
            <>
                <div className="desktopPromoBanner mb-1">
                    <button type="button" className="close" onClick={() => setShowExpandPromoBanner(!showExpandPromoBanner)}>
                        {showExpandPromoBanner ? <i className="fa fa-angle-up button-up" aria-hidden="true"></i> : <i className="fa fa-angle-down button-up" aria-hidden="true"></i>}
                    </button>
                    {showExpandPromoBanner && <div className="desktop-container">
                        <div className={`${promoInnerDivDynamic} ${promoGridDynamic }`}>
                            <div className="banner-image-grid">
                                <div className = "banner-image">
                                    <img  width='100%' height= '100%'
                                        src={imgUrlConverter(promoImgUrl)}></img>
                                </div>
                                <div className="Promo-event-overview">
                                    {dateText &&
                                        <> 
                                            <div className="promo-event-head-text">{dateText[0]}</div>
                                            <div className="promo-event-month-text">{dateText[1]}</div>
                                        </>
                                    }
                                    {pbData.event?.time && <div className="promo-event-time-text">{pbData.event?.time}</div>}
                                    {pbData.event?.location && <div className="promo-event-location-text">{pbData.event?.location}</div>}
                                    {pbData.event?.title && <div className="promo-event-order-text">{pbData.event?.title}</div>}
                                </div>
                            </div>
                            { pbData?.messageDesc &&
                                <div className="promo-message">
                                    <div>
                                    {pbData?.messageTitle &&  <h4 className="promo-heading">{pbData?.messageTitle}</h4>}
                                    {pbData?.messageDesc &&<span class="promo-text-font" dangerouslySetInnerHTML={{ __html: pbData?.messageDesc }}></span>}
                                    <span class="show-long-desc-popup"
                                        onClick={(e) => {
                                            e.target.closest('.promo-message')?.querySelector('.long-desc-popup')?.classList.remove('d-none');
                                        }}>
                                        <i class="fas fa-info-circle"></i>
                                    </span>
                                    <div class="long-desc-popup d-none">
                                        <button className='Closeinfo' title="Close"
                                            onClick={(e) => {
                                                e.target.closest('.promo-message')?.querySelector('.long-desc-popup')?.classList.add('d-none');
                                            }}>
                                            <i className='fas fa-times'></i>
                                        </button>
                                        <div className="">{popupText}</div>
                                    </div>
                                    </div>
                                </div >
                            }
                            <div className={` ${promoImageGridDynamic }`}>
                                {gifts.length > 0 &&
                                    gifts.map((product, index, array) => {
                                        return (
                                            <>
                                                { index === 0 
                                                    ? "" 
                                                    : ( product.name === plusFreeProductName 
                                                        ? <div className="condition-textplus-font">+</div>
                                                        : <div className="condition-text-font">OR</div> )
                                                }
                                                <div>
                                                    <img style = {{ "max-width": "90%"}}
                                                        src={product.imageUrl}></img>
                                                    <div className ={`product-text-font ${imageTextAlignDynamic}`} title = {product.name}>{product.name}</div>
                                                </div>
                                                
                                            </>
                                        )
                                    })
                                }
                            </div>
                            </div>
                    </div>
                    }
                    {!showExpandPromoBanner &&
                        <div className="promotion-banner"> 
                            {pbData?.messageDesc && <div className="container" dangerouslySetInnerHTML={{ __html: pbData?.messageDesc }}></div>}
                        </div>
                    }
                </div>
                <div className="mobilePromoBanner mb-1">
                    <button type="button" className="close" onClick={() => setShowExpandPromoBanner(!showExpandPromoBanner)}>
                        {showExpandPromoBanner ? <i className="fa fa-angle-up button-up" aria-hidden="true"></i> : <i className="fa fa-angle-down button-up" aria-hidden="true"></i>}
                    </button>
                    {showExpandPromoBanner && <div className="mobile-container">
                        <div className="mobile-inner">
                            <div className="mobile-grid-row">
                                <div>
                                    <img width='100%'  src={imgUrlConverter(promoImgUrl)}></img>
                                    {dateText && <div className="mobile-promo-event-text"> {dateText[1]}. {dateText[0]}</div>}
                                    {pbData.event?.time &&<div className="mobile-promo-event-text"> {pbData.event?.time}</div>}
                                </div>
                                <div className= "text-div">
                                    {pbData?.messageTitle &&  <div className="text-margin">{pbData?.messageTitle}</div>}
                                    {pbData?.messageDesc && <p className = "ptag"><span className ="para-text"  dangerouslySetInnerHTML={{ __html: pbData?.messageDesc }}></span>
                                    <span class="show-long-desc-popup" onClick={(e) => {
                                        e.target.closest('.mobile-grid-row')?.querySelector('.long-desc-popup')?.classList.remove('d-none');
                                    }}>
                                    <i class="fas fa-info-circle"></i>
                                </span></p>}
                                <div class="long-desc-popup d-none">
                                        <button className='Closeinfo' title="Close"
                                            onClick={(e) => {
                                                e.target.closest('.mobile-grid-row')?.querySelector('.long-desc-popup')?.classList.add('d-none');
                                            }}>
                                            <i className='fas fa-times'></i>
                                        </button>
                                        <div class="long-desc-popup-text">{popupText}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="mobile-commom-event-row">
                                {pbData.event?.location && <div className="mobile-event-text">{pbData.event?.location} </div>}
                                {pbData.event?.title && <div className="mobile-event-text">{pbData.event?.title} </div>}
                            </div>

                        </div>
                    </div>}
                    {!showExpandPromoBanner &&
                        <div className="promotion-banner">
                            {pbData?.messageDesc && <div className="container" dangerouslySetInnerHTML={{ __html: pbData?.messageDesc }}></div>}
                        </div>
                    }
                </div>
            </>

        )
    }
})