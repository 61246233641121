import React, { useState } from 'react';
import GradPackageTable from '../hs-grad/gradPackageTable';
import GradPackageOverview from '../hs-grad/gradPackageOverview';

const tabTypes = ['Overview', 'Compare'];

const GradPackageTabs = ({packageList, productList, pageContentData, hsPaymentPromo, altImage}) => {
    const [active, setActive] = useState(tabTypes[0]);

    const setActiveTab = (e, tabType) => {
        e.preventDefault();
        setActive(tabType);
    }
    
    return (
        <>
            {
                packageList?.length > 0 && 
                <div className='container-fluid mt-5'>
                    <h4 className='pb-3 group-name'>{pageContentData?.title}</h4>

                    <ul className="nav nav-tabs package-tabs">
                        {tabTypes.map(type => (
                            <li className="nav-item" key={type}>
                                <a className={`nav-link ${type === active ? 'active':''}`} onClick={(e) => setActiveTab(e, type)} href="#">{type}</a>
                            </li>
                        ))}
                    </ul>

                    <div className="package-tabs-content">
                        {
                            {
                                'Overview': <GradPackageOverview packageList={packageList} productList={productList} hsPaymentPromo={hsPaymentPromo} altImage={altImage}/>,
                                'Compare': <GradPackageTable packageList={packageList} productList={productList} pageContentData={pageContentData} />
                            }[active]
                        }
                    </div>
                </div>
            }
        </>
    );
}

export default GradPackageTabs;