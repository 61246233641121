import React, { useEffect, useState } from 'react';
import axios from 'axios'

export const CatalogPromo = React.memo(({ ybPBCatalogMsg, heroBannerData, endDate, setYbPromoList }) => {
    const [ybCopiesMsg, setYbCopiesMsg] = useState("")
    const [countDownMsg, setCountDownMsg] = useState("")
    const [currentDate, setCurrentDate] = useState()

    const [idmsNumber, setIdmsNumber] = useState();
    const [dotCmsTemplateName, setDotCmsTemplateName] = useState();
    const [ybaApiData, setYbaApiData] = useState();
    const [copiesCountDownMsg, setCopiesCountDownMsg] = useState(false)

    useEffect(()=> {
        let products = "";
        let productsFlag = true;
        heroBannerData.length > 0 && heroBannerData.map((data) => {
            setDotCmsTemplateName(data?.dotCmsTemplateName)
            if (data.groupOsr === "YB_BOOKS" && productsFlag) {
                products = data.products;
                productsFlag = false
            }
        })
        if (products.length > 0) {
            setIdmsNumber(products[0]?.idmsOrderNumber)
        }
    },[heroBannerData])

    useEffect(() => {
        if (idmsNumber) {
            axios({
                method: 'GET',
                url: `${process.env.REACT_APP_YBA_API_PATH}/inventory/jwt/${idmsNumber}`,
            })
                .then(response =>{
                    let ybaToken = JSON.parse(JSON.stringify(response.data))
                    axios({
                        url: `${process.env.REACT_APP_YBA_API_PATH}/inventory/yearbook/${idmsNumber}`,
                        method: "GET",
                        headers: {
                            'Authorization': 'Bearer ' + ybaToken
                        }
                    })
                        .then(res => {
                            setYbaApiData(res?.data);
                            if(res?.data?.waitlistEnrolled && dotCmsTemplateName == 'YB-1'){
                                document.cookie = 'templateName=YB-EXPIRED;path=/'
                            }
                        })
                        .catch((err) => console.error(err))
                })
                .catch((err) => console.error(err));
        }
    },[idmsNumber])

    useEffect(() => {
        let cacheKey = [];
        cacheKey = window.location.pathname?.split("/");

        let url = '';
        if(cacheKey?.length > 0){
            url = `${process.env.REACT_APP_API_PATH}/catalog/v1.0/getPromotionsCodesLite/${cacheKey[4]}`
        }

        url && axios({
            method: 'get',
            url: url,
            headers: {
                "Content-Type": "application/json"
            }
        })
        .then((res) => {
            if(res.data?.length > 0 && res.data[0].usesRemaining > 0){
                setYbPromoList(res.data[0])
                let copiesMsg = ybPBCatalogMsg?.ybNumberOfCopiesMsg;
		        setYbCopiesMsg(copiesMsg?.replace('{copies}', res.data[0]?.usesRemaining)?.replace('{code}', res.data[0]?.promoCode)?.replace('{promoDiscount}', `$${parseFloat(res.data[0]?.adjustmentAmount)}`))
            }
            
        })
        .catch((err) => console.error(err));
		
    },[ybPBCatalogMsg])

    useEffect(() => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_PATH}/catalog/v1.0/time`,
            headers: {
                "Content-Type": "application/json"
            }
        })
        .then(response => setCurrentDate(response.data))
        .catch((err) => console.error(err));

    },[])

    useEffect(() => {
        const days = Math.ceil((endDate - currentDate) / (1000 * 3600 * 24))
        let countDownMsgYb = ybPBCatalogMsg?.ybCountDownMsg;
        let remainingCopiesMsgYb = ybPBCatalogMsg?.ybRemainingCopiesMsg
        let autoOfferShutOff = ybaApiData?.autoOfferShutOff
        let limit = ybaApiData?.limit
        let sold = ybaApiData?.sold
        let copies = limit - sold

        if (autoOfferShutOff && copies <= 35 && copies > 0) {
            setCopiesCountDownMsg(remainingCopiesMsgYb?.replace('{remainingCopies}', copies))
        }
        else if (autoOfferShutOff === false && days < 31) { 
            if (days === 0) {
                setCountDownMsg(countDownMsgYb?.replace('ONLY {days}', "LAST DAY"))
            }
            else if (days > 0) {
                setCountDownMsg(countDownMsgYb?.replace('{days}', days + " DAYS"))
            }
        }
        else {
            setCopiesCountDownMsg("")
            setCountDownMsg("")
        }
    }, [endDate, ybPBCatalogMsg?.ybCountDownMsg, currentDate, ybaApiData])

    return (
        <>
            {heroBannerData && Object.keys(heroBannerData).length > 0 && countDownMsg && ybCopiesMsg === "" && <div className="copies-msg p-2" dangerouslySetInnerHTML={{ __html: countDownMsg }} />}
            {heroBannerData && Object.keys(heroBannerData).length > 0 && copiesCountDownMsg && ybCopiesMsg === "" && <div className="copies-msg p-2" dangerouslySetInnerHTML={{ __html: copiesCountDownMsg }}></div>}
            {ybCopiesMsg && <div className="copies-msg p-2" dangerouslySetInnerHTML={{ __html: ybCopiesMsg }} />}
        </>
    )
})