import React, { useCallback, useEffect, useState } from 'react'
import { AlertsList } from '../../components/Alerts/alertsList'
import {
    BREADCRUMB,
    HELPBUTTON,
    JWLFEATUREDPRODUCTS,
    JWLFILTERGROUPTILES,
    JWLPAYPALBANNER,
    JWLPRODUCTTILES,
    PAGETITLE,
    PROMOMSG
} from '../../constants/rootElements'
import { filterObjectsByNonEmptyKey } from '../../util/filterObjectsByNonEmptyKey'
import { Breadcrumb } from '../breadcrumb'
import { HelpButton } from '../help'
import { PaypalBanner } from '../paypalBanner'
import { TitleBar } from "../productBrowseTitle"
import { FeaturedProducts } from './featuredProducts'
import { HsJewelryFilterGroups } from './hsJewelryFilterGroups'
import { HsProductBrowse } from './hsProductBrowse'
import { PromoMessage } from "./promoMessage"

export const HSJWLPage = ({ appData }) => {
    const [isUSCustomer, setIsUSCustomer] = useState(null)
    const [pbData, setPbData] = useState({})
    const [activePBId, setActivePBId] = useState(null)
    const [filteredPbData, setFilteredPbData] = useState({})
    const [breadcrumbData, setBreadcrupmData] = useState([])
    const [selectedGroup, setSelectedGroup] = useState({})
    const [filteredItems, setFilteredQuickData] = useState([]);
    const [promoMessages, setPromoMessages] = useState([]);
    const [promoBanners, setPromoBanners] = useState([]);
    const [additionalData, setAdditionalData] = useState({});
    const [catalogOSR, setCatalogOSR] = useState()
    
    const JwlTitleBar = appData?.pagecontentData?.find(data => data.contentType === "ProductBrowseTitle")
    const JwlBreadcrumb = Boolean(appData?.pagecontentData?.find(data => data.contentType === "Navigation"))
    const ProductBrowsePromoMsg = Boolean(appData?.pagecontentData?.find(data => data.contentType === "ProductBrowsePromoMsg"))
    const ProductBrowseFiltersGroups = appData?.pagecontentData?.find(data => data.contentType === "ProductBrowseFiltersGroups")
    const ProductBrowseFeatured = appData?.pagecontentData?.find(data => data.contentType === "ProductBrowseFeatured")
    const ProductBrowseFiltersBar = Boolean(appData?.pagecontentData?.find(data => data.contentType === "ProductBrowseFiltersBar"))
    const ProductBrowseProducts = appData?.pagecontentData?.find(data => data.contentType === "ProductBrowseProducts")
    const PaypalBannerData = appData?.pagecontentData?.find(data => data.contentType === "PaypalId")

    const nonEmptyPromoMessages = filterObjectsByNonEmptyKey(promoMessages, 'message')

    const shopAll = () => {
        setFilteredPbData(pbData)
        let quickFilterData = { ...appData.filterData }
        quickFilterData?.filterGroups?.map((filterGroup, index) => {
            filterGroup.id = index;
            filterGroup.active = false;
            filterGroup.filters?.length > 0 && filterGroup.filters.map((filterObj) => {
                if (filterObj.filterOsr === selectedGroup?.filterOsr) {
                    filterObj.checked = true
                } else {
                    filterObj.checked = false
                }
                return true
            })
            return true
        })
        setFilteredQuickData([...quickFilterData?.filterGroups])
    }

    const selectedGroupData = useCallback((groupData) => {
        setSelectedGroup(groupData)
    },[])

    const getBreadcrumb = useCallback(() => {
        BREADCRUMB.render(<Breadcrumb pbData={breadcrumbData} />);
    },[breadcrumbData])

    const buildPromoBannersList = (promoBanners) => {
        if(!promoBanners) return [];

        return promoBanners.map(promoBanner => {
            const bannerMsg = promoBanner.messageTitle
                ? `${promoBanner.messageTitle}: ${promoBanner.messageDesc}`
                : promoBanner.messageDesc

            return { message: bannerMsg }
        })
    }

    const getPromoMsg = useCallback(() => {
        const validPromoBanners = promoBanners?.filter(item => !!item.messageDesc)

        if (validPromoBanners?.length > 0) {
            const enhancedPromoBanners = validPromoBanners.filter(item => !!item.event)
            const textPromoBanners = validPromoBanners.filter(item => !item.event)
            const promoBannersList = buildPromoBannersList(textPromoBanners)
            PROMOMSG.render(
                <>
                    {
                    enhancedPromoBanners.map( (promoBanner, index) => (
                        <PromoMessage pbData = {promoBanner} activeState = {index === 0} />
                    ))
                    }
                    {<AlertsList messages={promoBannersList} />}
                </>
            )
        } else if (promoMessages?.length > 0 && !!nonEmptyPromoMessages?.length) {
            PROMOMSG.render(
                <AlertsList messages={nonEmptyPromoMessages} />
            );
        }
    },[promoMessages, promoBanners])


    const productTiles = useCallback(() => {
        JWLPRODUCTTILES.render(
            <HsProductBrowse 
                pbData={pbData} 
                filteredPbData={filteredPbData} 
                shopAll={shopAll} 
                pageContent={ProductBrowseProducts} 
                filterData={appData.filterData} 
                setFilteredPbData={setFilteredPbData} 
                selectedGroup={selectedGroup} 
                setFilteredQuickData={setFilteredQuickData}
                filteredItems={filteredItems} 
                ProductBrowseFiltersBar={ProductBrowseFiltersBar} 
                setSelectedGroup={setSelectedGroup} 
                isUSCustomer={isUSCustomer} 
                additionalData={additionalData} 
                promoCodes={appData?.PromoCodesData}
                excludeList={appData?.excludeListData}
                catalogOSR={catalogOSR}
                pbId={activePBId}
                />
        );
        // eslint-disable-next-line
    },[
        pbData, 
        filteredPbData, 
        ProductBrowseProducts, 
        appData.filterData, 
        selectedGroup, 
        setFilteredQuickData, 
        filteredItems, 
        ProductBrowseFiltersBar, 
        isUSCustomer, 
        catalogOSR
    ])

    const filterGroupTiles = useCallback(() => {
        JWLFILTERGROUPTILES.render(
            <HsJewelryFilterGroups 
                pbData={pbData} 
                filterData={appData.filterData} 
                setFilteredPbData={setFilteredPbData} 
                selectedGroupData={selectedGroupData} 
                pageContentFilterGroup={ProductBrowseFiltersGroups} 
                altImage={ProductBrowseProducts?.imageUrl}
                setFilteredQuickData={setFilteredQuickData}
            />
        );
    },[
        pbData, 
        appData.filterData, 
        selectedGroupData, 
        ProductBrowseFiltersGroups, 
        filteredItems, 
        setFilteredQuickData
    ])

    const paypalBanner = useCallback(() => {
        JWLPAYPALBANNER.render(<PaypalBanner paypalBannerData={PaypalBannerData} />);
    },[PaypalBannerData])

    const featuredProducts = useCallback(() => {
        JWLFEATUREDPRODUCTS.render(
            <FeaturedProducts
                pbData={pbData}
                pbId={activePBId}
                pageContentFeatured={ProductBrowseFeatured}
                fromHsJwl={true}
                altImage={ProductBrowseProducts?.imageUrl}
            />
        );
    },[pbData, ProductBrowseFeatured])

    const getHelpButton = useCallback(() => {
        HELPBUTTON.render(<HelpButton />);
    },[])

    const getTitleBar = useCallback(() => {
        PAGETITLE.render(<TitleBar pageContent={JwlTitleBar} affiliationData={appData?.affiliationData}/>);
    },[])
    
    useEffect(()=> {
        Object.keys(appData.productBrowseData).map((key) => {
            if(key !== "metaData"){
                appData.productBrowseData[key]?.productGroups && Object.keys(appData.productBrowseData[key]?.productGroups).map((catObj)=> {
                    setIsUSCustomer(appData.productBrowseData[key].isUSCustomer)
                    let produtData = appData.productBrowseData[key].productGroups[catObj];
                    produtData = {...produtData, "products":produtData?.products?.sort((a, b) => a.orderSeq - b.orderSeq)}
                    setPbData(produtData)
                    setActivePBId(key)
                    setCatalogOSR(appData.productBrowseData[key].catalogOsr)
                    setBreadcrupmData(appData.productBrowseData[key].productGroups[catObj])
                    setPromoMessages(appData.productBrowseData[key].promoMessagesV2 || appData.productBrowseData[key].schoolStorePromoMessages)
                    setPromoBanners(appData.productBrowseData[key].promoBanners)
                    setAdditionalData({
                        publishedCatalogId: appData.productBrowseData[key].publishedCatalogId,
                        catalogType: appData.productBrowseData[key].sourceCatalogType,
                        customerId: key && key.split("-")[0]
                    })
                    return true
                })
            }
            return true
        })
    },[appData.productBrowseData])

    return (
        <>
        {
            JwlTitleBar && Object.keys(JwlTitleBar)?.length > 0 && getTitleBar()
        }
        {
            JwlBreadcrumb && getBreadcrumb() 
        }
        {
            ProductBrowsePromoMsg && (promoMessages?.length > 0 || promoBanners?.length > 0) && getPromoMsg()
        }
        {
            ProductBrowseFiltersGroups && Object.keys(ProductBrowseFiltersGroups)?.length > 0 && filterGroupTiles()
        }
        {
            ProductBrowseFeatured && Object.keys(ProductBrowseFeatured)?.length > 0 && featuredProducts()
        }
        {/* {
            ProductBrowseFiltersBar && productFilterTile()
        } */}
        {
            ProductBrowseProducts && Object.keys(ProductBrowseProducts)?.length > 0 && productTiles()
        }
        {
            getHelpButton()
        }
        {
            PaypalBannerData && Object.keys(PaypalBannerData)?.length > 0 && paypalBanner()
        }

        </>
    )
}
