import React, { useEffect, useState } from 'react';
import { ProductBrowseTiles } from '../home/productBrowseTiles';
import { ProductFilterTile } from '../hs-jwl/productFilterTile';
import Select from 'react-select';

export const DiplomaProductTiles = React.memo((props) => {
	const [filteredPbData, setFilteredPbData] = useState({});
	const [filteredItems, setFilteredQuickData] = useState([]);
	const [selectedGroup, setSelectedGroup] = useState({});
	const [showDropDown, setShowDropDown] = useState(false);
	const [productData, setProductData] = useState({"products": []});
	const [subCatProductData, setSubCatProductData] = useState({});
	const [selectedOption, setSelectedOption] = useState({});
	const [catalogOptions, setCatalogOption] = useState([]);
	const [subCatFilterData, setSubCatFilterData] = useState({});

	useEffect(() => {
		setSubCatProductData(Object.keys(filteredPbData)?.length > 0 ? { "products": [...filteredPbData.products] } : { "products": [...productData?.products?.sort((a, b) => a.orderSeq - b.orderSeq)] })
	}, [productData, filteredPbData]);

	useEffect(() => {
		if (props.serviceData?.subCatalogRefKeys?.length > 0) {
			const options = [];
			setShowDropDown(props.serviceData?.subCatalogRefKeys?.length > 1)
			props.serviceData?.subCatalogRefKeys?.forEach((subCatalog) => {
				let opt = {}
				opt.value = subCatalog.catalogKey
				opt.label = subCatalog.catalogName
				options.push(opt);
			});
			setCatalogOption(options)
		} else {
			props.serviceData?.productGroups &&
				Object.keys(props.serviceData?.productGroups)?.length > 0 &&
				Object.keys(props.serviceData?.productGroups).forEach(optionKey => {
					setProductData({ "products": props.serviceData?.productGroups[optionKey]?.products })
					setSubCatFilterData(props.serviceData?.productGroups[optionKey]?.products?.length > 6 ? props.filterData : {})
					}
				)
		}
	},[props.serviceData])

	useEffect(() => {
		if (selectedOption && Object.keys(selectedOption)?.length > 0) {
			let selectedOptionProducts = {};
			props.serviceData?.subCatalogs[selectedOption.value]?.productGroups &&
				Object.keys(
					props.serviceData?.subCatalogs[selectedOption.value]?.productGroups
				)?.length > 0 &&
				Object.keys(
					props.serviceData?.subCatalogs[selectedOption.value]?.productGroups
				).forEach(
					(optionKey) =>
						(selectedOptionProducts =
							props.serviceData?.subCatalogs[selectedOption.value]
								?.productGroups[optionKey])
				);
			let products = selectedOptionProducts.products;
			setProductData(
				Object.keys(selectedOptionProducts)?.length > 0
					? {
							products: products,
					  }
					: {}
			);
			if (products?.length > 6) {
				let catalogFilters = { ...props.filterData };
				props.filterData?.filterGroups?.forEach((filterObjs, index) => {
					let filter = filterObjs.filters?.filter((filterObj) => {
						let isApplicable = false;
						products.forEach((productObj) => {
							filterObj.filterItems.forEach((item)=>{
								if(productObj[props.fieldToCompare || "productOsr"]?.startsWith(item)) {
									isApplicable = true;
								}
								if(isApplicable) {
									return;
								}
							})
						});
						return isApplicable;
					});
					catalogFilters.filterGroups[index].filters = [...filter];
				});
				setSubCatFilterData(catalogFilters);
			} else {
				setSubCatFilterData({});
			}
		}
	}, [selectedOption]);

	if (props.serviceData) {
		return (
			<>
				{showDropDown &&
					<>
						<div className="default-text">Select a collection from the drop down below to see results.</div>
						<div className='row container-fluid mb-3'>
							<div className={`col-lg-4 col-md-6 ${selectedOption && Object.keys(selectedOption)?.length > 0 ? '' :'highlight-border'}`}><Select defaultValue={selectedOption && Object.keys(selectedOption)?.length > 0 ? selectedOption : {"label": "Select A Collection", "value": ""}} onChange={setSelectedOption}
										options={catalogOptions} /></div>
						</div>
					</>
				}
				{((showDropDown && Object.keys(selectedOption)?.length > 0) || (!showDropDown)) && (
					<>
						<ProductFilterTile
							pbData={productData}
							filteredPbData={filteredPbData}
							filterData={subCatFilterData}
							setFilteredPbData={setFilteredPbData}
							selectedGroup={selectedGroup}
							setFilteredQuickData={setFilteredQuickData}
							filteredItems={filteredItems}
							setSelectedGroup={setSelectedGroup}
							fieldToCompare={props.fieldToCompare}
						/>
						<ProductBrowseTiles
							pbData={subCatProductData}
							pageContent={{...props.pageContent,"title":`${selectedOption?.label ? selectedOption?.label : ''} ${props.pageContent?.title}`}}
							productInfo={{"prodDescDotCms": true, "performSort": false }}
							isDiploma={true}
						/>
					</>
				)}
			</>
		);
	} else {
		return null;
	}
});
