import React from 'react'

export const ChipList = ({ chipItemsData = [], chipItemClassName }) => {
    const chipItems = chipItemsData.map(item => (
        <span 
            key={item?.className} 
            className={`${item?.className} ${chipItemClassName}`}
        >
            {item?.content}
        </span>
    ))

    return (
        <div className='promo-label-container'>
            {chipItems}
        </div>
    )
}