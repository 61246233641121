import { useContext } from 'react';
import { Context } from './productBrowseProvider';

export const useGetProductBrowseData = () => {
    const productBrowseContext = useContext(Context);

    return productBrowseContext;
}

export const useGetProductBrowsePromoBanners = (productBrowseDataId) => {
    const productBrowseContext = useContext(Context);

    return productBrowseContext?.[productBrowseDataId]?.promoBanners;
}