import React from 'react';
import { ColRegaliaDropdownUx } from './colRegaliaDropdownUx';
import { ColRegaliaDialogUx } from './colRegaliaDialogUx';

export const ColRegaliaProductTiles = React.memo((props) => {

	return props.isRegaliaUx 
		? <ColRegaliaDialogUx {...props} /> 
		: <ColRegaliaDropdownUx {...props} />
});
