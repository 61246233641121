import React from 'react';

import { LABELS } from '../../constants';
import { decimalFormat, notNullCheck } from './utils';

export const PriceComponent = React.memo(({
	data,
	pageContent,
	fromHsJwl,
	isColJlry,
	isColRegalia,
	showYbDiscountPrice,
	isDiscountedPriceVisible = true
}) => {
	const {
		pricePreMessage,
		pricePostMessage,
		currencyCode,
		promoCode,
		discountedMinPrice,
		productMinPrice,
		retailPrice,
		messageWithPromoCode,
	} = data || {};

	let showPreMessage = false;
	const currencyCodeToDisplay = currencyCode === 'CAD' ? currencyCode : '';
	const isSchoolToCheck = pageContent?.ctaCheck?.schoolsToCheck && window?.affiliationData?.affiliateId

	if (isSchoolToCheck) {
		const ids = pageContent?.ctaCheck?.schoolsToCheck?.split(',');
		showPreMessage = !ids.includes(window?.affiliationData?.affiliateId);
	}

	const showYbDiscountPriceFunc = () => {
		if (showYbDiscountPrice && notNullCheck(showYbDiscountPrice)) {
			const YBPromoMessageOSRList = ['001_006_1ST LINE', '001_005_DELUXE', '002_006_1ST LINE', '002_005_DELUXE']
			const postMessage = YBPromoMessageOSRList.includes(data.productOsr)
				? LABELS.WITH_PROMO_CODE
				: LABELS.IF_ORDER_NOW

			return (
				<span className='product-price-font discounted-price'>
					<b>{currencyCodeToDisplay}</b> ${decimalFormat(showYbDiscountPrice)}{' '}
					{postMessage}
				</span>
			);
		}
	};

	const getPromoSection = (promoMessage) => (
		<>
			{notNullCheck(discountedMinPrice) && (
				<b className='fontSize1X priceColor ml-1'>
					{currencyCodeToDisplay}
					<span className='ml-1 product-price-font'>
						${decimalFormat(discountedMinPrice)}
					</span>
				</b>
			)}
			<br />
			<b className='ml-1 priceColor wordwrap fontSize1X'>
				{promoMessage}
			</b>
		</>
	)

	const isPriceCorrect = notNullCheck(pricePreMessage) && (notNullCheck(productMinPrice) || notNullCheck(retailPrice))
	const shouldDisplayPricePreMessage = (isColJlry && showPreMessage && isPriceCorrect) || isPriceCorrect

	return (
		<span>
			<span className='fontSizeXS product-font-size'>
				{shouldDisplayPricePreMessage && !isColRegalia && pricePreMessage}
			</span>
			<b className='fontSize1X product-font-size ml-1'>
				{currencyCodeToDisplay}
				{(showYbDiscountPrice || (fromHsJwl && notNullCheck(promoCode) && notNullCheck(discountedMinPrice))) ? (
					<s className='ml-1 product-price-font'>
						{notNullCheck(productMinPrice)
							? <>${decimalFormat(productMinPrice)}  </>
							: notNullCheck(retailPrice)
								? <>${decimalFormat(retailPrice)} </> : null}
					</s>
				) : (
					<span className='ml-1 product-price-font'>
						{notNullCheck(productMinPrice)
							? <>${decimalFormat(productMinPrice)}  </>
							: notNullCheck(retailPrice)
								? <>${decimalFormat(retailPrice)} </> : null}
					</span>
				)}
			</b>
			{messageWithPromoCode && isDiscountedPriceVisible && getPromoSection(messageWithPromoCode)}
			{notNullCheck(pricePostMessage) && (
				<span className='fontSizeXS product-font-size ml-1'>
					{pricePostMessage}
				</span>
			)}
			{showYbDiscountPriceFunc()}
		</span>
	);
}
);
