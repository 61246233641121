import React, { useEffect, useRef, useState } from 'react';
import { ShowContentButton } from './ShowContentButton';
import { CloseButton } from './closeButton';

/**
 * return single alert item
 * @param {string} message Message for alert item to be displayed
 * @param {function} handleAlertClose Alert Close Event Handler
 * @param {number} elementIndex Index of Alert In The List
 * @param {boolean} isVisible Is alert item should be displayed 
 */

export const Alert = ({ handleAlertClose, message, elementIndex, isVisible }) => {
    const [isContentOverflow, setIsContentOverflow] = useState(false)
    const [isAlertExpandable, setIsAlertExpandable] = useState(false)

    const refContainer = useRef(false)
    const refContent = useRef(false)

    const showContentButtonLabel = isContentOverflow
        ? 'See more'
        : 'See less'

    useEffect(() => {
        if (refContainer.current && refContent.current) {
            const rem = parseFloat(getComputedStyle(document.documentElement).fontSize)
            setIsContentOverflow(refContent.current.clientHeight > 3 * rem)
            setIsAlertExpandable(refContent.current.clientHeight > 3 * rem)
        }
    }, [])

    const handleContentButtonClick = () => {
        setIsContentOverflow(!isContentOverflow)
    }

    return isVisible && (
        <div
            ref={refContainer}
            className='alert alert-dismissible fade show'
            role="alert"
        >
            <CloseButton
                dataDismiss="alert"
                handleCloseClick={() => handleAlertClose(elementIndex)}
            />
            <div
                ref={refContent}
                className={isContentOverflow ? 'collapsed' : 'expanded'}
                dangerouslySetInnerHTML={{ __html: `<span>${message}</span>` }}
            ></div>
            {isAlertExpandable && (
                <ShowContentButton
                    handleButtonClick={handleContentButtonClick}
                    label={showContentButtonLabel} />
            )}
        </div>
    )
}