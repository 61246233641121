import React, { useState, useCallback, useEffect } from 'react';

export const ExpiredComingSoonBanner = React.memo((props) => {
	const { pageContentData, appData } = props;
	const [msgPageData, setMsgPageData] = useState({});

	const getSubCatData = (ybCatData, catalogOsr) => {
		Object.keys(ybCatData).map((catalogData) => {
			if (
				ybCatData[catalogData] !== null &&
				typeof ybCatData[catalogData] === 'object'
			) {
				if (pageContentData?.msgs?.length > 0 ) {
					pageContentData?.msgs?.map((pageContentAcc) => {
						let catalogOsrs = pageContentAcc.ctaCheck?.catalogOsr
						if(catalogOsrs?.indexOf(',') !== -1) {
							catalogOsrs = catalogOsrs?.split(',');
						} else if(catalogOsrs?.indexOf('&#44;') !== -1) {
							catalogOsrs = catalogOsrs?.split('&#44;');
						}
						if (catalogOsrs?.length) {
							catalogOsrs?.map((osrValue) => {
								if (osrValue === catalogOsr) {
									setMsgPageData(pageContentAcc);
								}
								return true;
							});
						}
						return true;
					});
				}
			}
			return true;
		});
	};

	const getCatData = useCallback((ybData) => {
		if (ybData !== undefined) {
			Object.keys(ybData)?.length > 0 &&
				Object.keys(ybData).map((pbData) => {
					if (ybData[pbData] !== null && typeof ybData[pbData] === 'object') {
						if (pbData === 'productGroups') {
							getSubCatData(
								ybData[pbData],
								ybData.catalogOsr
									? ybData.catalogOsr
									: ybData[pbData].catalogOsr
							);
						} else if (pbData === 'siteMapRefKeys') {
							ybData[pbData]?.length > 0 &&
								ybData[pbData].map(
									(pbObj, index) =>
										Object.keys(pbObj)?.length > 0 &&
										Object.keys(pbObj)?.map((pbObjKey) =>
											getCatData(ybData[pbData][index][pbObjKey])
										)
								);
						} else {
							getCatData(ybData[pbData]);
						}
					}
					return true;
				});
		} // eslint-disable-next-line
	}, []);

	useEffect(() => {
		Object.keys(appData?.productBrowseData)?.length > 0 &&
			Object.keys(appData.productBrowseData).map(
				(ybObjData) => ybObjData && getCatData(appData.productBrowseData[ybObjData])
			);
	}, [appData.productBrowseData, getCatData]);

	return (
		<>
			{msgPageData ? (
				<div
					className='message-banner warning'
					dangerouslySetInnerHTML={{ __html: msgPageData.ctaMessage }}
				/>
			) : null}
		</>
	);
});
