import React from 'react';

/**
 * return Show Content Button
 * @param {string} label Text to display
 * @param {function} handleButtonClick Button Click Event Handler
 */

export const ShowContentButton = ({ label, handleButtonClick }) => (
    <div className='showContent'>
        <a href='#' onClick={handleButtonClick}>{label}</a>
    </div>
)
