import React, { useCallback, useEffect, useState } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import HeroBannerComponent from './heroBanner'
import AdsBannerComponent from './adsBanner'
import YbAccessories from './ybAccessories'
import { VideoHeroBanner } from './videoHero'
import { Breadcrumb } from '../breadcrumb'
import MediaCallout from './mediaCallout'
import { CatalogPromo } from './catalogPromo'
import { TitleBar } from "../productBrowseTitle";
import { BREADCRUMB, YBHEROBANNER, HEROVIDEO, ADSBANNER, YBACCESSORIES, YBADDACCESSORIES, COPIESMSG, YBMEDIACALLOUT, PAGETITLE, CHANGESCHOOL, PROMOMSG } from '../../constants/rootElements'
import { ChangeSchool } from '../changeSchool';
import { gaYearbookPageLoad } from '../../util/googleAnalytics';
import { AlertsList } from '../../components/Alerts/alertsList';

export const YearbookPLP = React.memo(({ appData }) => {
	const [YbData, setYBData] = useState({})
	const [adsData, setAdsData] = useState([])
	const [heroBannerData, setHeroBannerData] = useState([])
	const [yBAccData, setYBAccData] = useState({})
	const [addAccData, setAddAccData] = useState({})
	const [yBAccPageData, setYBAccPageData] = useState({})
	const [addAccPageData, setAddAccPageData] = useState({})
	const [breadcrumbData, setBreadcrupmData] = useState({})
	const [endDate, setEndDate] = useState(null)
	const [ybActivePromo, setYbActivePromo] = useState()
	const [promoMessages, setPromoMessages] = useState([])
	const [loadGrid, setLoadGrid] = useState(false)
	const [isMob] = useState(document.body.clientWidth < 769)
	const YbTitleBar = appData?.pagecontentData?.find(data => data.contentType === "ProductBrowseTitle")
	const PBBreadcrumb = appData?.pagecontentData?.find(data => data.contentType === "Navigation")
	const PBHeroProducts = appData?.pagecontentData?.find(data => data.contentType === "ProductBrowseHeroProducts")
	const YbPlusVideo = appData?.pagecontentData?.find(data => data.contentType === "MediaCalloutSmall")
	const YbProductBrowseAds = appData?.pagecontentData?.find(data => data.contentType === "YbProductBrowseAds")
	const PBIndividualProducts = appData?.pagecontentData?.filter(data => data.contentType === "ProductBrowseIndividualProducts")
	const YbPBCatalogMsg = appData?.pagecontentData?.find(data => data.contentType === "YbProductBrowseCatalogMsg")
	const YbMediaCallout = appData?.pagecontentData?.find(data => data.contentType === "YbProductBrowseCallOut")
	const ProductBrowsePromoMsg = Boolean(
		appData?.pagecontentData?.find(
			(data) => data.contentType === 'ProductBrowsePromoMsg'
		)
	)

	const getPromoMsg = useCallback(() => {
		PROMOMSG.render(<AlertsList messages={promoMessages} />);
	}, [promoMessages]);

	const getSubCatData = (ybCatData, catalogOsr, status) => {
		Object.keys(ybCatData).map((catalogData) => {
			if(ybCatData[catalogData] !== null && (typeof (ybCatData[catalogData]) === "object")){
				setBreadcrupmData(ybCatData[catalogData]["homewardPathSegments"] ? ybCatData[catalogData] : {})
				if(PBHeroProducts && Object.keys(PBHeroProducts)?.length > 0){
					const groupOsrs = PBHeroProducts.ctaCheck?.groupOsr?.split(",");
					groupOsrs?.length > 0 && groupOsrs.map((osrValue) => {
						if(osrValue === ybCatData[catalogData]["groupOsr"]){
							setHeroBannerData(prevState => [...prevState, ybCatData[catalogData]]);
						}
						return true
					})
				}

				if(YbProductBrowseAds && Object.keys(YbProductBrowseAds)?.length > 0){
					const groupOsrs = YbProductBrowseAds.ctaCheck?.groupOsr?.split(",");
					groupOsrs?.length > 0 && groupOsrs.map((osrValue) => {
						const isValueAndGroupOsrEqual = osrValue === ybCatData[catalogData]["groupOsr"]

						if(isValueAndGroupOsrEqual && status === 'ACTIVE'){
							setAdsData(prevState => [...prevState, ybCatData[catalogData]]);
						}
						return true
					})
				}

				if(PBIndividualProducts?.length > 0){
					PBIndividualProducts.map((pageContentAcc) => {
						const groupOsrs = pageContentAcc.ctaCheck?.groupsOsr?.split(",");
						groupOsrs?.length > 0 && groupOsrs.map((groupOsrValue) => {
							if(groupOsrValue === ybCatData[catalogData]["groupOsr"]){
								const catalogOsrs = pageContentAcc.ctaCheck?.catalogOsr?.split(",");
								if(catalogOsrs?.length > 0){
									catalogOsrs?.length > 1 && catalogOsrs.map((osrValue) => {
										if(osrValue === catalogOsr){
											setAddAccData(ybCatData[catalogData])
											setAddAccPageData(pageContentAcc)
										}
										return true
									})
									if(catalogOsrs?.length === 1 && (catalogOsrs[0] === catalogOsr)){
										setYBAccData(ybCatData[catalogData]);
										setYBAccPageData(pageContentAcc);
									}
								}else {
									setYBAccData(ybCatData[catalogData]);
									setYBAccPageData(pageContentAcc);
								}
							}
							return true
						})
						return true
					})
				}
			}
			return true
		})
	}

	const getCatData = useCallback((ybData, pbObjKey) => {
		if(ybData !== undefined){
			if(ybData.hasOwnProperty("products")){
				PBIndividualProducts?.length > 0 && PBIndividualProducts.map((pageContentAcc) => {
					const groupOsrs = pageContentAcc.ctaCheck?.groupOsr?.split(",");
					groupOsrs?.length > 0 && groupOsrs.map((osrValue) => {
						if(osrValue === ybData.groupOsr){
							setYBAccData(ybData)
							setYBAccPageData(pageContentAcc)
						}
						return true
					})
					return true
					
				})
			}else{
				Object.keys(ybData)?.length > 0 && Object.keys(ybData).map((pbData) => {
					if(ybData[pbData] !== null && (typeof (ybData[pbData]) === "object")){
						if(pbData === "productGroups"){
							const catalogOsrKey = ybData.catalogOsr ? ybData.catalogOsr : pbObjKey ? (pbObjKey.split("-") && pbObjKey.split("-")?.length > 0 && pbObjKey.split("-")[1]) : ""

							getSubCatData(ybData[pbData], catalogOsrKey, ybData?.statusDescription)
						}else if(pbData === "siteMapRefKeys"){
							ybData[pbData]?.length > 0 && ybData[pbData].map((pbObj, index) => 
								Object.keys(pbObj)?.length > 0 && Object.keys(pbObj)?.map((pbObjKey) => 
									getCatData(ybData[pbData][index][pbObjKey], pbObjKey)
								)
							)
						}else{
							getCatData(ybData[pbData])
						}
					}
					return true
				})
			}
		} // eslint-disable-next-line 
	}, [])

	useEffect(()=> {
		Object.keys(appData?.productBrowseData)?.length > 0 && Object.keys(appData.productBrowseData).map((ybObjData) => {
			setEndDate(parseInt(appData.productBrowseData[ybObjData]?.catalogEndDate))
			getCatData(appData.productBrowseData[ybObjData])
			setPromoMessages(appData.productBrowseData[ybObjData].promoMessages);
			setYBData(appData.productBrowseData[ybObjData])
			return true
		})
	},[appData.productBrowseData, getCatData])

	const setYbPromoList = (data) => {
		setYbActivePromo(data)
	}
	const getTitleBar = useCallback(() => {
		PAGETITLE.render(<TitleBar pageContent={YbTitleBar} affiliationData={appData?.affiliationData}/>);
	},[])
	
	const getBreadcrumb = useCallback(() => {
		BREADCRUMB.render(<Breadcrumb pbData={breadcrumbData} />);
	},[breadcrumbData])

	const getChangeSchool = useCallback(() => {
		CHANGESCHOOL.render(<ChangeSchool/>);
	},[])

	const getHeroProducts = useCallback(() => {
		YBHEROBANNER.render(<HeroBannerComponent heroBannerData={heroBannerData} 
					pageContentData={PBHeroProducts} isYearbookPlusCustomer={YbData?.isYearbookPlusCustomer} catalogOsr={YbData?.catalogOsr} ybActivePromo={ybActivePromo} />);
	},[PBHeroProducts, YbData?.isYearbookPlusCustomer, YbData?.catalogOsr, heroBannerData, ybActivePromo])

	const getYbVideo = useCallback(() => {
		HEROVIDEO.render(<VideoHeroBanner videoHeroData={YbPlusVideo} />);
	},[YbPlusVideo])

	const getAdsComp = useCallback(() => {
		ADSBANNER.render(<AdsBannerComponent adsData={adsData} ybAdsPageData = {YbProductBrowseAds} />);
	},[adsData, YbProductBrowseAds])

	const getYbAcc = useCallback(() => {
		YBACCESSORIES && YBACCESSORIES.render(<YbAccessories yBAccData={yBAccData} yBAccPageData = {yBAccPageData} />);
	},[yBAccData, yBAccPageData])

	const getYbAddAcc = useCallback(() => {
		YBADDACCESSORIES.render(<YbAccessories yBAccData={addAccData} yBAccPageData = {addAccPageData} />);
	},[addAccData, addAccPageData])

	const getCatalogMsg = useCallback(() => {
		COPIESMSG.render(<CatalogPromo ybPBCatalogMsg={YbPBCatalogMsg} heroBannerData={heroBannerData} endDate={endDate} setYbPromoList={setYbPromoList}/>);
	}, [YbPBCatalogMsg, heroBannerData, endDate])

	const getMediaCallout = useCallback(() => {
		YBMEDIACALLOUT.render(<MediaCallout pageContentData = {YbMediaCallout} heroBannerData={heroBannerData} isYearbookPlusCustomer={YbData?.isYearbookPlusCustomer} />);
	}, [YbMediaCallout, heroBannerData, YbData?.isYearbookPlusCustomer])


	const handleScroll = () => {
        setLoadGrid(true);
        removeScrollEvent();
    };
    const addScrollEvent = () => {
        window.addEventListener('scroll', handleScroll);
    }
 
    const removeScrollEvent = () => {
        window.removeEventListener('scroll', handleScroll);
    }
 
    useEffect(()=>{
		if(appData.productBrowseData && Object.keys(appData.productBrowseData).length>0){
			gaYearbookPageLoad();
		}
        if(document.body.clientWidth<1980) {
            addScrollEvent();
        } else {
            setLoadGrid(true);
        }
        return () => removeScrollEvent();
	},[])
	
	return (
		<LazyLoadComponent className="container-fluid">			
			{
				YbTitleBar && Object.keys(YbTitleBar)?.length > 0 && getTitleBar()
      		}
			{
				YbTitleBar && getChangeSchool()
			}
			{
				ProductBrowsePromoMsg && promoMessages?.length > 0 && getPromoMsg()
			}
			{
				PBBreadcrumb && getBreadcrumb()
			}
			{
				YbPBCatalogMsg && getCatalogMsg()
			}
			{
				PBHeroProducts && (Object.keys(heroBannerData)?.length > 0) && getHeroProducts()
			}
			{
				YbPlusVideo && YbData?.isYearbookPlusCustomer && getYbVideo()
			}
			{
				YbProductBrowseAds && (adsData?.length > 0) && getAdsComp()
			}
			{
				isMob && YbProductBrowseAds ? 
					loadGrid && PBIndividualProducts && (Object.keys(yBAccData)?.length > 0) && getYbAcc()
					:
					PBIndividualProducts && (Object.keys(yBAccData)?.length > 0) && getYbAcc()
			}
			{
				loadGrid && PBIndividualProducts && (Object.keys(addAccData)?.length > 0) && getYbAddAcc()
			}
			{
				loadGrid && YbMediaCallout && (Object.keys(YbMediaCallout)?.length > 0) && getMediaCallout()
			}
			
		</LazyLoadComponent>
	)
})
