import React, { useEffect, useState, useCallback, useRef } from 'react'
import { m, LazyMotion, domAnimation } from "framer-motion"
import { LazyLoadComponent, LazyLoadImage, trackWindowScroll } from 'react-lazy-load-image-component';
import { hostUrlConverter } from '../../util'

const MediaCallout = React.memo(({pageContentData, heroBannerData, isYearbookPlusCustomer, scrollPosition }) => {

    const [hbProductData, setHBProductData] = useState([]);
    const mediaPageData = useRef({});
    const mediaPageDataFlag = useRef(false);

    useEffect(()=> {
        heroBannerData?.length > 0 && heroBannerData.map((bannerObj)=>
            setHBProductData(prevState => [...prevState, ...bannerObj.products])
        )
    },[heroBannerData])

    const getMediaPageData = useCallback(
        (mediaObj) => {
            mediaPageData.current = {}
            mediaPageDataFlag.current = false

            for(let i=0; i< hbProductData?.length; i++){
                const productOsrs = mediaObj.ctaCheck.productOsr?.split(",");
                for(let j=0; j< productOsrs?.length; j++){
                    if(productOsrs[j] === hbProductData[i].productOsr){
                        if((mediaObj.ctaCheck.isYearbookPlusCustomer === "true" && isYearbookPlusCustomer) || 
                            (mediaObj.ctaCheck.isYearbookPlusCustomer === "false" && isYearbookPlusCustomer === false)){
                            mediaPageData.current = hbProductData[i];
                            mediaPageDataFlag.current = true;
                            break;
                        }
                        // else if(mediaObj.ctaCheck.isYearbookPlusCustomer === "false" && isYearbookPlusCustomer === false){
                        //     mediaPageData.current = hbProductData[i];
                        //     mediaPageDataFlag.current = true;
                        //     break;
                        // }
                    }
                }
            }
            if(mediaPageDataFlag.current === false){
                for(let j=0; j < hbProductData?.length; j++){
                    const productOsrs = mediaObj.ctaCheck.productOsr?.split(",");
                    for(let k=0; k< productOsrs?.length; k++){
                        if(hbProductData[j].productOsr === productOsrs[k]){
                            mediaPageData.current = hbProductData[j];
                            break;
                        }
                    }
                }
            }
        },
    [isYearbookPlusCustomer, hbProductData])

    if (pageContentData) {
        return (
            <LazyMotion features={domAnimation}>
                <LazyLoadComponent scrollPosition={scrollPosition}>
                    <div className="product-categories">
                        <div className="school-banner">
                            <div className="img-wrapper">
                                <LazyLoadComponent>
                                    <picture>
                                        <source media="(min-width: 1024px)" srcSet={pageContentData.desktopImageUrl} />
                                        <LazyLoadImage src={pageContentData.mobileImageUrl} alt={pageContentData.heading+'alt-text'} scrollPosition={scrollPosition} />
                                    </picture>
                                </LazyLoadComponent>
                            </div>
                            <m.div
                                className="overlay"
                                animate={{
                                    opacity: [0, 1],
                                    transition: {
                                        duration: .5
                                    }
                                }}
                            >
                                <div className="ml-2">
                                    <m.h4
                                        animate={{
                                            y: [15, 0],
                                            opacity: [0, 1],
                                            transition: {
                                                delay: .8,
                                                duration: .3,
                                            },
                                        }}
                                    >
                                        {pageContentData.heading}
                                    </m.h4>
                            
                                    <ul>
                                        <m.li
                                            animate={{
                                                y: [15, 0],
                                                opacity: [0, 1],
                                                transition: {
                                                    delay: 1.1,
                                                    duration: .3,
                                                },
                                            }}
                                        >
                                            <div className="fontSize1X py-2">{pageContentData.subheading}</div>
                                            {
                                                pageContentData.ybProductBrowseCallOut?.map((mediaObj, index)=> {
                                                    return (
                                                        <>
                                                            {getMediaPageData(mediaObj)}
                                                            {mediaPageData.current && Object.keys(mediaPageData.current)?.length > 0 && <div className="py-3"><a href={hostUrlConverter(mediaPageData.current.actionUrl)} className={index === 0 ? "hero-banner-button btn" : "callout-button btn"}>{mediaObj.ctaButton}</a></div>}
                                                        </>
                                                    )
                                                    
                                                })
                                            }
                                        </m.li>
                                    </ul>
                                </div>
                            </m.div>
                        </div>
                    </div>
                </LazyLoadComponent>
            </LazyMotion>)
    } else {
        return <></>
    }
})

export default trackWindowScroll(MediaCallout)