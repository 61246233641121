import axios from 'axios';
import { compact } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { LazyLoadComponent, LazyLoadImage, trackWindowScroll } from 'react-lazy-load-image-component';
import { Carousel } from 'react-responsive-carousel';

import { CHIP_LIST_LABELS, ChipList } from '../../components';
import { useGetProductBrowsePromoBanners } from '../../context';
import { actionUrlConverter, dataErrorRedirect, hostUrlConverter, imgUrlConverter } from '../../util';
import { PhotoCardCarousel } from '../hs-grad/photoCardCarousel';
import { PriceComponent } from './price';
import { promoDataConverter } from './utils';


const SeeDetailsComponent = (props) => {
  const [photoCardImages, setPhotoCardImages] = useState([])
  const [showOverlay, setShowOverlay] = useState(true);
  const [modalContent, setModalContent] = useState(false);
  const [overlayData, setOverlayData] = useState({})
  const [paymentPromoInfo, setPaymentPromoInfo] = useState({})
  const [paymentOptionsData, setPaymentOptionsData] = useState("")
  const [productDesc, setProductDesc] = useState("")
  const [showCta, setShowCta] = useState(true);
  const [orderMsg, setOrderMsg] = useState("");
  const [anncMsg, setAnncMsg] = useState();

  const promoBanners = useGetProductBrowsePromoBanners(props.pbId)

  const isLimitedSizeProduct = props.soldOutData && props.soldOutData[props?.productData?.prodRefCode] && props.soldOutData[props?.productData?.prodRefCode]?.featureOsr;
  const isSoldOutProduct = props.soldOutData && props.soldOutData[props?.productData?.prodRefCode] && !props.soldOutData[props?.productData?.prodRefCode]?.featureOsr;
  
  const handleClose = () => {
    setShowOverlay(false);
    props.setSeeDetails(false);
  }

  useEffect(() => {
    let cacheKey = [];
    cacheKey = props?.productData?.cacheKey?.split("-");

    let url = '';
    if(cacheKey?.length > 0){
      url = `${process.env.REACT_APP_CACHE_SERVICE_PATH}/api/cacheService/productdetail/${cacheKey[0]}/${cacheKey[1]}/${cacheKey[2]}`
    }

    url && axios.get(url,
      {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then(result => {
        promoDataConverter(result.data.productData, props.promoCodes, props.fromHsJwl, props.isCustomerInExcludeList)
        setOverlayData(result.data)
        let message = result?.data?.productData?.orderDeliveryInfo?.trim()?.length ? result?.data?.productData?.orderDeliveryInfo?.trim() : result?.data?.productData?.onlineMessage?.trim();
        setOrderMsg(message);
        setModalContent(true)
        if(props.productInfo?.checkCtaKey && result?.data?.productData[props.productInfo?.checkCtaKey] !== undefined){
          setShowCta(result?.data?.productData[props.productInfo?.checkCtaKey])
        }
        if(props.fromHsGrad || props.productInfo?.prodDescDotCms) {
          const template = window.template
          const prodRefCode = props?.productData?.prodRefCode
          const descUrl = `${process.env.REACT_APP_BASE_PATH}/application/content/${props.fromColGradAnnc?"HS-GRAD":template}/${prodRefCode}/long-description`
          axios.get(descUrl,
            {
              headers: {
                "Content-Type": "application/json"
              }
            })
            .then(data => {
              if(data?.data.length > 0)
                setProductDesc(data.data);
              else
                setProductDesc(props.pageContentData?.ctaLongDescription ? props.pageContentData?.ctaLongDescription : result?.data?.productData?.description);
            })
        } else if (props.productInfo?.fromJkt) {
          let jktDescSpec = props.productInfo?.jktDesc || props.pageContentData?.ctaLongDescription
          jktDescSpec += result?.data?.productData?.description ? `<div>${result?.data?.productData?.description}</div>`:''
          jktDescSpec += result?.data?.productData?.specification ? `<div>${result?.data?.productData?.specification}</div>`:''
          if (props?.productData?.prodRefCode === 'JOW705WEB') {
            // description for softshell product
            jktDescSpec = result?.data?.productData?.longDescription ? `<div>${result?.data?.productData?.longDescription}</div>`:''
            jktDescSpec += result?.data?.productData?.specification ? `<div>${result?.data?.productData?.specification}</div>`:''
          }
          setProductDesc(jktDescSpec);
        } else {
          setProductDesc(props.productInfo?.productDesc ? props.productInfo?.productDesc : (props.pageContentData?.ctaLongDescription ? props.pageContentData?.ctaLongDescription : result?.data?.productData?.description ));
        }
        if (props.fromColGradAnnc && props?.productData?.prodRefCode === "QRCANNPLUS") {
          const descUrl = `${process.env.REACT_APP_BASE_PATH}/application/content/COL-GRAD-ANNC/QRCANNPLUS/short-description`
          axios.get(descUrl,
            {
              headers: {
                "Content-Type": "application/json"
              }
            })
            .then(data => {
              if(data?.data.length > 0)
                setAnncMsg(data.data);
            })
        }
      })
      .catch((e)=> dataErrorRedirect(e))

  }, [props?.productData?.cacheKey])

  useEffect(() => {
    if(overlayData?.productData && Object.keys(overlayData.productData)?.length > 0){
      const customerNumber = window.location.pathname?.split("/");
      const url = `${process.env.REACT_APP_BASE_PATH}/apps/checkout/productPaymentPlanDetails.mvc?productType=${overlayData.productData.productType}&categoryName=${overlayData.productData.categoryName}&currentPrice=${overlayData.productData.productMinPrice ? overlayData.productData.productMinPrice : overlayData.productData.retailPrice}&currencyCode=${overlayData.productData.currencyCode}&customerNumber=${customerNumber?.length > 0 ? customerNumber[4] : null}`

      url && axios.get(url,
        {
          headers: {
            "Content-Type": "application/json"
          }
        })
        .then(result => {
          if (result.data && Object.keys(result.data)?.length > 0) {
            let currencyCodeToDisplay = overlayData?.productData?.currencyCode === 'CAD' ? overlayData?.productData?.currencyCode : "";
            setPaymentPromoInfo(result.data)
            setPaymentOptionsData(props.paymentOptions?.displayText?.replace('{totalPrice}', `${currencyCodeToDisplay} $${overlayData.productData?.productMinPrice ? overlayData.productData?.productMinPrice : overlayData.productData?.retailPrice}`)?.replace('{numberOfPayments}', result.data?.numberOfPayments)?.replace('{price}', `${currencyCodeToDisplay} $${result.data.payments?.length > 0 && result.data.payments[0]}`))
          }
        })
    }

  }, [overlayData?.productData, props.paymentOptions])


  const showAddToCart = () => {
    let url = ""
    if(overlayData?.productData?.addToCartUrl?.includes("/-/-")){
      const pathParams = window.location.pathname?.split("/")
      if(pathParams?.length > 0){
        url = hostUrlConverter(overlayData.productData.addToCartUrl.replace("/-/-/", `/${pathParams[4]}/${pathParams[5]}/`))
      }
    } else {
      url = hostUrlConverter(overlayData?.productData?.addToCartUrl)
    }
    let payloadObj = {}
    payloadObj = {
      "publishedCatalogId": props?.productData?.cacheKey?.split("-")?.length > 0 && props.productData.cacheKey.split("-")[1], "publishedProductId": overlayData.productData?.publishedProductId,"configurations":[],
      "displayName": overlayData.productData?.displayName,"productName": overlayData.productData?.displayName,"productOsr": overlayData.productData?.osr,
      "designer": overlayData.productData?.designer }

    if(props.fromHsJwl){
      payloadObj = { ...payloadObj, 
          "catalogOsr": "",
          "sourceCatalogType": "CATALOG_JRD",
          "configurations": [
              {
                  "featureId": "19568411",
                  "featureOsr": "SKU",
                  "featureName": "",
                  "featureDisplayName": "",
                  "optionId": "",
                  "optionOsr": overlayData.productData?.osr,
                  "valueProvider": "CONSUMER",
                  "selected": false,
                  "finalConfiguration": false,
                  "allowedInFinalConfig": false,
                  "specialInstructRequired": false,
                  "customDesign": false,
                  "displayedInCart": false,
                  "configuredInCart": false,
                  "licensingRequired": false,
                  "hidden": true,
                  "productAsConfiguration": false,
                  "id": "",
                  "required": false,
                  "optionImageUrl": "",
                  "subConfigurations": []
              }
          ],
          "productId": overlayData.productData?.publishedProductId,
          "catalogId": props?.productData?.cacheKey?.split("-")?.length > 0 && props.productData.cacheKey.split("-")[1],
          "productType": overlayData.productData?.productType,
      }
    } else if  (props.productInfo?.isColJlry && overlayData?.productData?.configurableFlag === false && overlayData?.productData?.prodRefCode === "JLRY_BOX_CND") {
      payloadObj = {...payloadObj,
        "catalogOsr":"",
        "sourceCatalogType":"CATALOG_JRD",
        "configurations":[
           {
              "featureId":"20872413",
              "featureOsr":"SKU",
              "featureName":"",
              "featureDisplayName":"",
              "optionId":"",
              "optionOsr":overlayData.productData?.osr,
              "valueProvider":"CONSUMER",
              "selected":false,
              "finalConfiguration":false,
              "allowedInFinalConfig":false,
              "specialInstructRequired":false,
              "customDesign":false,
              "displayedInCart":false,
              "configuredInCart":false,
              "licensingRequired":false,
              "hidden":true,
              "productAsConfiguration":false,
              "id":"",
              "required":false,
              "optionImageUrl":"",
              "subConfigurations":[]
           }
        ],
        "productId": overlayData.productData?.publishedProductId,
        "catalogId": props?.productData?.cacheKey?.split("-")?.length > 0 && props.productData.cacheKey.split("-")[1],
        "productType": overlayData.productData?.productType,
        "categoryOsr":"ACCESSORIES",
     }
    } else if(props.productInfo?.isAffiliationStole) {
      payloadObj = {...payloadObj,
        "catalogOsr":"COL_AFFILIATION_STOLE",
        "sourceCatalogType":"CATALOG_SHOP",
        "productId": overlayData.productData?.publishedProductId,
        "catalogId": props?.productData?.cacheKey?.split("-")?.length > 0 && props.productData.cacheKey.split("-")[1],
        "productType": overlayData.productData?.productType,
        "categoryOsr": props?.productData?.groupOsr,
        "schoolYear": null,
        "configurations":[
          {
            "featureId": "13071363",
            "featureOsr": "ORDER SOURCE",
            "featureName": "ORDER SOURCE",
            "featureDisplayName": "Order Source",
            "featureClass": null,
            "optionId": "22880534",
            "optionOsr": "WEB",
            "optionName": "WEB",
            "optionDisplayName": "Web",
            "parentFeatureId": null,
            "parentFeatureOsr": null,
            "parentOptionOsr": null,
            "optionValue": null,
            "defaultOptionOsr": "WEB",
            "defaultOptionValue": null,
            "valueProvider": "CONSUMER",
            "licensingCode": null,
            "explicitChars": null,
            "fieldLimit": null,
            "sortSequence": null,
            "selected": false,
            "finalConfiguration": false,
            "allowedInFinalConfig": false,
            "specialInstructRequired": false,
            "customDesign": false,
            "displayedInCart": false,
            "configuredInCart": false,
            "licensingRequired": false,
            "hidden": true,
            "productAsConfiguration": false,
            "publishedProductId": null,
            "adjustmentPrice": null,
            "subConfigurations": [],
            "selectedOptions": null,
            "id": "13071363",
            "required": true,
            "optionImageUrl": "https://democdn.jostens.com/apps/catalog/catalogImage.mvc?type=college&variant=option_md&option=WEB&feature=ORDER^%^20SOURCE&product=COL_REGALIA_STOLES&jc3b=20211117242&version=2&customerNumber=1041120&imageType=option"
          }
        ]
     }
     payloadObj["productName"] = overlayData.productData?.osr;
    }

    axios({
      method: 'post',
      url: url,
      headers: {
        "Content-Type": "application/json"
      },
      data: payloadObj
    })
    .then((res)=> {
      let redirectUrl = res?.data?.length > 0 ? res.data[0].redirect : Object.keys(res?.data)?.length  > 0 ? res.data.redirect : "";
      if (redirectUrl) {
        window.location.href = redirectUrl;
      }
    })
    .catch((err)=> console.error(err));
  }
  
  const generateActionUrl = (urlData) => {
    if(urlData.includes("/-/-")){
      const pathParams = window.location.pathname?.split("/")
      if(pathParams?.length > 0){
        window.location.href = hostUrlConverter(urlData.replace("/-/-/", `/${pathParams[4]}/${pathParams[5]}/`))
      }
    } else {
        window.location.href = hostUrlConverter(urlData)
    }
  }
  
  const actionUrlGenerator = () => {
    const url = overlayData?.productData?.configurableFlag ? actionUrlConverter(overlayData?.productData?.actionUrl) : hostUrlConverter(overlayData?.productData?.addToCartUrl)
    window.location.href = url;
  }
  
  useEffect(()=> {
    if(props.fromPhotoCard && overlayData?.productData && Object.keys(overlayData.productData)?.length > 0){
      const photoImagesArray = []
      if (overlayData.productData.flavoredOptions?.length > 0) {
        overlayData.productData.flavoredOptions.forEach((optionsObj) => optionsObj.flavoredProductImages?.length > 0 && optionsObj.flavoredProductImages.forEach((imagesObj) => photoImagesArray.push({ "altImageUrl": imagesObj.imageUrl })))
      } else {
        photoImagesArray.push({ "altImageUrl": overlayData.productData.productImageUrl })
      }
      setPhotoCardImages(photoImagesArray)
    }
  }, [overlayData?.productData])

  const shouldChipListDisplay = (props?.productData?.messageWithPromoCode && !Boolean(promoBanners?.length))
    || isLimitedSizeProduct
    || isSoldOutProduct

  const chipsItems = compact([
    props?.productData?.messageWithPromoCode && CHIP_LIST_LABELS.SALE,
    isLimitedSizeProduct && CHIP_LIST_LABELS.LIMITED_SIZE,
    isSoldOutProduct && CHIP_LIST_LABELS.SOLD_OUT
  ])

  return (
    <>
      {modalContent &&
        <Modal size="lg" show={showOverlay} onHide={handleClose}>
          <Modal.Header closeButton>
            {overlayData?.productData?.displayName && <Modal.Title>QUICK VIEW: <span dangerouslySetInnerHTML={{ __html: overlayData?.productData?.displayName }} /></Modal.Title>}
          </Modal.Header>
          <Modal.Body>
            <div className="product-overlay-grid">
              {
                shouldChipListDisplay && !props.isCustomerInExcludeList && <ChipList chipItemsData={chipsItems} chipItemClassName={'ml-3'} />
              }
            {props.fromPhotoCard ? <PhotoCardCarousel productData={{...overlayData.productData}} fromPhotoCard={props.fromPhotoCard} flavoredProductsPageContent={props.flavoredProductsPageContent} altImage={props?.pageContent?.imageUrl}/> :<OverlayCarousel images={props.fromPhotoCard ? photoCardImages : (overlayData?.productData?.altImages?.length > 0 ? overlayData.productData.altImages : overlayData?.productData)} fromPhotoCard={props.fromPhotoCard} altImage={props?.pageContent?.imageUrl}/>}

              <div className="mb-3">
                <span className = { props.fromHsGrad ? "d-flex" :""}>
                {!isSoldOutProduct && overlayData?.productData && (
                  <PriceComponent
                    data={overlayData?.productData}
                    fromHsJwl={props.fromHsJwl}
                    isColJlry={props.isColJlry}
                    isColRegalia={props.isColRegalia}
                    pageContent={props.pageContent}
                    showYbDiscountPrice={props?.showYbDiscountPrice}
                    isDiscountedPriceVisible={!Boolean(promoBanners?.length)}
                  />
                )}
                {!isSoldOutProduct && showCta ? (
                  props.fromProchamp ? 
                    <span className="cursorPointer action-btn" onClick={()=> actionUrlGenerator()}>
                      <button type="button" className="product-overlay-button ml-3 p-1">{overlayData?.productData?.configurableFlag ? "CUSTOMIZE" : "ADD TO CART"}</button>
                    </span> :
                  props.fromHsJwl ?
                    <span>
                      <span
                        className="cursorPointer"
                        onClick={overlayData?.productData?.configurableFlag ? () => generateActionUrl(overlayData?.productData?.actionUrl) : () => showAddToCart()}
                      >
                        <button
                          type="button"
                          className="product-overlay-button ml-3 mr-3 p-1"
                        >
                          {overlayData?.productData?.configurableFlag ? "CUSTOMIZE" : "ADD TO CART"}
                        </button>
                      </span>
                    </span> :
                  props.fromHsGrad ?
                  <span className = "d-flex">
                    <span className="cursorPointer" onClick={overlayData?.productData?.configurableFlag ? () => generateActionUrl(overlayData?.productData?.actionUrl) : () => showAddToCart()}>
                      <button type="button" className="product-overlay-button ml-3 p-1 mr-2">{"BUY NOW"}</button>
                    </span>
                    <b><span className="promo-message-styles">{props?.productData?.promoText }</span></b>
                      </span>
                     :
                  props.fromHeroBanner ? 
                    <a href={hostUrlConverter(overlayData?.productData?.actionUrl)} rel="nofollow">
                      <button type="button" className="product-overlay-button ml-3 px-2 py-1">{props?.pageContentData?.ctaButton}</button>
                    </a> :
                  (overlayData?.productData?.configurableFlag === false) ?
                    <span className="cursorPointer" onClick={() => showAddToCart()}>
                      <button type="button" className="product-overlay-button ml-3 p-1">{overlayData?.productData?.designer === "SINGLE_STEP_DESIGNER" ? props?.pageContentData?.ctaAddToCart || "ADD TO CART" : "ADD TO CART"}</button>
                    </span> :
                    <span className="cursorPointer" onClick={() => generateActionUrl(overlayData?.productData?.actionUrl)}>
                      <button type="button" className="product-overlay-button ml-3 p-1">{props.pageContentData?.ctaCustomize ? props.pageContentData?.ctaCustomize : "CUSTOMIZE"}</button>
                    </span>) : null
                }
                </span>
                {
                  overlayData?.productData?.priceDesc && 
                  <div className="mt-2 product-font-size"><b>{ overlayData?.productData?.priceDesc }</b></div>
                }
                {
                  (productDesc) && ((productDesc+"").trim()!="") &&
                  <div className="product-overlay-text">
                    <h6>Product Description</h6>
                    <p className="product-font-size long-description-scroll" dangerouslySetInnerHTML={{ __html: productDesc }} />
                  </div>
                }
                {
                  (anncMsg) && ((anncMsg+"").trim()!="") &&
                  <div className="product-overlay-text">
                    <p dangerouslySetInnerHTML={{ __html: anncMsg }} />
                  </div>
                }

                {
                  overlayData?.productData?.shippingMsg &&
                  <div className="product-overlay-text">
                    <h6>Shipping Information</h6>
                    <p className="product-font-size" dangerouslySetInnerHTML={{__html: overlayData?.productData?.shippingMsg}} />
                  </div>
                }

                {
                  orderMsg &&
                  <div className="product-overlay-text">
                    <h6>Ordering and Delivery Information</h6>
                    <p className="product-font-size" dangerouslySetInnerHTML={{__html: orderMsg }} />
                  </div>
                }

                {
                  paymentPromoInfo && (paymentPromoInfo.eppAvailable || paymentPromoInfo.epp4Available || paymentPromoInfo.jpayAvailable || paymentPromoInfo.jpay4Available || paymentPromoInfo.jpay12Available) &&
                  <div className="product-overlay-text">
                      {
                        props.fromHsJwl ?
                          <><h6>Payment Options</h6>
                            <p className="product-font-size">Starting at ${overlayData.productData.productMinPrice ? overlayData.productData.productMinPrice : overlayData.productData.retailPrice} or {paymentPromoInfo.numberOfPayments} interest-free payments of
                              ${paymentPromoInfo.payments?.length > 0 && paymentPromoInfo.payments[0]} with a Jostens Payment Plan {(paymentPromoInfo.jpayAvailable || paymentPromoInfo.jpay4Available || paymentPromoInfo.jpay12Available) ? 
                              <LazyLoadComponent>
                                <picture>
                                  <LazyLoadImage className="p-1" height={25} width={25} src="/apps/jcom/s4/src/images/jpay-jostens.png" alt="Jostens" />
                                </picture>
                              </LazyLoadComponent> : (paymentPromoInfo.eppAvailable || paymentPromoInfo.epp4Available) ? "E-Pay" : null}.</p></> :
                           <><h6>{props.paymentOptions?.title}</h6>
                            <p className="product-font-size">{paymentOptionsData}
                            <LazyLoadComponent>
                                <picture>
                                  <LazyLoadImage className="p-1" height={25} width={25} src="/apps/jcom/s4/src/images/jpay-jostens.png" alt="Jostens" />
                                </picture>
                              </LazyLoadComponent>.
                              </p></>
                      }
                  </div>
                }
              
                { overlayData?.productData?.faqUrl &&
                  <div class="product-overlay-text">
                    <h2 class="h5">Questions?</h2>
                    <p>Visit the<a href={overlayData?.productData?.faqUrl}> FAQs</a></p>
                  </div>
                }
              
                {props?.productData?.showMetalOption &&
                  overlayData?.productData?.metalOptions && Object.keys(overlayData.productData.metalOptions).length > 0 &&
                  <div className="product-overlay-text">
                    <h6>Metal Options</h6>
                    <p className="product-font-size">{overlayData.productData.metalOptions.description}
                    </p>
                    <div className="mb-3 metal-options-grid">
                      {
                        overlayData.productData.metalOptions.metals?.length > 0 &&
                        overlayData.productData.metalOptions.metals.map((metalImg, key) =>
                        <div>
                          <LazyLoadComponent>
                            <picture>                          
                                <LazyLoadImage className="p-1" height={60} width={60} key={key} src={imgUrlConverter(metalImg?.imageUrl)} alt={`${metalImg?.metalName}.`} scrollPosition={props.scrollPosition} />
                            </picture>
                          </LazyLoadComponent>
                          <div className="fontSizeXS">{`${metalImg?.metalName}`}</div>
                        </div>
                        )
                      }
                    </div>
                  </div>
                }
                {
                  props.productInfo?.jktOption?.length &&
                  <div className="product-overlay-text">
                    <h6>JACKET OPTIONS</h6>
                    <p className="product-font-size" dangerouslySetInnerHTML={{__html: props.productInfo?.jktOption}} />
                  </div>
                }
              </div>
            </div>
          </Modal.Body>
        </Modal>
      }
    </>
  );
}

const OverlayCarousel = (props) => {
  const arrowStyles = {
    position: 'absolute',
    zIndex: 2,
    top: 'calc(50% - 15px)',
    width: 30,
    height: 30,
    cursor: 'pointer',
  };

  if(Array.isArray(props.images) && props.images?.length > 0){
    return (
      <LazyLoadComponent>
        <Carousel showStatus={false} showThumbs={false} showIndicators={props.fromPhotoCard || props.images?.length < 2 ? false : true} renderArrowPrev={(onClickHandler, hasPrev, label) =>
          hasPrev && (
            <div className="swiper-btn-prev" onClick={onClickHandler} title={label} style={{ ...arrowStyles, left: 15 }}><i className="far fa-arrow-left" role="img"></i></div>
          )
        }
          renderArrowNext={(onClickHandler, hasNext, label) =>
            hasNext && (
              <div className="swiper-btn-next" onClick={onClickHandler} title={label} style={{ ...arrowStyles, right: 15 }}><i className="far fa-arrow-right" role="img"></i></div>
            )
          }>
          {
            props.images?.map((image) => {
              return (
                <LazyLoadComponent>
                  <picture>
                      <source media="(min-width: 1024px)" srcSet={props.fromPhotoCard ? image.altImageUrl : imgUrlConverter(image.altImageUrl)} />
                      <LazyLoadImage 
                        src={props.fromPhotoCard ? image.altImageUrl || props?.altImage : imgUrlConverter(image.altImageUrl || props?.altImage)} 
                        alt={image.altImageDisplayName} 
                        onError={(e) => (props?.altImage && props?.altImage !== e.target.src) ? e.target.src = props?.altImage : false}
                      />
                  </picture>
                </LazyLoadComponent>
              )
            }
            )
          }
        </Carousel>
      </LazyLoadComponent>
    );
  }else{
    return (
      <LazyLoadComponent>
        <picture>
            <source media="(min-width: 1024px)" srcSet={props.fromPhotoCard ? props.images?.productImageUrl : imgUrlConverter(props.images?.productImageUrl)} />
            <LazyLoadImage height={300} width={300} 
              src={props.fromPhotoCard ? props.images?.productImageUrl || props?.altImage : imgUrlConverter(props.images?.productImageUrl || props?.altImage)} 
              alt={props.images?.displayName} 
              onError={(e) => (props?.altImage && props?.altImage !== e.target.src) ? e.target.src = props?.altImage : false}
            />
        </picture>
      </LazyLoadComponent>
    )
  }
}
export default trackWindowScroll(SeeDetailsComponent);