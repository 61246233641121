import React, { useEffect, useState } from 'react'
import { ProductBrowseTiles } from '../home/productBrowseTiles'
import { ProductFilterTile } from '../hs-jwl/productFilterTile'
import { PhotoCardTiles } from './photoCardTiles'

export const HsGradProductBrowse = React.memo((props) => {
    const [filteredPbData, setFilteredPbData] = useState({})
    const [filteredItems, setFilteredQuickData] = useState([])
    const [selectedGroup, setSelectedGroup] = useState({})
    const searchParamsItems = props.fromColGradAnnc?props.groupOsr:new URLSearchParams(decodeURIComponent(window.location.search))?.get('filter');
    const [productData, setProductData] = useState({})
    const [productCount, setProductCount] = useState();

    useEffect(() => {
        if (searchParamsItems && props.filterData && Object.keys(props.filterData)?.length === 0) {
            const currentProductData = Object.keys(filteredPbData)?.length > 0 ? { "products": filteredPbData.products } : { "products": props.pbData?.products?.sort((a, b) => a.orderSeq - b.orderSeq) }
            let searchParamFound = false
            props.pageContent && props.pageContent.productFilters?.length > 0 &&
                props.pageContent.productFilters.forEach((ctaData) => {
                    if (ctaData.filterParam === searchParamsItems) {
                        searchParamFound = true
                        currentProductData.products?.length > 0 && currentProductData.products.forEach((prodData) => {
                            if(ctaData.filterRefValue?.split(",")?.length > 0) {
                                ctaData.filterRefValue?.split(",").forEach((refValue) => {
                                    if (prodData[ctaData.filterRefType] === refValue) {
                                        let searchedProducts = { "products": currentProductData.products?.filter((obj) => obj[ctaData.filterRefType] === refValue) }
                                        setProductData(searchedProducts)
                                        setProductCount(searchedProducts?.products?.length)
                                    }
                                })
                            }
                        })
                    }
                })
            if(!searchParamFound) {
                setProductData({ "products": currentProductData?.products ? [...currentProductData.products] : [] })
            }
        } else {
            const filteredPbDataProducts = filteredPbData?.products ? [...filteredPbData?.products] : []
            const sortedPbDataProducts = props?.pbData?.products?.length
                ? [...props?.pbData?.products?.sort((a, b) => a.orderSeq - b.orderSeq)] 
                : []
            
            setProductData(
                Object.keys(filteredPbData)?.length > 0
                    ? { "products": filteredPbDataProducts } 
                    : { "products": sortedPbDataProducts}
            )
        }
    }, [props.pbData, filteredPbData, searchParamsItems, props.pageContent])

    if (props.pbData) {
        return (
            <>
                <div className='mt-3'>
                    <span className="px-2 h5">{props.pbData?.displayName ? props.pbData?.displayName : "Individual Products"}</span>
                </div>
                <div className='container-fluid mb-4'><div className="pb-3 group-name"></div></div>
                {!props.filterData.message && <ProductFilterTile pbData={props.pbData} filteredPbData={filteredPbData} filterData={props.filterData} setFilteredPbData={setFilteredPbData} selectedGroup={selectedGroup}
                    setFilteredQuickData={setFilteredQuickData} filteredItems={filteredItems} setSelectedGroup={setSelectedGroup} productCount={productCount}/>}
                {(props.flavoredProductsPageContent?.ctaCheck?.groupOsr?.split(",")?.length > 0 && props.flavoredProductsPageContent.ctaCheck.groupOsr.split(",").find(ctaValue => ctaValue === searchParamsItems)) ? <PhotoCardTiles pbData={productData} pageContent={props.pageContent} paymentOptions={props.gradPaymentOptions} flavoredProductsPageContent={props.flavoredProductsPageContent} filteredItems = {filteredItems} fromColGradAnnc={props.fromColGradAnnc} /> : <ProductBrowseTiles pbData={productData} pageContent={props.pageContent} fromHsGrad={props.fromColGradAnnc?false:true} paymentOptions={props.gradPaymentOptions} productInfo={{ "performSort": false }} promoCodes = {props.promoCodes} fromColGradAnnc={props.fromColGradAnnc}/>}
            </>
        )
    } else {
        return null
    }
})
