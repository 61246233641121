import React from 'react';
import { gaCapGownBrowseCustomizeClick } from '../../util/googleAnalytics';

const GradPackageTable = ({packageList, productList, pageContentData}) => {
	
	const leftArrowClick = () => {
		let packageContainerElement = document.getElementById('grad_package-container');
		let currentScrollPosition = packageContainerElement.scrollLeft;
		let scrollToPosiotion = currentScrollPosition - Math.trunc(window.innerWidth / 2.5 - 20);
		packageContainerElement.scroll(scrollToPosiotion,0);
	};

	const rightArrowClick = () => {
		let packageContainerElement = document.getElementById('grad_package-container');
		let currentScrollPosition = packageContainerElement.scrollLeft;
		let scrollToPosiotion = currentScrollPosition + Math.trunc(window.innerWidth / 2.5 - 20);
		packageContainerElement.scroll(scrollToPosiotion,0);
	};

	const scrollEle = () => {
		let packageContainerElement = document.getElementsByClassName(
			'grad_package-container'
		)[0];
		if (packageContainerElement.scrollLeft > 1) {
			document.getElementById('pkg-scroll-left').classList.add('active');
		} else {
			document.getElementById('pkg-scroll-left').classList.remove('active');
		}
		if (
			packageContainerElement.scrollLeft <
			packageContainerElement.scrollWidth -
				packageContainerElement.offsetWidth -
				10
		) {
			document.getElementById('pkg-scroll-right').classList.add('active');
		} else {
			document.getElementById('pkg-scroll-right').classList.remove('active');
		}
	};

	let counter = 1;
	const getDisplayValue = (text, index) => {
		if (text === '1') {
			return (
				<span className='grad_package-item-description' key={index}>
					<i className='fas fa-check'></i>
				</span>
			);
		} else if (text.length < 10) {
			return (
				<span className='grad_package-item-description' key={index}>
					{text}
				</span>
			);
		} else {
			return (
				<div className='grad_package-long-description' key={index}>
					<div className='long-description'>{text}</div>
					<div
						className='long-desc-popup d-none'
						id={'long-desc-popup-' + index}>
						<button
							className='close' title="Close"
							onClick={() => {
								document
									.getElementById('long-desc-popup-' + index)
									.classList.add('d-none');
							}}>
							<i className='fas fa-times'></i>
						</button>
						<div className='long-desc-popup-text'>{text}</div>
					</div>
					<span
						className='show-long-desc-popup'
						onClick={() => {
							console.log("clicked")
							document
								.getElementById('long-desc-popup-' + index)
								.classList.remove('d-none');
						}}>
						<i className='fas fa-info-circle'></i>
					</span>
				</div>
			);
		}
	};

	return (
		<section className='grad-packages-comparison'>
					<div className='grad_packages' id='TRADITIONAL_PACKAGE'>
						<div
							className='grad_package items-list'
							style={{width: `${100 / (productList?.length + 1)}%`}}>
							<div className='grad_package-wrapper'>
								<div className='grad_package-detail'></div>

								<div className='grad_package-info'>
									{packageList?.length > 0 && packageList.map((item, index) => {
										return (
											<li className='grad_package-item' key={index}>
												<span className='grad_package-item-description' title={item.displayName+"."}>
													{item.displayName}
												</span>
												<div className='long-desc-popup d-none' id={'item-name-popup-' + index}>
													<button className='close' title="Close"
														onClick={() => {
															document
																.getElementById('item-name-popup-' + index)
																.classList.add('d-none');
														}}>
														<i className='fas fa-times'></i>
													</button>
													<div className='long-desc-popup-text'>{item.displayName}</div>
												</div>
												{
													item.displayName.length && item.displayName.length >= 20 &&
													<span
														className='show-long-desc-popup d-sm-none'
														onClick={() => {
															document
																.getElementById('item-name-popup-' + index)
																.classList.remove('d-none');
														}}>
														<i className='fas fa-info-circle'></i>
													</span>
												}
											</li>
										);
									})}
								</div>
							</div>
						</div>

						<div
							className='pkg-scroll-btn pkg-scroll-left'
							onClick={() => leftArrowClick()}
							id='pkg-scroll-left'>
							<i className='fal fa-arrow-left'></i>
						</div>

						<div
							className='pkg-scroll-btn pkg-scroll-right active'
							onClick={() => rightArrowClick()}
							id='pkg-scroll-right'>
							<i className='fal fa-arrow-right'></i>
						</div>

						<div
							id='grad_package-container'
							className='grad_package-container'
							style={{
								gridTemplateColumns: `repeat(${productList?.length}, minmax(0, 1fr))`,
							}}
							onScroll={() => scrollEle()}>
							{productList?.length <= 5 &&
								productList.map((product, index) => {
									let parentClass = 'grad_package';
									let childClass = 'grad_package-wrapper';
									if (index === 0) {
									// if (product.productOsr.includes('MASCOT')) {
										parentClass = parentClass + ' mascot-pkg';
										childClass = childClass + ' best-package';
									} else {
										childClass = childClass + ' pkg-color-' + counter++;
									}
									counter = counter > 4 ? 1 : counter;
									// const nameArray = (product.displayName && product.displayName.split(' ')) || [];
									return (
										<div className={parentClass} key={index}>
											<div className={childClass}>
												<div className='grad_package-detail'>
													<div className='grad_package-best-label'>
														{index === 0 && "Most Popular"}
													</div>

													<div className='grad_package-name'>
														<span title={product.displayName+"."}>{product.displayName?.replace("Package", "")}</span>
														<div
															className='long-desc-popup d-none'
															id={'product-name-popup-' + index}>
															<button
																className='close' title="Close"
																onClick={() => {
																	document
																		.getElementById('product-name-popup-' + index)
																		.classList.add('d-none');
																}}>
																<i className='fas fa-times'></i>
															</button>
															<div className='long-desc-popup-text'>{product.displayName}</div>
														</div>
														{
															product.displayName.length && product.displayName.length >= 20 &&
															<span
																className='show-long-desc-popup d-sm-none'
																onClick={() => {
																	document
																		.getElementById('product-name-popup-' + index)
																		.classList.remove('d-none');
																}}>
																<i className='fas fa-info-circle'></i>
															</span>
														}
														<span>{"PACKAGE"}</span>
														{/* {nameArray?.length && nameArray.map((name, key) => <span key={key}>{name}</span>)} */}
													</div>

													<div className='grad_package-price'>
														${product.retailPrice}
													</div>

													<div className='grad_package-btn'>
														<a
															className='btn btn-primary btn-sm'
															id='package_buy-btn'
															onClick={()=>{pageContentData?.title==="Cap and Gown Packages" && gaCapGownBrowseCustomizeClick(product,"PackageGridUpper");return true;}}
															href={product.actionUrl}
															rel="nofollow"
															data-package-osr={product.productOsr}
															data-category-osr=''>
															Customize
														</a>
													</div>

													<div className='grad_package-info'>
														<ul className='grad_package-list'>
															<li className={`grad_package-item ${product.repMessage ? '' : 'no-top-border'}`}>
																{product.repMessage && getDisplayValue(product.repMessage,"i"+index)}
															</li>
														</ul>
													</div>
												</div>
												<div className='grad_package-info'>
													<ul className='grad_package-list'>
														{packageList?.length > 0 && packageList.map(
															(item, key) => (
																<li className='grad_package-item' key={key}>
																	{item.packageProductAvailability.map(
																		(packageItem, indexValue) =>
																			packageItem.packageOsr ===
																				product.productOsr
																				? getDisplayValue(
																					packageItem.display,
																					indexValue
																				)
																				: null
																	)}
																</li>
															)
														)}
													</ul>
												</div>
												<div className='grad_package-btn top-border'>
													<a
														className='btn btn-primary btn-sm'
														id='package_buy-btn'
														onClick={()=>{pageContentData?.title==="Cap and Gown Packages" && gaCapGownBrowseCustomizeClick(product,"PackageGridLower");return true;}}
														href={product.actionUrl}
														rel="nofollow"
														data-package-osr={product.productOsr}
														data-category-osr=''>
														Customize
													</a>
												</div>
											</div>
										</div>
									);
								})}
						</div>
					</div>
				</section>
	);
};

export default GradPackageTable