import React from 'react';

export const PaypalBanner = ({paypalBannerData}) => {
	const load = (d, t) => {
    const paypalBannerMid =  paypalBannerData?.paypalBannerMid;
		let s = d.getElementsByTagName(t)[0],
			n = d.createElement(t);
		n.setAttribute('data-namespace', 'paypal2');
		n.setAttribute('data-pp-account', paypalBannerMid);
		n.src =
			'https://www.paypalobjects.com/upstream/bizcomponents/js/messaging.js';
		n.setAttribute('async','');
		n.id="pp-msg-s";
		!d.getElementById("pp-msg-s") && s.parentNode.insertBefore(n, s);
	};
	window.onload = load(window.document, 'script');

	return (
		<div className='paypal-banner-container'>
			<div className='paypal-wrapper'>
				<div
					data-pp-message
					data-pp-style-layout='text'
					data-pp-style-logo-type='alternative'
					data-pp-style-logo-position='left'></div>
			</div>
		</div>
	);
};
