import { m } from "framer-motion";
import React from 'react';
import { LazyLoadComponent, LazyLoadImage } from 'react-lazy-load-image-component';

export const SlideItem = ({
    alt,
    className,
    handleError,
    handleItemClick,
    imageClassName,
    key,
    media,
    scrollPosition,
    src,
    srcSet,
    title,
    titleClassName,
    variants
}) => (
    <m.div
        {...(key && { key })}
        className={className}
        variants={variants}>
        <div className="py-1 mx-2 group-tile cursorPointer font-weight-bold" onClick={handleItemClick}>
            <LazyLoadComponent>
                <picture>
                    <source media={media} srcSet={srcSet} />
                    <LazyLoadImage className={imageClassName} src={src} alt={alt} scrollPosition={scrollPosition} onError={handleError} />
                </picture>
            </LazyLoadComponent>
            <div className={titleClassName}>
                {title}
                <i className="fas fa-chevron-right fa-xs"></i>
            </div>
        </div>
    </m.div>
)
