import { createRoot } from 'react-dom/client'

export const BREADCRUMB = document.getElementById('breadcrumbs') && createRoot(document.getElementById('breadcrumbs'))
export const PROMOMSG = document.getElementById('promoMsg') && createRoot(document.getElementById('promoMsg'))
export const JWLPAYPALBANNER = document.getElementById('jwlPaypalBanner') && createRoot(document.getElementById('jwlPaypalBanner'))
export const PACKAGESGRID = document.getElementById('packagesGrid') && createRoot(document.getElementById('packagesGrid'));
export const GRADPRODUCTTILES = document.getElementById('gradProductTiles') && createRoot(document.getElementById('gradProductTiles'))
export const HELPBUTTON = document.getElementById('helpButton') && createRoot(document.getElementById('helpButton'))
export const JWLPRODUCTTILES = document.getElementById('jwlProductTiles') && createRoot(document.getElementById('jwlProductTiles'))
export const JWLFILTERGROUPTILES = document.getElementById('jwlFilterGroupTiles') && createRoot(document.getElementById('jwlFilterGroupTiles'))
export const JWLFEATUREDPRODUCTS = document.getElementById('jwlFeaturedProducts') && createRoot(document.getElementById('jwlFeaturedProducts'))
export const YBACCESSORIES = document.getElementById('ybaccessories') && createRoot(document.getElementById('ybaccessories'))
export const YBEXPIREDMSGS = document.getElementById('ybExpiredMsgs') && createRoot(document.getElementById('ybExpiredMsgs'))
export const HEROBANNER = document.getElementById('heroBanner') && createRoot(document.getElementById('heroBanner'))
export const YBHEROBANNER = document.getElementById('herobanner') && createRoot(document.getElementById('herobanner'))
export const HEROVIDEO = document.getElementById('herovideo') && createRoot(document.getElementById('herovideo'))
export const ADSBANNER = document.getElementById('adsbanner') && createRoot(document.getElementById('adsbanner'))
export const YBADDACCESSORIES = document.getElementById('ybaddaccessories') && createRoot(document.getElementById('ybaddaccessories'))
export const COPIESMSG = document.getElementById('copiesmsg') && createRoot(document.getElementById('copiesmsg'))
export const YBMEDIACALLOUT = document.getElementById('ybmediacallout') && createRoot(document.getElementById('ybmediacallout'))
export const PAGETITLE = document.getElementById('pageTitle') && createRoot(document.getElementById('pageTitle'))
export const CHANGESCHOOL = document.getElementById('changeSchool') && createRoot(document.getElementById('changeSchool'))
export const PRODUCTTILES = document.getElementById('productTiles') && createRoot(document.getElementById('productTiles'))
export const PAYPALBANNER = document.getElementById('paypalBanner') && createRoot(document.getElementById('paypalBanner'))
export const PRODUCTFILTERTILE = document.getElementById('productFilterTile') && createRoot(document.getElementById('productFilterTile'))
export const FILTERGROUPTILES = document.getElementById('filterGroupTiles') && createRoot(document.getElementById('filterGroupTiles'))
export const FEATUREDPRODUCTS = document.getElementById('featuredProducts') && createRoot(document.getElementById('featuredProducts'))

