const responsiveDefaults = {
    dots: true,
    arrows: true,
    variableWidth: false
}

export const SLIDER = {
    SETTINGS: {
        accessibility: true,
        slidesToShow: 7,
        slidesToScroll: 7,
        arrows: true,
        dots: true,
        variableWidth: false,
        adaptiveHeight: false,
        infinite: false,
        responsive: [
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    ...responsiveDefaults
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    ...responsiveDefaults
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5,
                    ...responsiveDefaults
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 6,
                    ...responsiveDefaults
                }
            }
        ]
    },

    CONTAINER: {
        show: {
            transition: {
                staggerChildren: 0.2
            }
        }
    },

    PRODUCT: {
        hidden: { opacity: 0 },
        show: {
            opacity: 1,
            transition: {
                duration: 0.25
            }
        }
    }
}