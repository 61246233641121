
export const gaAllSelectionsPageLoad  = () => {
    window && !window.dataLayer && (window.dataLayer = []);
    window.dataLayer.push({
     "event": "allSelectionsPageLoad",
     "pageType": "Cap & Gown Product Browse",
     "schoolType": window?.affiliationData?.customerClassCode,
     "schoolId": window?.affiliationData?.affiliateId,
     "schoolName": window?.affiliationData?.name,
     "packageType": "Cap & Gown"
   });
 };

 export const gaCapGownBrowseCustomizeClick  = (product, sectionOfPage) => {
  window && !window.dataLayer && (window.dataLayer = []);
  window.dataLayer.push({
    "event": "CapGownBrowseCustomizeClick",
    "pageType": "Cap & Gown Product Browse",
    "schoolType": window?.affiliationData?.customerClassCode,
    "schoolId": window?.affiliationData?.affiliateId,
    "schoolName": window?.affiliationData?.name,
    "packageId": product.productOsr,
    "packageOrderStepName":`Customize ${sectionOfPage} Button`,
    "packageType": "Cap & Gown"
  });
};

export const gaPageLoad  = (filterName=null) => {
  let extendedName = getExtendedName()

  window && !window.dataLayer && (window.dataLayer = []);
  let eventObj = {
    "event": filterName?"carouselFilter":"pageLoad",
    "pageType": window?.gaPageType +" Product Browse",
    "schoolType": window?.affiliationData?.customerClassCode,
    "schoolId": window?.affiliationData?.affiliateId,
    "schoolName": extendedName,
  }
  filterName && (eventObj["filterName"] = filterName);
  window.dataLayer.push(eventObj);
};

export const gaYearbookPageLoad  = () => {
  let extendedName = getExtendedName()

  window && !window.dataLayer && (window.dataLayer = []);
  let eventObj = {
    "event": "YBKBrowsePageLoad",
    "pageType": "Product Browse: Yearbook",
    "schoolType": window?.affiliationData?.customerClassCode,
    "schoolId": window?.affiliationData?.affiliateId,
    "schoolName": extendedName,
    "designerType": "Yearbook"
  }
  window.dataLayer.push(eventObj);
};

export const gaYearbookItemClick  = (productSKU, event, configurableFlag=null, section='', productCategory='') => {
  let extendedName = getExtendedName()

  window && !window.dataLayer && (window.dataLayer = []);
  let eventObj = {
    "event": event,
    "pageType": "Product Browse: Yearbook",
    "schoolType": window?.affiliationData?.customerClassCode,
    "schoolId": window?.affiliationData?.affiliateId,
    "schoolName": extendedName,
    "designerType": "Yearbook Accessories",
    "designerProductSku": productSKU
  }
  if (event === 'YBKAccessoriesCustomizeClick') {
    eventObj.designerProductCategory = productCategory
    eventObj.designerSchoolPersonalizationAvailable = (configurableFlag === true)
    eventObj.designerSchoolPersonalizationChoice = `Customize ${section} Button`
  }
  window.dataLayer.push(eventObj);
};

export const selectdropdown  = (subCatlogName) => {
  let extendedName = window?.affiliationData?.name + "(" + window?.affiliationData?.city +"," + window?.affiliationData?.state +")";
  window && !window.dataLayer && (window.dataLayer = []);
  let eventObj = {
    "event": "selectdropdown",
    "pageType": window?.gaPageType +" Product Browse",
    "schoolType": window?.affiliationData?.customerClassCode,
    "schoolId": window?.affiliationData?.affiliateId,
    "schoolName": extendedName,
    "page": "Product Browse",
    "category_name": window?.gaPageType,
    "subcategory_name": subCatlogName
  }
  window.dataLayer.push(eventObj);
};

const getExtendedName = () => {
  let extendedName = "";
  if (window?.affiliationData?.name && window?.affiliationData?.city && window?.affiliationData?.state) {
    extendedName = window?.affiliationData?.name + "(" + window?.affiliationData?.city +"," + window?.affiliationData?.state +")";
  } else if(window?.affiliationData?.name) {
    extendedName = window?.affiliationData?.name
  }
  return extendedName
}