import React, { useEffect, useState } from 'react';
import { ProductBrowseTiles } from '../home/productBrowseTiles';
import { ProductFilterTile } from '../hs-jwl/productFilterTile';

export const AffiliationStoleProductTile = React.memo((props) => {
	const [filteredPbData, setFilteredPbData] = useState({});
	const [filteredItems, setFilteredQuickData] = useState([]);
	const [selectedGroup, setSelectedGroup] = useState({});
	const [productData, setProductData] = useState({"products": []});
	const [subCatProductData, setSubCatProductData] = useState({});
    const [modFilterData, setModFilterData] = useState({})   

	useEffect(() => {
		setSubCatProductData(Object.keys(filteredPbData)?.length > 0 ? { "products": [...filteredPbData?.products] } : { "products": [...productData?.products] })
	}, [productData, filteredPbData]);

	useEffect(() => {
		let sortedKeys = [];
		let finalProducts = [];
		let filtersToShow = [];
		if(props?.serviceData?.productGroups && Object.keys(props?.serviceData?.productGroups)?.length > 0) {
			Object.keys(props?.serviceData?.productGroups)?.forEach((key)=>{
				sortedKeys?.push({"name":key, "sequence":props?.serviceData?.productGroups[key]?.orderSeq});
				filtersToShow.push(props?.serviceData?.productGroups[key]?.groupOsr);
			});
		}	
		sortedKeys?.sort((a,b)=>a?.sequence-b?.sequence).forEach(sortedKeyObj => {
			props.serviceData?.productGroups[sortedKeyObj?.name]?.products?.forEach((o)=>{
				o["groupOsr"] = props.serviceData?.productGroups[sortedKeyObj?.name]?.groupOsr;
			})
			finalProducts = [...finalProducts, ...props.serviceData?.productGroups[sortedKeyObj?.name]?.products];
		})
		let tempFilterObj = {...props.filterData};
		let prods = [...removeDuplicates(finalProducts)]
    if(filtersToShow?.length>0) {
			let finalFilters = props?.filterData?.filterGroups?.length && props?.filterData?.filterGroups[0]?.filters.filter((subFilter) => filtersToShow?.includes(subFilter?.filterOsr));
			if(tempFilterObj?.filterGroups) {
				tempFilterObj?.filterGroups[0]?.filters?.splice(0);
			}	                  
			finalFilters?.forEach(fl => {
				tempFilterObj?.filterGroups[0]?.filters?.push(fl);
			});                
      setModFilterData(tempFilterObj);
		} else if(prods?.length==0) {
				setModFilterData({});
		}
		setProductData({ "products": [...prods] });
	},[props?.serviceData])

	const removeDuplicates = (arr=[]) => {
		const uniqueIds = [];
		!arr && (arr=[]);
		return arr.filter(element => {
			const isDuplicate = uniqueIds.includes(element.productOsr);
			if (!isDuplicate) {
				uniqueIds.push(element.productOsr);
				return true;
			}
			return false;
		});
	}

	if (props?.serviceData) {
		return (
			<>
				<ProductFilterTile
					pbData={productData}
					filteredPbData={filteredPbData}
					filterData={modFilterData}
					setFilteredPbData={setFilteredPbData}
					selectedGroup={selectedGroup}
					setFilteredQuickData={setFilteredQuickData}
					filteredItems={filteredItems}
					setSelectedGroup={setSelectedGroup}
					fieldToCompare={props?.fieldToCompare}
				/>
				<ProductBrowseTiles
					pbData={subCatProductData}
					pageContent={props?.pageContent}
					productInfo={{"prodDescDotCms": false, "performSort": false, "isAffiliationStole": true }}
				/>
			</>
		);
	} else {
		return null;
	}
});
