import { useState } from 'react';
import { Alert } from './alert';

/**
 * return list of alert items
 * @param {array} messages Messages Array of Objects
 * @param {string} isVisible Component Visibility Property
 * @param {function} handleAlertClose Alert Close Event Handler
 */

export const AlertsList = ({ messages }) => {
    const [closedAlerts, setClosedAlerts] = useState([])

    const handleAlertClose = (elementKey) => {
        setClosedAlerts([...closedAlerts, elementKey])
    }

    return !!messages?.length && (
        <div className='announcements'>
            {messages?.map((listItem, index) => {
                const isAlertItemVisible = !closedAlerts.includes(index)

                return (
                    <Alert
                        key={`${listItem.message}${index}`}
                        message={listItem.message}
                        handleAlertClose={handleAlertClose}
                        elementIndex={index}
                        isVisible={isAlertItemVisible}
                    />
                )
            }

            )}
        </div>
    )
}
