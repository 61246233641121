import React, { useEffect, useState } from 'react';
import { ProductBrowseTiles } from '../home/productBrowseTiles';
import { ProductFilterTile } from '../hs-jwl/productFilterTile';

export const RegaliaProductTile = React.memo((props) => {
	const [filteredPbData, setFilteredPbData] = useState({});
	const [filteredItems, setFilteredQuickData] = useState([]);
	const [selectedGroup, setSelectedGroup] = useState({});
	const [productData, setProductData] = useState({"products": []});
	const [subCatProductData, setSubCatProductData] = useState({});

	useEffect(() => {
		setSubCatProductData(Object.keys(filteredPbData)?.length > 0 ? { "products": [...filteredPbData.products] } : { "products": [...productData?.products?.sort((a, b) => a.orderSeq - b.orderSeq)] })
	}, [productData, filteredPbData]);

	useEffect(() => {
		props.serviceData?.productGroups &&
			Object.keys(props.serviceData?.productGroups)?.length > 0 &&
			Object.keys(props.serviceData?.productGroups).forEach(optionKey =>
				setProductData({ "products": props.serviceData?.productGroups[optionKey]?.products })
			)
	},[props.serviceData])

	if (props.serviceData) {
		return (
			<>
				<ProductFilterTile
					pbData={productData}
					filteredPbData={filteredPbData}
					filterData={props.filterData}
					setFilteredPbData={setFilteredPbData}
					selectedGroup={selectedGroup}
					setFilteredQuickData={setFilteredQuickData}
					filteredItems={filteredItems}
					setSelectedGroup={setSelectedGroup}
					fieldToCompare={props.fieldToCompare}
				/>
				<ProductBrowseTiles
					pbData={subCatProductData}
					pageContent={props.pageContent}
					productInfo={{"prodDescDotCms": false, "performSort": false }}
				/>
			</>
		);
	} else {
		return null;
	}
});
