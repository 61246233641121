import { m } from "framer-motion";
import React from 'react';
import Slider from "react-slick";

import { SlideItem } from './SlideItem';

export const ProductSlider = ({
    anchorItem,
    animate,
    className,
    initial,
    itemsList,
    settings,
    variants
}) => {

    const isAnchorItemContainsProps = Boolean(Object.keys(anchorItem).length)
    const slideItems = itemsList.map((currentItem, key) => (
        <SlideItem
            key={`groupObj-${key}`}
            {...currentItem}
        />
    ))

    return (
        <m.div
            className={className}
            variants={variants}
            initial={initial}
            animate={animate}
        >
            <Slider {...settings} className="carousel">
                {anchorItem && isAnchorItemContainsProps && <SlideItem {...anchorItem} />}
                {slideItems}
            </Slider>
        </m.div>
    )
}