import React, { useCallback, useEffect, useState } from 'react';
import { AlertsList } from '../../components/Alerts/alertsList';
import {
    BREADCRUMB,
    GRADPRODUCTTILES,
    HELPBUTTON,
    JWLPAYPALBANNER,
    PACKAGESGRID,
    PAGETITLE,
    PROMOMSG
} from '../../constants/rootElements';
import { filterObjectsByNonEmptyKey } from '../../util/filterObjectsByNonEmptyKey';
import { gaAllSelectionsPageLoad } from '../../util/googleAnalytics';
import { Breadcrumb } from '../breadcrumb';
import { HelpButton } from '../help';
import { PaypalBanner } from '../paypalBanner';
import { TitleBar } from "../productBrowseTitle";
import GradPackageTabs from './gradPackageTabs';
import { HsGradProductBrowse } from './gradProductBrowse';

export const HSGradPage = ({ appData }) => {
    const [ pbData, setPbData ] = useState({})
    const [breadcrumbData, setBreadcrupmData] = useState([])
    const [promoMessages, setPromoMessages] = useState([]);
    
    
    const gradTitleBar = appData?.pagecontentData?.find(data => data.contentType === "ProductBrowseTitle")
    const gradBreadcrumb = Boolean(appData?.pagecontentData?.find(data => data.contentType === "Navigation"))
    const ProductBrowsePromoMsg = Boolean(appData?.pagecontentData?.find(data => data.contentType === "ProductBrowsePromoMsg"))
    const ProductBrowseProducts = appData?.pagecontentData?.find(data => data.contentType === "ProductBrowseProducts")
    const GradPaymentOptions = appData?.pagecontentData?.find(data => data.contentType === "PaymentOptions")
    const PaypalBannerData = appData?.pagecontentData?.find(data => data.contentType === "PaypalId")
    const ProductBrowseFlavoredProducts = appData?.pagecontentData?.find(data => data.contentType === "ProductBrowseFlavoredProducts")
    const ProductBrowsePackages = appData?.pagecontentData?.find(data => data.contentType === "Packages")
    const HsPaymentPromo = appData?.pagecontentData?.find(data => data.contentType === "PaymentPromo")

    const nonEmptyPromoMessages = filterObjectsByNonEmptyKey(promoMessages, 'message')

	const getBreadcrumb = useCallback(() => {
		BREADCRUMB.render(<Breadcrumb pbData={breadcrumbData} />);
  },[breadcrumbData])

    const getPromoMsg = useCallback(() => {
        !!nonEmptyPromoMessages?.length && PROMOMSG.render(
            <AlertsList messages={nonEmptyPromoMessages} />
        );
    }, [promoMessages])

  const paypalBanner = useCallback(() => {
       JWLPAYPALBANNER.render(<PaypalBanner paypalBannerData={PaypalBannerData} />);
   },[PaypalBannerData])

    const getPackagesGrid = useCallback(() => {
        const productsData = { ...pbData }
        if (productsData && Object.keys(productsData)?.length > 0 && productsData.groupOsr === ProductBrowsePackages?.ctaCheck?.groupOsr) {
            PACKAGESGRID.render(<GradPackageTabs packageList={productsData?.packageItems?.filter(obj => obj.packageProductAvailability?.length > 0)} productList={[...productsData?.products?.filter(obj => obj.productType === ProductBrowsePackages?.ctaCheck?.productType)]} pageContentData={ProductBrowsePackages} hsPaymentPromo={HsPaymentPromo} altImage = {ProductBrowseProducts?.imageUrl}/>);
        }
        
    }, [pbData, ProductBrowsePackages, HsPaymentPromo])

  const productTiles = useCallback(() => {
      const productsData = { ...pbData }
      if (productsData && Object.keys(productsData)?.length > 0 && productsData.groupOsr === "REGALIA") {
          productsData.products = productsData.products.filter((obj => obj.productType !== "PACKAGE"))
      }
      GRADPRODUCTTILES.render(<HsGradProductBrowse pbData={productsData} pageContent={ProductBrowseProducts} filterData={appData.filterData} promoCodes = {appData.PromoCodesData}
        gradPaymentOptions={GradPaymentOptions} flavoredProductsPageContent={ProductBrowseFlavoredProducts} />);
    // eslint-disable-next-line
  },[pbData, ProductBrowseProducts, appData.filterData, GradPaymentOptions, ProductBrowseFlavoredProducts])

  const getHelpButton = useCallback(() => {
        HELPBUTTON.render(<HelpButton />);
  },[])

  const getTitleBar = useCallback(() => {
    PAGETITLE.render(<TitleBar pageContent={gradTitleBar} affiliationData={appData?.affiliationData}/>);
  },[])
	
	useEffect(()=> {
		Object.keys(appData.productBrowseData).map((key) => {
            if(key !== "metaData"){
                appData.productBrowseData[key] && Object.keys(appData.productBrowseData[key])?.length > 0 && 
                    setPbData(appData.productBrowseData[key])
                    setBreadcrupmData(appData.productBrowseData[key])
                    setPromoMessages(appData.productBrowseData[key]?.promoMessagesV2)
            }
            return true
        })
    },[appData.productBrowseData])
    
    useEffect(()=>{
        if(appData.productBrowseData && Object.keys(appData.productBrowseData).length>0){
            let key = Object.keys(appData.productBrowseData)[0];
            appData.productBrowseData[key].displayName==="Cap & Gown" && gaAllSelectionsPageLoad();
        }
    },[])

	return (
		<>
        {
            gradTitleBar && Object.keys(gradTitleBar)?.length > 0 && getTitleBar()
        }
	    {
            gradBreadcrumb && getBreadcrumb() 
        }
        {
            ProductBrowsePromoMsg && promoMessages?.length > 0 && getPromoMsg()
        }
        {
            ProductBrowsePackages && Object.keys(ProductBrowsePackages)?.length > 0 && getPackagesGrid()
        }
        {
            ProductBrowseProducts && Object.keys(ProductBrowseProducts)?.length > 0 && productTiles()
        }
        {
            getHelpButton()
        }
        {
            PaypalBannerData && Object.keys(PaypalBannerData)?.length > 0 && paypalBanner()
        }
			
		</>
	)
}
