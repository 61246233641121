import React, { useEffect, useState, useCallback } from 'react'
import { Breadcrumb } from '../breadcrumb'
import { ProductBrowseTiles } from "../home/productBrowseTiles"
import { PaypalBanner } from '../paypalBanner'
import { TitleBar } from "../productBrowseTitle"
import { HelpButton } from '../help'
import { BREADCRUMB, PAYPALBANNER, PRODUCTTILES, HELPBUTTON, PAGETITLE } from '../../constants/rootElements'

export const FRBPage = props => {
    const { appData } = props
    const [pbData, setPbData] = useState({})
    const [breadcrumbData, setBreadcrupmData] = useState([])

    const TitleBarPC = appData?.pagecontentData?.find(data => data.contentType === "ProductBrowseTitle")
    const BreadcrumbPC = Boolean(appData?.pagecontentData?.find(data => data.contentType === "Navigation"))
    const ProductBrowseProducts = appData?.pagecontentData?.find(data => data.contentType === "ProductBrowseProducts")
    const PaypalBannerData = appData?.pagecontentData?.find(data => data.contentType === "PaypalId")
    const PaymentOptions = appData?.pagecontentData?.find(data => data.contentType === "PaymentOptions")

    const getBreadcrumb = useCallback(() => {
        BREADCRUMB.render(<Breadcrumb pbData={breadcrumbData} />);
    }, [breadcrumbData])

    const productTiles = useCallback(() => {
        PRODUCTTILES.render(<ProductBrowseTiles pbData={pbData} pageContent={ProductBrowseProducts} paymentOptions={PaymentOptions}/>);
        // eslint-disable-next-line
    }, [pbData,PaymentOptions,ProductBrowseProducts])

    const paypalBanner = useCallback(() => {
       PAYPALBANNER.render(<PaypalBanner paypalBannerData={PaypalBannerData} />);
    }, [PaypalBannerData])

    const getHelpButton = useCallback(() => {
        HELPBUTTON.render(<HelpButton />);
    }, [])

    const getTitleBar = useCallback(() => {
        PAGETITLE.render(<TitleBar pageContent={TitleBarPC} affiliationData={appData?.affiliationData}/>);
      },[])

    useEffect(() => {
        appData.productBrowseData && Object.keys(appData.productBrowseData).map((key) => {
            if (key !== "metaData") {
                appData.productBrowseData[key]?.productGroups && Object.keys(appData.productBrowseData[key]?.productGroups).map((catObj) => {
                    setPbData(appData.productBrowseData[key].productGroups[catObj])
                    setBreadcrupmData(appData.productBrowseData[key].productGroups[catObj])
                    return true
                })
            }
            return true
        })
    }, [appData.productBrowseData])

    return (
        <>
            {
                TitleBarPC && Object.keys(TitleBarPC)?.length > 0 && getTitleBar()
            }
            {
                BreadcrumbPC && getBreadcrumb()
            }
            {
                ProductBrowseProducts && Object.keys(ProductBrowseProducts)?.length > 0 && productTiles()
            }
            {
                getHelpButton()
            }
            {
                PaypalBannerData && Object.keys(PaypalBannerData)?.length > 0 && paypalBanner()
            }

        </>
    )
}
