import React, { createContext } from 'react';
import { AppData } from '../../data/index'

const initialState = AppData.productBrowseData;

export const Context = createContext(initialState);

export const ProductBrowseProvider = ({ children }) => {
    return (
        <Context.Provider value={initialState}>
            {children}
        </Context.Provider>
    );
};