import React, { useState, useCallback, useEffect } from 'react';
import YbAccessories from './ybAccessories';
import { YBACCESSORIES } from '../../constants/rootElements'

export const ExpiredComingSoonAccessories = React.memo((props) => {
	const { appData, ybAccPageData } = props;
	const [yBAccData, setYBAccData] = useState({});

	const getSubCatData = (ybCatData, catalogOsr) => {
		Object.keys(ybCatData).map((catalogData) => {
			if (
				ybCatData[catalogData] !== null &&
				typeof ybCatData[catalogData] === 'object'
			) {
				if (ybAccPageData?.ctaCheck?.catalogOsr.indexOf(catalogOsr) !== -1) {
					let groupOsrs = ybAccPageData.ctaCheck?.groupOsr;
					if(ybAccPageData.ctaCheck?.groupOsr?.indexOf(',') !== -1) {
						groupOsrs = ybAccPageData.ctaCheck?.groupOsr?.split(',');
					} else if(ybAccPageData.ctaCheck?.groupOsr?.indexOf('&#44;') !== -1) {
						groupOsrs = ybAccPageData.ctaCheck?.groupOsr?.split('&#44;');
					}
					groupOsrs?.length > 0 &&
						groupOsrs.map((groupOsrValue) => {
							if (groupOsrValue === ybCatData[catalogData]['groupOsr']) {
								setYBAccData(ybCatData[catalogData]);
							}
							return true;
						});
				}
			}
			return true;
		});
	};

	const getCatData = useCallback((ybData, pbObjKey) => {
		if (ybData !== undefined) {
			Object.keys(ybData)?.length > 0 &&
				Object.keys(ybData).map((pbData) => {
					if (ybData[pbData] !== null && typeof ybData[pbData] === 'object') {
						if (pbData === 'productGroups') {
							const catalogOsrKey = ybData.catalogOsr ? ybData.catalogOsr : pbObjKey ? (pbObjKey.split("-") && pbObjKey.split("-")?.length > 0 && pbObjKey.split("-")[1]) : ""
							getSubCatData(
								ybData[pbData],
								catalogOsrKey
							);
						} else if (pbData === 'siteMapRefKeys') {
							ybData[pbData]?.length > 0 &&
								ybData[pbData].map(
									(pbObj, index) =>
										Object.keys(pbObj)?.length > 0 &&
										Object.keys(pbObj)?.map((pbObjKey) =>
											getCatData(ybData[pbData][index][pbObjKey], pbObjKey)
										)
								);
						} else {
							getCatData(ybData[pbData]);
						}
					}
					return true;
				});
		} // eslint-disable-next-line
	}, []);

	useEffect(() => {
		Object.keys(appData?.productBrowseData)?.length > 0 &&
			Object.keys(appData.productBrowseData).map(
				(ybObjData) => ybObjData && getCatData(appData.productBrowseData[ybObjData])
			);
	}, [appData.productBrowseData, getCatData]);

	const getYbAcc = useCallback(() => {
		YBACCESSORIES && YBACCESSORIES.render(
			<YbAccessories yBAccData={yBAccData} yBAccPageData={ybAccPageData} />);
	}, [yBAccData, ybAccPageData]);

	return (
		<>
			{Object.keys(yBAccData)?.length > 0 &&
				Object.keys(ybAccPageData)?.length > 0 &&
				getYbAcc()}
		</>
	);
});
