import React, { useState, useCallback } from 'react'
import { m, LazyMotion, domAnimation } from "framer-motion"
import { LazyLoadComponent, LazyLoadImage, trackWindowScroll } from 'react-lazy-load-image-component';
import { imgUrlConverter, hostUrlConverter } from '../../util'
import { PriceComponent } from '../home/price'
import { Carousel } from 'react-bootstrap'
import SeeDetailsComponent from '../home/seeDetails'
import axios from 'axios'
import { gaYearbookItemClick } from '../../util/googleAnalytics';

//const HeroBannerComponent = React.memo(({ heroBannerData, pageContentData, isYearbookPlusCustomer, catalogOsr, scrollPosition }) => {
const YbAccessories = React.memo(({ yBAccData, yBAccPageData, scrollPosition }) => {
    const [seeDetails, setSeeDetails] = useState(false)
    const [productData, setProductData] = useState({})

    const viewDetails = useCallback(
        (data) => {
          setSeeDetails(true)
          setProductData(data)
          gaYearbookItemClick(data?.productOsr, "YBKAccessoriesSeeDatailsClick")
        },
    [])

    const nextIcon = () => {
        return <div className="swiper-btn-next"><i className="far fa-arrow-right"></i></div>
    }
    const prevIcon = () => {
        return <div className="swiper-btn-prev"><i className="far fa-arrow-left"></i></div>
    }

    const container = {
        show: {
            transition: {
                staggerChildren: 0.2
            }
        }
    }

    const product = {
        hidden: { opacity: 0 },
        show: {
            opacity: 1,
            transition: {
                duration: 0.25
            }
        }
    }

    const showAddToCart = (productObj) => {
        axios({
            method: 'post',
            url: hostUrlConverter(productObj?.addToCartUrl),
            headers: {
                "Content-Type": "application/json"
            },
            data: {
                "publishedCatalogId": productObj?.cacheKey?.split("-")?.length > 0 && productObj.cacheKey.split("-")[1], "publishedProductId": productObj?.publishedCatalogProductId, "configurations": [],
                "displayName": productObj?.displayName, "productName": productObj?.displayName, "productOsr": productObj?.productOsr,
                "designer": productObj?.designer
            }
        })
            .then((res) => {
                if (res?.data?.length > 0 && res.data[0].redirect) {
                    window.location.href = res.data[0].redirect
                }
            })
            .catch((err) => console.error(err));
    }

    if (yBAccData && Object.keys(yBAccData)?.length > 0) {
        return (
            <LazyMotion features={domAnimation}>
                <LazyLoadComponent scrollPosition={scrollPosition}>
                <section className="yb-products-catalog container-padding container-fluid">
                    <div className="yb-group-wrapper">
                        {yBAccData?.products?.length > 0 ? <h4 className="pb-3 group-name">{yBAccPageData?.heading}</h4>:null}
                        <m.div className="catalog-wrapper" 
                            variants={container}
                            initial="hidden"
                            animate="show">
                            {yBAccData?.products?.map((productObj, key) => {
                                return (
                                    <m.div className="content-item" key={key} variants={product} title={productObj?.displayName}>
                                            <div className="item-image-wrapper cursorPointer" onClick={()=>{
                                                    gaYearbookItemClick(productObj?.productOsr, "YBKAccessoriesProductClick")
                                                    window.location.assign(hostUrlConverter(productObj?.actionUrl))
                                                }}>
                                                <LazyLoadComponent>
                                                <picture>
                                                    <source media="(min-width: 1024px)" srcSet={imgUrlConverter(productObj.imageUrl, yBAccPageData?.ctaCheck?.desktopImageVariant)} />
                                                    <LazyLoadImage height="100%" width="100%" src={imgUrlConverter(productObj.imageUrl, yBAccPageData?.ctaCheck?.mobileImageVariant)} alt={productObj?.displayName} scrollPosition={scrollPosition} />
                                                </picture>
                                                </LazyLoadComponent>
                                                
                                             {/*   <img loading="lazy" src={imgUrlConverter(productObj.imageUrl, yBAccPageData?.ctaCheck?.mobileImageVariant)} alt={productObj?.displayName} scrollPosition={scrollPosition} />
                                                   <LazyLoadImage src={imgUrlConverter(productObj.imageUrl, yBAccPageData?.ctaCheck?.desktopImageVariant)} alt={productObj?.displayName} scrollPosition={scrollPosition} />

                                              */}  
                                            </div>
                                            {productObj?.altImages?.length <= 1 &&
                                            <div className="carousel-caption">
                                                <button className="product-button-btn" onClick={()=>viewDetails(productObj)}> SEE DETAILS </button>
                                            </div>}
                                        {
                                            productObj?.altImages?.length > 1 &&
                                                <LazyLoadComponent><Carousel key={productObj.displayName+key} className="product-overlay" nextIcon={nextIcon()} prevIcon={prevIcon()} indicators={false} indicatorlabels={[]} interval={null}>
                                                    {
                                                    productObj?.altImages?.map((altImage) =>
                                                        <Carousel.Item key={altImage?.altImageDisplayName+key}>
                                                            <div className="carousel-img-wrapper cursorPointer" onClick={()=>{
                                                                gaYearbookItemClick(productObj?.productOsr, "YBKAccessoriesProductClick")
                                                                window.location.assign(hostUrlConverter(productObj?.actionUrl))
                                                            }}>
                                                               {/* <img loading="lazy" src={imgUrlConverter(altImage?.altImageUrl, yBAccPageData?.ctaCheck?.mobileImageVariant)} alt={altImage?.altImageDisplayName} />
                                                                <LazyLoadImage src={imgUrlConverter(altImage.imageUrl, yBAccPageData?.ctaCheck?.mobileImageVariant)} alt={altImage?.displayName} scrollPosition={scrollPosition} />
                                                            */}
                                                            <LazyLoadComponent scrollPosition={scrollPosition}>
                                                            <picture>
                                                                <source media="(min-width: 1024px)" srcSet={imgUrlConverter(productObj.imageUrl, yBAccPageData?.ctaCheck?.desktopImageVariant)} />
                                                                <LazyLoadImage src={imgUrlConverter(productObj.imageUrl, yBAccPageData?.ctaCheck?.mobileImageVariant)} alt={productObj?.displayName} scrollPosition={scrollPosition} />
                                                            </picture>
                                                            </LazyLoadComponent>
                                                            </div>
                                                            <div className="carousel-caption">
                                                                <button className="product-button-btn" onClick={()=>viewDetails(productObj)}> SEE DETAILS </button>
                                                            </div>
                                                        </Carousel.Item>
                                                    )
                                                    }
                                                </Carousel>
                                                </LazyLoadComponent>
                                        }
                                        {productObj?.productTag && <div className="sale-label">{productObj.productTag}</div>}
                                        <div className="item-text-wrapper">
                                            <div className="yb-product-name yb-product-area">
                                                <a href={hostUrlConverter(productObj?.actionUrl)} onClick={() => gaYearbookItemClick(productObj?.productOsr, "YBKAccessoriesProductClick")}>
                                                    <h6>{productObj.displayName}</h6>
                                                </a>
                                            </div>
                                            {
                                               productObj?.description && productObj?.description?.length && <div className="fontSizeXS yb-product-area yb-product-desc" title={productObj.description+'.'} dangerouslySetInnerHTML={{__html: productObj.description}} />
                                            }
                                            <div className="my-2 fontSizeSM"><PriceComponent data={productObj} /></div>
                                            {
                                                productObj.configurableFlag ? 
                                                    <button type="button" className="carousel-action-button" onClick={() => {
                                                        gaYearbookItemClick(productObj?.productOsr, "YBKAccessoriesCustomizeClick", productObj?.configurableFlag, yBAccPageData?.heading, yBAccData?.groupOsr);
                                                        window.location.assign(hostUrlConverter(productObj?.actionUrl));
                                                    }}>{yBAccPageData?.ctaCustomize}</button>
                                                : 
                                                <div className="cursorPointer" onClick={() => showAddToCart(productObj)}>
                                                    <button type="button" className="carousel-action-button">{yBAccPageData?.ctaAddToCart}</button>
                                                </div>
                                            }
                                            
                                        </div>
                                    </m.div>
                                )}
                            )}

                        </m.div>
                    </div>

                </section>
                {
                    seeDetails && <SeeDetailsComponent setSeeDetails={setSeeDetails} productData={productData} pageContentData={yBAccPageData} />
                }
                </LazyLoadComponent>
            </LazyMotion>
        )
    } else {
        return null
    }
})

export default trackWindowScroll(YbAccessories)