import { useState, useEffect } from "react"

export const useGetExcludeListDataByCustomerId = (catalogExcludeList, catalogOSR, customerId) => {
    const [isCustomerInCatalogExcludeList, setIsCustomerInCatalogExcludeList] = useState(false)
    const [currentCatalogExcludeList, setCurrentCatalogExcludeList] = useState([])

    useEffect(() => {
        const catalogItem = catalogExcludeList?.find(item => item.catalogOSR === catalogOSR)
        const catalogItemExcludeList = catalogItem?.customerNumber?.split(',')

        catalogItemExcludeList && setCurrentCatalogExcludeList(catalogItemExcludeList)
        catalogItemExcludeList?.includes(customerId) && setIsCustomerInCatalogExcludeList(true)
    }, [catalogExcludeList, catalogOSR, customerId])

    return {
        currentCatalogExcludeList,
        isCustomerInCatalogExcludeList
    }
}