import React from 'react'
import { hostUrlConverter } from '../../util/index'

export const VideoHeroBanner = React.memo(({ videoHeroData }) => {

    if(videoHeroData && videoHeroData.videoId) {
        return (
            <div className="media-callout">
                <div className="media">
                    <div className="video">
                        {
                            videoHeroData.videoProvider === "youtube" &&
                            <iframe 
                                id="video-qKsvLKKoY2E" 
                                frameBorder="0" 
                                allowFullScreen="1" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                title={videoHeroData.title} 
                                src={`https://www.youtube.com/embed/${videoHeroData.videoId}`}
                            />
                        }
                        {
                            videoHeroData.videoProvider === "vimeo" &&
                            <iframe 
                                id="video-qKsvLKKoY2E" 
                                frameBorder="0" 
                                webkitallowfullscreen
                                mozallowfullscreen
                                allowfullscreen  
                                title={videoHeroData.title} 
                                src={`https://player.vimeo.com/video/${videoHeroData.videoId}`}
                            />
                        }
                    </div>
                </div>
                <div className="overlay dark">
                    <div className="inner">
                        <h2>{videoHeroData.title}</h2>
                        <div className="content fontSizeXS" dangerouslySetInnerHTML={{__html: videoHeroData.content}} />
                        { videoHeroData.button && videoHeroData.button.link && videoHeroData.button.text && <div><a href={hostUrlConverter(videoHeroData.button.link)} target="_blank" rel="noopener noreferrer" className="btn btn-white btn-white--ghost">{videoHeroData.button.text}</a></div>}
                    </div>
                </div>
            </div>
        )
    } else return null
})