import React, { useCallback, useEffect, useState } from 'react';
import { AlertsList } from '../../components/Alerts/alertsList';
import {
    BREADCRUMB,
    GRADPRODUCTTILES,
    HELPBUTTON,
    JWLPAYPALBANNER,
    PAGETITLE,
    PROMOMSG
} from '../../constants/rootElements';
import { gaPageLoad } from '../../util/googleAnalytics';
import { Breadcrumb } from '../breadcrumb';
import { HelpButton } from '../help';
import { HsGradProductBrowse } from '../hs-grad/gradProductBrowse';
import { PaypalBanner } from '../paypalBanner';
import { TitleBar } from "../productBrowseTitle";

export const ColGradAnncPage = ({ appData }) => {
    const [pbData, setPbData] = useState({})
    const [breadcrumbData, setBreadcrupmData] = useState([])
    const [groupOsr, setGroupOsr] = useState(null)
    const [promoMessages, setPromoMessages] = useState([]);


    const gradTitleBar = appData?.pagecontentData?.find(data => data.contentType === "ProductBrowseTitle")
    const gradBreadcrumb = Boolean(appData?.pagecontentData?.find(data => data.contentType === "Navigation"))
    const ProductBrowseProducts = appData?.pagecontentData?.find(data => data.contentType === "ProductBrowseProducts")
    const PaypalBannerData = appData?.pagecontentData?.find(data => data.contentType === "PaypalId")
    const ProductBrowseFlavoredProducts = appData?.pagecontentData?.find(data => data.contentType === "ProductBrowseFlavoredProducts")
    const ProductBrowsePromoMsg = Boolean(
        appData?.pagecontentData?.find(
            (data) => data.contentType === 'ProductBrowsePromoMsg'
        )
    );

    const getBreadcrumb = useCallback(() => {
        BREADCRUMB.render(<Breadcrumb pbData={breadcrumbData} />);
    }, [breadcrumbData])

	const getPromoMsg = useCallback(() => {
		PROMOMSG.render(<AlertsList messages={promoMessages} />);
	}, [promoMessages]);

    const paypalBanner = useCallback(() => {
        JWLPAYPALBANNER.render(<PaypalBanner paypalBannerData={PaypalBannerData} />);
    }, [PaypalBannerData])

    const productTiles = useCallback(() => {
        const productsData = { ...pbData }
        GRADPRODUCTTILES.render(<HsGradProductBrowse pbData={productsData} pageContent={ProductBrowseProducts} filterData={appData.filterData}
            flavoredProductsPageContent={ProductBrowseFlavoredProducts} fromColGradAnnc={true} groupOsr={groupOsr} />);
    }, [pbData, ProductBrowseProducts, appData.filterData, ProductBrowseFlavoredProducts])

    const getHelpButton = useCallback(() => {
        HELPBUTTON.render(<HelpButton />);
    }, [])

    const getTitleBar = useCallback(() => {
        PAGETITLE.render(<TitleBar pageContent={gradTitleBar} affiliationData={appData?.affiliationData} />);
    }, [])

    useEffect(() => {
        if (appData.productBrowseData && Object.keys(appData.productBrowseData).length > 0) {
            gaPageLoad();
        }
    }, [])

    useEffect(() => {
        Object.keys(appData.productBrowseData).map((key) => {
            if (key !== "metaData") {
                appData.productBrowseData[key] && Object.keys(appData.productBrowseData[key])?.length > 0 &&
                    setPbData(appData.productBrowseData[key])
                setBreadcrupmData(appData.productBrowseData[key])
                setPromoMessages(appData.productBrowseData[key].promoMessages);
                setGroupOsr(appData?.productBrowseData[key]?.groupOsr);
            }
            return true
        })
    }, [appData.productBrowseData])

    return (
        <>
            {gradTitleBar && Object.keys(gradTitleBar)?.length > 0 && getTitleBar()}
            {ProductBrowsePromoMsg && promoMessages?.length > 0 && getPromoMsg()}
            {gradBreadcrumb && getBreadcrumb()}
            {ProductBrowseProducts && Object.keys(ProductBrowseProducts)?.length > 0 && productTiles()}
            {getHelpButton()}
            {PaypalBannerData && Object.keys(PaypalBannerData)?.length > 0 && paypalBanner()}
        </>
    )
}
