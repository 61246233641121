import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom"
import * as Routes from '../constants/routes'
import { HomePage } from '../pages/home';
import { YearbookPage } from '../pages/yearbook';
import { HSJWLPage } from '../pages/hs-jwl';
import { UplomaPage } from "../pages/uploma";
import { HSGradPage } from '../pages/hs-grad';
import { HSJacketsPage } from '../pages/hs-jackets';
import { ColRegaliaPage } from "../pages/col-regalia";
import { AffiliationStolePage } from "../pages/affiliation-stole";
import { FRBPage } from '../pages/frb';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { AppData } from '../data'
import { DiplomaPage } from "../pages/diploma";
import { ColJWLPage } from "../pages/col_jlry";
import { RegaliaPage } from "../pages/regalia";
import { ColGradAnncPage } from '../pages/col-grad-annc';

const App = () => {

  const getPageByParam = (option) => {
    const pages = {
      "PRO-CHAMP": <LazyLoadComponent><HomePage appData={AppData} /></LazyLoadComponent>,
      "YB-1": <LazyLoadComponent><YearbookPage appData={AppData} /></LazyLoadComponent>,
      "YB-CROSSSELL": <LazyLoadComponent><YearbookPage appData={AppData} /></LazyLoadComponent>,
      "YB-EXPIRED": <LazyLoadComponent><YearbookPage appData={AppData} /></LazyLoadComponent>,
      "YB-COMINGSOON": <LazyLoadComponent><YearbookPage appData={AppData} /></LazyLoadComponent>,
      "YB_AD_EXPIRED": <LazyLoadComponent><YearbookPage appData={AppData} /></LazyLoadComponent>,
      "HS-JLRY": <HSJWLPage appData={AppData} />,
      "HS-GRAD": <LazyLoadComponent><HSGradPage appData={AppData} /></LazyLoadComponent>,
      "UPLOMA":  <LazyLoadComponent><UplomaPage appData={AppData} /></LazyLoadComponent>,
      "HS-JKTS": <LazyLoadComponent><HSJacketsPage appData={AppData} /></LazyLoadComponent>,
      "FRB": <LazyLoadComponent><FRBPage appData={AppData} /></LazyLoadComponent>,
      "DIPLOMA": <LazyLoadComponent><DiplomaPage appData={AppData} /></LazyLoadComponent>,
      "COL_RINGS": <ColJWLPage appData={AppData} />,
      "COL-CAD-JLRY": <ColJWLPage appData={AppData} isCanadaCollege={true} />,
      "FQ-Regalia": <RegaliaPage appData={AppData} />,
      "AFFILIATION-STOLE": <LazyLoadComponent><AffiliationStolePage appData={AppData} /></LazyLoadComponent>,
      "COL-Regalia": <ColRegaliaPage appData={AppData} />,
      "COL-GRAD-ANNC": <ColGradAnncPage appData={AppData} />,
    };
    return pages[option];
  }

  return (
    <Router>
          <Switch>
            <Route path={Routes.HOME}>
            {
              Object.keys(AppData.productBrowseData)?.length > 0 && 
                AppData.pagecontentData?.length > 0 &&
                getPageByParam(window.template)
            }
            </Route>
          </Switch>
    </Router>
  )
}

export default App
