import { ProductBrowseProvider } from './productBrowseDataContext/productBrowseProvider';

// Actions Exports
export { setProductBrowseData } from './productBrowseDataContext/actions';
// Hooks Exports
export { useGetProductBrowseData, useGetProductBrowsePromoBanners } from './productBrowseDataContext/useProductBrowseData';

const withContextProviders = (Component) => (props) => (
    <ProductBrowseProvider>
        <Component />
    </ProductBrowseProvider>
);


export default withContextProviders;