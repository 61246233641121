// import HardCodedPageContentData from './pb-page-content-data.json'
// import HardCodedProductBrowseData from './pb-service-data.json'
// import HardCodedAffiliationData from './affiliation-data.json'
// import HardCodedFilterData from './pb-filter-data.json'
// import HardCodedPromoCodesData from  './pb-promo-codes-data.json'
// import HardCodedExcludeListData from './pb-exclude-list.json'

const pageContentData = window.pageContentData || window.pageContextData || window.pageContent || window.hsJwlPageContent || window.hsGradPageContent || window.hsJktsPageContent
const productBrowseData = window.productBrowseData || window.serviceData || window.hsJwlProductBrowseData || window.hsGradProductBrowseData || window.hsJktsProductBrowseData
const filterData = window.filterData || window.hsJwlFilterData || window.hsGradFilterData

export const AppData = {
    affiliationData: window.affiliationData ?? {},
    productBrowseData: productBrowseData ?? {},
    pagecontentData: pageContentData ?? [],
    filterData: filterData ?? {},
    PromoCodesData: window.promocodes ?? [],
    excludeListData: window.promoCodesExcludeList ?? [],
}

// ! Use Only For Local Development Process 
// export const AppData = {
//     affiliationData: HardCodedAffiliationData,
//     productBrowseData: HardCodedProductBrowseData,
//     pagecontentData: HardCodedPageContentData,
//     filterData: HardCodedFilterData,
//     PromoCodesData: HardCodedPromoCodesData,
//     excludeListData: HardCodedExcludeListData
// }
